import { Directive, ElementRef, Output, EventEmitter, OnInit } from '@angular/core';

declare var Dragdealer: any;

@Directive({
  selector: '[appSlider]'
})
export class SliderDirective implements OnInit {
  @Output() sliderComplete: EventEmitter<boolean> = new EventEmitter();
  slideToUnlockNew: any;
  dragCallback: boolean;
  manualTimer: any;
  timer: any;

  constructor(private el: ElementRef) {

  }

  ngOnInit(): void {
    this.slideToUnlockNew = new Dragdealer(this.el.nativeElement,
      {
        x: 0,
        slide: false,
        steps: 1,
        loose: false,
        right: 15,
        dragStopCallback: (x) => {
          this.dragCallback = true;
          if (this.manualTimer) {
            clearTimeout(this.manualTimer);
            this.manualTimer = null;
          }
          // Only 0 and 1 are the possible values because of "steps: 2"
          if (x === 1) {
            this.sliderComplete.emit(true);
            // this.startTimer();
            setTimeout(() => {
              this.slideToUnlockNew.setValue(0, 0, true);
            }, 5);
          } else {
            this.slideToUnlockNew.setValue(0, 0, false);
          }
        },
        callback: () => {
          if (this.dragCallback) {
            this.dragCallback = false;
            return;
          }

          if (this.manualTimer) {
            this.manualTimer = null;
            return;
          }

          this.manualTimer = setTimeout(() => {
            this.slideToUnlockNew.setValue(0, 0, false);
          }, 100);
        },
        animationCallback: (x, y) => {
          if (this.el.nativeElement.querySelector('.dragdealer__text')) {
            // tslint:disable-next-line:max-line-length
            this.el.nativeElement.querySelector('.dragdealer__handle span').style.width = `${this.el.nativeElement.querySelector('.dragdealer__text').offsetWidth}px`;
          }
          this.el.nativeElement.querySelector('.dragdealer__handle').style.transform = 'translateX(0)';
          this.el.nativeElement.querySelector('.dragdealer__handle').style.width = `${x * 100}%`;
        }
      });
  }
}
