/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoVideoStreamResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-video-stream-response';
import { Steer73PaddypowerCoreDtoVideoStreamRequest } from '../models/steer-73paddypower-core-dto-video-stream-request';
@Injectable({
  providedIn: 'root',
})
class AtTheRacesV1Service extends __BaseService {
  static readonly AtTheRacesV1VideoStreamPath = '/api/attheraces/VideoStream';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Contacts AtTheRaces to get the URL for the media stream
   * @param request The details of the stream to get the URL for
   * @return OK
   */
  AtTheRacesV1VideoStreamResponse(request: Steer73PaddypowerCoreDtoVideoStreamRequest): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoVideoStreamResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/attheraces/VideoStream`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoVideoStreamResponse>;
      })
    );
  }
  /**
   * Contacts AtTheRaces to get the URL for the media stream
   * @param request The details of the stream to get the URL for
   * @return OK
   */
  AtTheRacesV1VideoStream(request: Steer73PaddypowerCoreDtoVideoStreamRequest): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoVideoStreamResponse> {
    return this.AtTheRacesV1VideoStreamResponse(request).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoVideoStreamResponse)
    );
  }
}

module AtTheRacesV1Service {
}

export { AtTheRacesV1Service }
