/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoStoreSearchResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-store-search-response';
@Injectable({
  providedIn: 'root',
})
class StoreService extends __BaseService {
  static readonly StoreAddressSearchPath = '/api/store/Search/Address/{query}';
  static readonly StoreLocationSearchPath = '/api/store/Search/Location';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Searches for a shop by address.
   *
   * Performs a prioritised search, the shops whose name matches the wuery are ranked above those whose address matches the query.
   * @param params The `StoreService.StoreAddressSearchParams` containing the following parameters:
   *
   * - `query`: The search query to match to the shops
   *
   * - `api-version`:
   *
   * @return OK
   */
  StoreAddressSearchResponse(params: StoreService.StoreAddressSearchParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoStoreSearchResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/store/Search/Address/${encodeURIComponent(String(params.query))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoStoreSearchResponse>;
      })
    );
  }
  /**
   * Searches for a shop by address.
   *
   * Performs a prioritised search, the shops whose name matches the wuery are ranked above those whose address matches the query.
   * @param params The `StoreService.StoreAddressSearchParams` containing the following parameters:
   *
   * - `query`: The search query to match to the shops
   *
   * - `api-version`:
   *
   * @return OK
   */
  StoreAddressSearch(params: StoreService.StoreAddressSearchParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoStoreSearchResponse> {
    return this.StoreAddressSearchResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoStoreSearchResponse)
    );
  }

  /**
   * Searches for stores that are closest to the provided location.
   *
   * The number of results and distance from the searching device are limited by app settings. Defaults: Max Results = 5, Max Distance = 50miles
   * @param params The `StoreService.StoreLocationSearchParams` containing the following parameters:
   *
   * - `longitude`: The logitude of the searching device
   *
   * - `latitude`: The latitude of the searching device
   *
   * - `api-version`:
   *
   * @return OK
   */
  StoreLocationSearchResponse(params: StoreService.StoreLocationSearchParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoStoreSearchResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString());
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString());
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/store/Search/Location`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoStoreSearchResponse>;
      })
    );
  }
  /**
   * Searches for stores that are closest to the provided location.
   *
   * The number of results and distance from the searching device are limited by app settings. Defaults: Max Results = 5, Max Distance = 50miles
   * @param params The `StoreService.StoreLocationSearchParams` containing the following parameters:
   *
   * - `longitude`: The logitude of the searching device
   *
   * - `latitude`: The latitude of the searching device
   *
   * - `api-version`:
   *
   * @return OK
   */
  StoreLocationSearch(params: StoreService.StoreLocationSearchParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoStoreSearchResponse> {
    return this.StoreLocationSearchResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoStoreSearchResponse)
    );
  }
}

module StoreService {

  /**
   * Parameters for StoreAddressSearch
   */
  export interface StoreAddressSearchParams {

    /**
     * The search query to match to the shops
     */
    query: string;
    apiVersion: number;
  }

  /**
   * Parameters for StoreLocationSearch
   */
  export interface StoreLocationSearchParams {

    /**
     * The logitude of the searching device
     */
    longitude: number;

    /**
     * The latitude of the searching device
     */
    latitude: number;
    apiVersion: number;
  }
}

export { StoreService }
