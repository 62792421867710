/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreDtoStreaksCompetitionResponse } from '../models/steer-73paddypower-core-dto-base-response-system-collections-generic-list-steer-73paddypower-core-dto-streaks-competition-response';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksFullCompetitionAndGameDetailsResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-transfer-objects-streaks-full-competition-and-game-details-response';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksLeaderboardResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-transfer-objects-streaks-leaderboard-response';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksMonthlyLeaderboardResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-transfer-objects-streaks-monthly-leaderboard-response';
import { Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerDataDomainResponsesStreaksLeaderboardItem } from '../models/steer-73paddypower-core-dto-base-response-system-collections-generic-list-steer-73paddypower-data-domain-responses-streaks-leaderboard-item';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksShopLeaderBoardResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-transfer-objects-streaks-shop-leader-board-response';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksCanUserRegisterResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-transfer-objects-streaks-can-user-register-response';
import { Steer73PaddypowerCoreDtoBaseResponseSystemInt32 } from '../models/steer-73paddypower-core-dto-base-response-system-int-32';
import { Steer73PaddypowerCoreDtoStreaksUpdateSelectionRequest } from '../models/steer-73paddypower-core-dto-streaks-update-selection-request';
import { Steer73PaddypowerCoreDtoStreaksRegisterUserRequest } from '../models/steer-73paddypower-core-dto-streaks-register-user-request';
import { Steer73PaddypowerCoreDtoBaseResponseSystemString } from '../models/steer-73paddypower-core-dto-base-response-system-string';
import { Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreDtoStreaksCompetitionGetGamesResponse } from '../models/steer-73paddypower-core-dto-base-response-system-collections-generic-list-steer-73paddypower-core-dto-streaks-competition-get-games-response';
import { Steer73PaddypowerCoreDtoBaseResponseSystemBoolean } from '../models/steer-73paddypower-core-dto-base-response-system-boolean';
import { Steer73PaddypowerAccaTrackerControllersApiUpdateReceiveWeeklyReminderSmsRequest } from '../models/steer-73paddypower-acca-tracker-controllers-api-update-receive-weekly-reminder-sms-request';
import { Steer73PaddypowerAccaTrackerControllersApiUpdateReceiveNewMatchSmsRequest } from '../models/steer-73paddypower-acca-tracker-controllers-api-update-receive-new-match-sms-request';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreakCopyResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-transfer-objects-streak-copy-response';
@Injectable({
  providedIn: 'root',
})
class StreaksService extends __BaseService {
  static readonly StreaksGetActiveStreaksPath = '/api/streaks';
  static readonly StreaksGetCompetitionAndUserDetailsV2Path = '/api/streaks/{competitionId}';
  static readonly StreaksCheckNameAvailabilityPath = '/api/streaks/{competitionId}/players/{userName}';
  static readonly StreaksGetBothLeaderboardsPath = '/api/streaks/{competitionId}/leaderboard';
  static readonly StreaksGetBothMonthlyLeaderboardsPath = '/api/streaks/{competitionId}/monthlyleaderboard';
  static readonly StreaksGetNationalLeaderboardPath = '/api/streaks/{competitionId}/leaderboard/national';
  static readonly StreaksGetShopLeaderboardPath = '/api/streaks/{competitionId}/leaderboard/shop';
  static readonly StreaksVerifyUserEligibilityPath = '/api/streaks/{competitionId}/canenter';
  static readonly StreaksUpdateSelectionPath = '/api/streaks/{competitionId}/{userName}';
  static readonly StreaksRegisterUserPath = '/api/streaks/{competitionId}/{userName}';
  static readonly StreaksCashoutPath = '/api/streaks/{competitionId}/{userName}/cashout';
  static readonly StreaksGetAllActiveCompetitionsAsyncPath = '/api/streaks/GetAllActiveStreakCompetitions';
  static readonly StreaksUpdateReceiveReminderSmsPath = '/api/streaks/Entries/{gameEntryId}/UpdateReceiveWeeklyReminderSms';
  static readonly StreaksUpdateReceiveReminderSms_1Path = '/api/streaks/{competitionId}/{userName}/ReceiveReminderSms';
  static readonly StreaksUpdateReceiveNewMatchSmsPath = '/api/streaks/Entries/{gameEntryId}/UpdateReceiveNewMatchSms';
  static readonly StreaksUpdateReceiveNewMatchSms_1Path = '/api/streaks/{competitionId}/{userName}/ReceiveNewMatchSms';
  static readonly StreaksGetAllStreakCopyPath = '/api/streaks/copy';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets a list active Streaks competitions, these are ordered by Competition start date ascending.
   * @param api-version undefined
   * @return OK
   */
  StreaksGetActiveStreaksResponse(apiVersion: number): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreDtoStreaksCompetitionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __headers = __headers.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/streaks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreDtoStreaksCompetitionResponse>;
      })
    );
  }
  /**
   * Gets a list active Streaks competitions, these are ordered by Competition start date ascending.
   * @param api-version undefined
   * @return OK
   */
  StreaksGetActiveStreaks(apiVersion: number): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreDtoStreaksCompetitionResponse> {
    return this.StreaksGetActiveStreaksResponse(apiVersion).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreDtoStreaksCompetitionResponse)
    );
  }

  /**
   * Gets the competition details for the specified Streaks Competition. Including all the user and game data and cashout enabled status
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksGetCompetitionAndUserDetailsV2Params` containing the following parameters:
   *
   * - `competitionId`: The ID of the Streaks Competition
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksGetCompetitionAndUserDetailsV2Response(params: StreaksService.StreaksGetCompetitionAndUserDetailsV2Params): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksFullCompetitionAndGameDetailsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/streaks/${encodeURIComponent(String(params.competitionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksFullCompetitionAndGameDetailsResponse>;
      })
    );
  }
  /**
   * Gets the competition details for the specified Streaks Competition. Including all the user and game data and cashout enabled status
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksGetCompetitionAndUserDetailsV2Params` containing the following parameters:
   *
   * - `competitionId`: The ID of the Streaks Competition
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksGetCompetitionAndUserDetailsV2(params: StreaksService.StreaksGetCompetitionAndUserDetailsV2Params): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksFullCompetitionAndGameDetailsResponse> {
    return this.StreaksGetCompetitionAndUserDetailsV2Response(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksFullCompetitionAndGameDetailsResponse)
    );
  }

  /**
   * Check name availability and acceptability for given competition. Verifies the name is unique within the competition and that it doesn't contain any banned words
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksCheckNameAvailabilityParams` containing the following parameters:
   *
   * - `userName`: The name to check
   *
   * - `competitionId`: The ID of the Streaks competition to check the name for
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksCheckNameAvailabilityResponse(params: StreaksService.StreaksCheckNameAvailabilityParams): __Observable<__StrictHttpResponse<Array<boolean>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/streaks/${encodeURIComponent(String(params.competitionId))}/players/${encodeURIComponent(String(params.userName))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<boolean>>;
      })
    );
  }
  /**
   * Check name availability and acceptability for given competition. Verifies the name is unique within the competition and that it doesn't contain any banned words
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksCheckNameAvailabilityParams` containing the following parameters:
   *
   * - `userName`: The name to check
   *
   * - `competitionId`: The ID of the Streaks competition to check the name for
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksCheckNameAvailability(params: StreaksService.StreaksCheckNameAvailabilityParams): __Observable<Array<boolean>> {
    return this.StreaksCheckNameAvailabilityResponse(params).pipe(
      __map(_r => _r.body as Array<boolean>)
    );
  }

  /**
   * Gets the leaderboard for the national and shop games
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksGetBothLeaderboardsParams` containing the following parameters:
   *
   * - `competitionId`: The ID of the Streaks competition to get the leaderboard for
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksGetBothLeaderboardsResponse(params: StreaksService.StreaksGetBothLeaderboardsParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksLeaderboardResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/streaks/${encodeURIComponent(String(params.competitionId))}/leaderboard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksLeaderboardResponse>;
      })
    );
  }
  /**
   * Gets the leaderboard for the national and shop games
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksGetBothLeaderboardsParams` containing the following parameters:
   *
   * - `competitionId`: The ID of the Streaks competition to get the leaderboard for
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksGetBothLeaderboards(params: StreaksService.StreaksGetBothLeaderboardsParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksLeaderboardResponse> {
    return this.StreaksGetBothLeaderboardsResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksLeaderboardResponse)
    );
  }

  /**
   * Gets the leaderboard split into game periods for the national and shop games
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksGetBothMonthlyLeaderboardsParams` containing the following parameters:
   *
   * - `competitionId`: The ID of the Streaks competition to get the leaderboard for
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksGetBothMonthlyLeaderboardsResponse(params: StreaksService.StreaksGetBothMonthlyLeaderboardsParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksMonthlyLeaderboardResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/streaks/${encodeURIComponent(String(params.competitionId))}/monthlyleaderboard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksMonthlyLeaderboardResponse>;
      })
    );
  }
  /**
   * Gets the leaderboard split into game periods for the national and shop games
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksGetBothMonthlyLeaderboardsParams` containing the following parameters:
   *
   * - `competitionId`: The ID of the Streaks competition to get the leaderboard for
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksGetBothMonthlyLeaderboards(params: StreaksService.StreaksGetBothMonthlyLeaderboardsParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksMonthlyLeaderboardResponse> {
    return this.StreaksGetBothMonthlyLeaderboardsResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksMonthlyLeaderboardResponse)
    );
  }

  /**
   * Gets the leaderboard for the national game
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksGetNationalLeaderboardParams` containing the following parameters:
   *
   * - `competitionId`: The ID of the Streaks competition to get the leaderboard for
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksGetNationalLeaderboardResponse(params: StreaksService.StreaksGetNationalLeaderboardParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerDataDomainResponsesStreaksLeaderboardItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/streaks/${encodeURIComponent(String(params.competitionId))}/leaderboard/national`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerDataDomainResponsesStreaksLeaderboardItem>;
      })
    );
  }
  /**
   * Gets the leaderboard for the national game
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksGetNationalLeaderboardParams` containing the following parameters:
   *
   * - `competitionId`: The ID of the Streaks competition to get the leaderboard for
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksGetNationalLeaderboard(params: StreaksService.StreaksGetNationalLeaderboardParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerDataDomainResponsesStreaksLeaderboardItem> {
    return this.StreaksGetNationalLeaderboardResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerDataDomainResponsesStreaksLeaderboardItem)
    );
  }

  /**
   * Gets the leaderboard for the store game
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksGetShopLeaderboardParams` containing the following parameters:
   *
   * - `competitionId`: The ID of the Streaks competition to get the leaderboard for
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksGetShopLeaderboardResponse(params: StreaksService.StreaksGetShopLeaderboardParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksShopLeaderBoardResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/streaks/${encodeURIComponent(String(params.competitionId))}/leaderboard/shop`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksShopLeaderBoardResponse>;
      })
    );
  }
  /**
   * Gets the leaderboard for the store game
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksGetShopLeaderboardParams` containing the following parameters:
   *
   * - `competitionId`: The ID of the Streaks competition to get the leaderboard for
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksGetShopLeaderboard(params: StreaksService.StreaksGetShopLeaderboardParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksShopLeaderBoardResponse> {
    return this.StreaksGetShopLeaderboardResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksShopLeaderBoardResponse)
    );
  }

  /**
   * Verifies if a user is eligible to enter the game
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksVerifyUserEligibilityParams` containing the following parameters:
   *
   * - `competitionId`: The ID of the Streaks competition to check the eligibility for
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksVerifyUserEligibilityResponse(params: StreaksService.StreaksVerifyUserEligibilityParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksCanUserRegisterResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/streaks/${encodeURIComponent(String(params.competitionId))}/canenter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksCanUserRegisterResponse>;
      })
    );
  }
  /**
   * Verifies if a user is eligible to enter the game
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksVerifyUserEligibilityParams` containing the following parameters:
   *
   * - `competitionId`: The ID of the Streaks competition to check the eligibility for
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksVerifyUserEligibility(params: StreaksService.StreaksVerifyUserEligibilityParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksCanUserRegisterResponse> {
    return this.StreaksVerifyUserEligibilityResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreaksCanUserRegisterResponse)
    );
  }

  /**
   * Sets a team selection for a user
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksUpdateSelectionParams` containing the following parameters:
   *
   * - `userName`: The user name of the user making the selection
   *
   * - `selectionRequest`: Selection data - contains the Id of the selection made
   *
   * - `competitionId`: The ID of the Streaks competition
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksUpdateSelectionResponse(params: StreaksService.StreaksUpdateSelectionParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemInt32>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.selectionRequest;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/streaks/${encodeURIComponent(String(params.competitionId))}/${encodeURIComponent(String(params.userName))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemInt32>;
      })
    );
  }
  /**
   * Sets a team selection for a user
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksUpdateSelectionParams` containing the following parameters:
   *
   * - `userName`: The user name of the user making the selection
   *
   * - `selectionRequest`: Selection data - contains the Id of the selection made
   *
   * - `competitionId`: The ID of the Streaks competition
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksUpdateSelection(params: StreaksService.StreaksUpdateSelectionParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemInt32> {
    return this.StreaksUpdateSelectionResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemInt32)
    );
  }

  /**
   * Registers a user for a streaks competition
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksRegisterUserParams` containing the following parameters:
   *
   * - `userName`: The user name to use to register the user
   *
   * - `registerUserRequest`: Registration data - currently only store Id
   *
   * - `competitionId`: The ID of the Streaks competition to register the user for
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksRegisterUserResponse(params: StreaksService.StreaksRegisterUserParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemInt32>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.registerUserRequest;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/streaks/${encodeURIComponent(String(params.competitionId))}/${encodeURIComponent(String(params.userName))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemInt32>;
      })
    );
  }
  /**
   * Registers a user for a streaks competition
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksRegisterUserParams` containing the following parameters:
   *
   * - `userName`: The user name to use to register the user
   *
   * - `registerUserRequest`: Registration data - currently only store Id
   *
   * - `competitionId`: The ID of the Streaks competition to register the user for
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksRegisterUser(params: StreaksService.StreaksRegisterUserParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemInt32> {
    return this.StreaksRegisterUserResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemInt32)
    );
  }

  /**
   * Cashes out the current users streak
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksCashoutParams` containing the following parameters:
   *
   * - `userName`: The user name of the currentuser
   *
   * - `competitionId`: The ID of the Streaks competition
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksCashoutResponse(params: StreaksService.StreaksCashoutParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/streaks/${encodeURIComponent(String(params.competitionId))}/${encodeURIComponent(String(params.userName))}/cashout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemString>;
      })
    );
  }
  /**
   * Cashes out the current users streak
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `StreaksService.StreaksCashoutParams` containing the following parameters:
   *
   * - `userName`: The user name of the currentuser
   *
   * - `competitionId`: The ID of the Streaks competition
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksCashout(params: StreaksService.StreaksCashoutParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemString> {
    return this.StreaksCashoutResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemString)
    );
  }

  /**
   * Gets a list of all the active competitions to determine which banner if any should be shown
   * @param api-version undefined
   * @return OK
   */
  StreaksGetAllActiveCompetitionsAsyncResponse(apiVersion: number): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreDtoStreaksCompetitionGetGamesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __headers = __headers.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/streaks/GetAllActiveStreakCompetitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreDtoStreaksCompetitionGetGamesResponse>;
      })
    );
  }
  /**
   * Gets a list of all the active competitions to determine which banner if any should be shown
   * @param api-version undefined
   * @return OK
   */
  StreaksGetAllActiveCompetitionsAsync(apiVersion: number): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreDtoStreaksCompetitionGetGamesResponse> {
    return this.StreaksGetAllActiveCompetitionsAsyncResponse(apiVersion).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreDtoStreaksCompetitionGetGamesResponse)
    );
  }

  /**
   * @param params The `StreaksService.StreaksUpdateReceiveReminderSmsParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `gameEntryId`:
   *
   * - `competitionId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksUpdateReceiveReminderSmsResponse(params: StreaksService.StreaksUpdateReceiveReminderSmsParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;

    if (params.competitionId != null) __params = __params.set('competitionId', params.competitionId.toString());
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/streaks/Entries/${encodeURIComponent(String(params.gameEntryId))}/UpdateReceiveWeeklyReminderSms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>;
      })
    );
  }
  /**
   * @param params The `StreaksService.StreaksUpdateReceiveReminderSmsParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `gameEntryId`:
   *
   * - `competitionId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksUpdateReceiveReminderSms(params: StreaksService.StreaksUpdateReceiveReminderSmsParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean> {
    return this.StreaksUpdateReceiveReminderSmsResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemBoolean)
    );
  }

  /**
   * @param params The `StreaksService.StreaksUpdateReceiveReminderSms_1Params` containing the following parameters:
   *
   * - `userName`:
   *
   * - `request`:
   *
   * - `competitionId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksUpdateReceiveReminderSms_1Response(params: StreaksService.StreaksUpdateReceiveReminderSms_1Params): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/streaks/${encodeURIComponent(String(params.competitionId))}/${encodeURIComponent(String(params.userName))}/ReceiveReminderSms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>;
      })
    );
  }
  /**
   * @param params The `StreaksService.StreaksUpdateReceiveReminderSms_1Params` containing the following parameters:
   *
   * - `userName`:
   *
   * - `request`:
   *
   * - `competitionId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksUpdateReceiveReminderSms_1(params: StreaksService.StreaksUpdateReceiveReminderSms_1Params): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean> {
    return this.StreaksUpdateReceiveReminderSms_1Response(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemBoolean)
    );
  }

  /**
   * @param params The `StreaksService.StreaksUpdateReceiveNewMatchSmsParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `gameEntryId`:
   *
   * - `competitionId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksUpdateReceiveNewMatchSmsResponse(params: StreaksService.StreaksUpdateReceiveNewMatchSmsParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;

    if (params.competitionId != null) __params = __params.set('competitionId', params.competitionId.toString());
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/streaks/Entries/${encodeURIComponent(String(params.gameEntryId))}/UpdateReceiveNewMatchSms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>;
      })
    );
  }
  /**
   * @param params The `StreaksService.StreaksUpdateReceiveNewMatchSmsParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `gameEntryId`:
   *
   * - `competitionId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksUpdateReceiveNewMatchSms(params: StreaksService.StreaksUpdateReceiveNewMatchSmsParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean> {
    return this.StreaksUpdateReceiveNewMatchSmsResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemBoolean)
    );
  }

  /**
   * @param params The `StreaksService.StreaksUpdateReceiveNewMatchSms_1Params` containing the following parameters:
   *
   * - `userName`:
   *
   * - `request`:
   *
   * - `competitionId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksUpdateReceiveNewMatchSms_1Response(params: StreaksService.StreaksUpdateReceiveNewMatchSms_1Params): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/streaks/${encodeURIComponent(String(params.competitionId))}/${encodeURIComponent(String(params.userName))}/ReceiveNewMatchSms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>;
      })
    );
  }
  /**
   * @param params The `StreaksService.StreaksUpdateReceiveNewMatchSms_1Params` containing the following parameters:
   *
   * - `userName`:
   *
   * - `request`:
   *
   * - `competitionId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  StreaksUpdateReceiveNewMatchSms_1(params: StreaksService.StreaksUpdateReceiveNewMatchSms_1Params): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean> {
    return this.StreaksUpdateReceiveNewMatchSms_1Response(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemBoolean)
    );
  }

  /**
   * Gets a list of all the streak copies
   * @param api-version undefined
   * @return OK
   */
  StreaksGetAllStreakCopyResponse(apiVersion: number): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreakCopyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __headers = __headers.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/streaks/copy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreakCopyResponse>;
      })
    );
  }
  /**
   * Gets a list of all the streak copies
   * @param api-version undefined
   * @return OK
   */
  StreaksGetAllStreakCopy(apiVersion: number): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreakCopyResponse> {
    return this.StreaksGetAllStreakCopyResponse(apiVersion).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsStreakCopyResponse)
    );
  }
}

module StreaksService {

  /**
   * Parameters for StreaksGetCompetitionAndUserDetailsV2
   */
  export interface StreaksGetCompetitionAndUserDetailsV2Params {

    /**
     * The ID of the Streaks Competition
     */
    competitionId: number;
    apiVersion: number;
  }

  /**
   * Parameters for StreaksCheckNameAvailability
   */
  export interface StreaksCheckNameAvailabilityParams {

    /**
     * The name to check
     */
    userName: string;

    /**
     * The ID of the Streaks competition to check the name for
     */
    competitionId: number;
    apiVersion: number;
  }

  /**
   * Parameters for StreaksGetBothLeaderboards
   */
  export interface StreaksGetBothLeaderboardsParams {

    /**
     * The ID of the Streaks competition to get the leaderboard for
     */
    competitionId: number;
    apiVersion: number;
  }

  /**
   * Parameters for StreaksGetBothMonthlyLeaderboards
   */
  export interface StreaksGetBothMonthlyLeaderboardsParams {

    /**
     * The ID of the Streaks competition to get the leaderboard for
     */
    competitionId: number;
    apiVersion: number;
  }

  /**
   * Parameters for StreaksGetNationalLeaderboard
   */
  export interface StreaksGetNationalLeaderboardParams {

    /**
     * The ID of the Streaks competition to get the leaderboard for
     */
    competitionId: number;
    apiVersion: number;
  }

  /**
   * Parameters for StreaksGetShopLeaderboard
   */
  export interface StreaksGetShopLeaderboardParams {

    /**
     * The ID of the Streaks competition to get the leaderboard for
     */
    competitionId: number;
    apiVersion: number;
  }

  /**
   * Parameters for StreaksVerifyUserEligibility
   */
  export interface StreaksVerifyUserEligibilityParams {

    /**
     * The ID of the Streaks competition to check the eligibility for
     */
    competitionId: number;
    apiVersion: number;
  }

  /**
   * Parameters for StreaksUpdateSelection
   */
  export interface StreaksUpdateSelectionParams {

    /**
     * The user name of the user making the selection
     */
    userName: string;

    /**
     * Selection data - contains the Id of the selection made
     */
    selectionRequest: Steer73PaddypowerCoreDtoStreaksUpdateSelectionRequest;

    /**
     * The ID of the Streaks competition
     */
    competitionId: number;
    apiVersion: number;
  }

  /**
   * Parameters for StreaksRegisterUser
   */
  export interface StreaksRegisterUserParams {

    /**
     * The user name to use to register the user
     */
    userName: string;

    /**
     * Registration data - currently only store Id
     */
    registerUserRequest: Steer73PaddypowerCoreDtoStreaksRegisterUserRequest;

    /**
     * The ID of the Streaks competition to register the user for
     */
    competitionId: number;
    apiVersion: number;
  }

  /**
   * Parameters for StreaksCashout
   */
  export interface StreaksCashoutParams {

    /**
     * The user name of the currentuser
     */
    userName: string;

    /**
     * The ID of the Streaks competition
     */
    competitionId: number;
    apiVersion: number;
  }

  /**
   * Parameters for StreaksUpdateReceiveReminderSms
   */
  export interface StreaksUpdateReceiveReminderSmsParams {
    request: Steer73PaddypowerAccaTrackerControllersApiUpdateReceiveWeeklyReminderSmsRequest;
    gameEntryId: string;
    competitionId: number;
    apiVersion: number;
  }

  /**
   * Parameters for StreaksUpdateReceiveReminderSms_1
   */
  export interface StreaksUpdateReceiveReminderSms_1Params {
    userName: string;
    request: Steer73PaddypowerAccaTrackerControllersApiUpdateReceiveWeeklyReminderSmsRequest;
    competitionId: number;
    apiVersion: number;
  }

  /**
   * Parameters for StreaksUpdateReceiveNewMatchSms
   */
  export interface StreaksUpdateReceiveNewMatchSmsParams {
    request: Steer73PaddypowerAccaTrackerControllersApiUpdateReceiveNewMatchSmsRequest;
    gameEntryId: string;
    competitionId: number;
    apiVersion: number;
  }

  /**
   * Parameters for StreaksUpdateReceiveNewMatchSms_1
   */
  export interface StreaksUpdateReceiveNewMatchSms_1Params {
    userName: string;
    request: Steer73PaddypowerAccaTrackerControllersApiUpdateReceiveNewMatchSmsRequest;
    competitionId: number;
    apiVersion: number;
  }
}

export { StreaksService }
