import { Component, OnInit } from '@angular/core';
import { FaqsService } from '../../../api/services';
import { Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  faqText: string;
  show = true;
  loading = true;
  constructor(
    private titleService: Title,
    private faqService: FaqsService
  ) {
    this.titleService.setTitle('Frequently asked questions');
  }

  ngOnInit(): void {
    this.loadFaqs();
  }

  loadFaqs() {
    this.faqService.FaqsGetLatest(1)
    .pipe(take(1))
    .subscribe((res) => {
      this.faqText = res.Payload;
      this.show = true;
      this.loading = false;
    },
    (err) => {
      this.show = false;
      this.loading = false;
    });
  }
}
