/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCheckForNewVersionResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-transfer-objects-check-for-new-version-response';
import { Steer73PaddypowerCoreTransferObjectsCheckForNewVersionRequest } from '../models/steer-73paddypower-core-transfer-objects-check-for-new-version-request';
@Injectable({
  providedIn: 'root',
})
class AppVersionsService extends __BaseService {
  static readonly AppVersionsCheckForNewVersionPath = '/api/appVersions/CheckForNewVersion';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AppVersionsService.AppVersionsCheckForNewVersionParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  AppVersionsCheckForNewVersionResponse(params: AppVersionsService.AppVersionsCheckForNewVersionParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCheckForNewVersionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/appVersions/CheckForNewVersion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCheckForNewVersionResponse>;
      })
    );
  }
  /**
   * @param params The `AppVersionsService.AppVersionsCheckForNewVersionParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  AppVersionsCheckForNewVersion(params: AppVersionsService.AppVersionsCheckForNewVersionParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCheckForNewVersionResponse> {
    return this.AppVersionsCheckForNewVersionResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCheckForNewVersionResponse)
    );
  }
}

module AppVersionsService {

  /**
   * Parameters for AppVersionsCheckForNewVersion
   */
  export interface AppVersionsCheckForNewVersionParams {
    request: Steer73PaddypowerCoreTransferObjectsCheckForNewVersionRequest;
    apiVersion: number;
  }
}

export { AppVersionsService }
