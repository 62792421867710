import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnnoucementService } from '../../../api/services';
import { take } from 'rxjs/operators';
import { Steer73PaddypowerCoreDtoAnnoucementViewModel } from 'src/app/api/models';
import { Location } from '@angular/common';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {
  annoucement: Steer73PaddypowerCoreDtoAnnoucementViewModel;
  loading = true;

  constructor(
    private route: ActivatedRoute,
    private annoucementService: AnnoucementService,
    private location: Location
  ) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    this.getAnnoucement(parseInt(id));
  }

  getAnnoucement(id: number) {
      this.annoucementService.AnnoucementGet_1({
        id: id,
        apiVersion: 1
      }).pipe(take(1)).subscribe((res) => {
        this.loading = false;
        this.annoucement = res.Payload;
      });
  }

  goBack() {
    this.location.back();
  }

}
