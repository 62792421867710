<div (scroll)="adjustHeights()" class="rewards">
  <div class="rewards__loggedout containerTop" *ngIf="!(currentUser)">
      <img class="rewards__image" width="164" height="138" src="assets/images/vectors/rewardsClub.svg" alt="rewards club" >
      <h3 class="rewards__title">6<sup>th</sup> BET <span>Free</span></h3>
      <h4 class="rewards__subtitle">Track 5 shop bets, get a <span>free bet</span> up to £/€10</h4>
      <a [routerLink]="['/login']" class="button button--large rewards__login-button">Login</a>
      <a [routerLink]="['/create-account']" class="rewards__create-link">Create a new account</a>
  </div>
  <div class="rewards__loggedin containerTop" *ngIf="(currentUser)">
      <img class="rewards__image" width="164" height="138" src="assets/images/vectors/rewardsClub.svg" alt="rewards club" >
      <h3 class="rewards__title">6<sup>th</sup> BET <span>Free</span></h3>
      <h4 class="rewards__subtitle">Track 5 shop bets, get a <span>free bet</span> up to £/€10</h4>
      <div class="stamps">
        <div class="stamps__row">
          <ng-container *ngFor="let x of [].constructor(3); let i = index;">
            <svg class="stamps__icon" [ngClass]="{'selected': i < stamps}">
                <use xlink:href="assets/images/vectors/svgsprites.svg#stamp"></use>
            </svg>
          </ng-container>
        </div>
        <div class="stamps__row">
          <ng-container *ngFor="let x of [].constructor(2); let i = index;">
            <svg class="stamps__icon" [ngClass]="{'selected': i < (stamps-3)}">
                <use xlink:href="assets/images/vectors/svgsprites.svg#stamp"></use>
            </svg>
          </ng-container>
        </div>
      </div>
      <p class="rewards__para">Your average stake per bet so far:</p>
      <h2 class="rewards__title"><span>{{ currencySymbol }}{{ averageStake }} </span></h2>
      <ng-container *ngFor="let freeBetCode of freeBetCodes">
        <p class="rewards__para">Free bet codes</p>
        <div class="rewards__batch-container">
          <div class="rewards__batch-value">
            <span>{{ freeBetCode.AmountWithCurrencySymbol }}</span>
            <span>Bet</span>
          </div>
          <div class="rewards__batch-code">{{ freeBetCode.Code }}</div>
        </div>
      </ng-container>
  </div>

  <div class="terms" [ngClass]="status ? 'open' : ''">
    <h3 (click)="open()">Terms and conditions</h3>
    <div [innerHTML]="termsText" *ngIf="termsShow && !termsLoading"></div>
    <div class="terms__error" *ngIf="!termsShow && !termsLoading">There is no data to show</div>
  </div>
</div>

<app-footer></app-footer>
