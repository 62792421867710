import { Component, OnInit, OnDestroy, AfterViewChecked, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { TicketService } from 'src/app/shared/ticket.service';
import { interval, Subscription } from 'rxjs';
import { Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel, Steer73PaddypowerCoreServicesBetTicketManagerRetaCashOutModel, Steer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel
        } from 'src/app/api/models';
import { switchMap, startWith, take } from 'rxjs/operators';
import { SideMenuService } from 'src/app/shared/side-menu.service';
import { Sort } from 'src/app/enums/sort.enum';
import { Filter } from 'src/app/enums/filter.enum';
import { UserService, User } from 'src/app/shared/user.service';
import { AppSettingsService } from 'src/app/shared/app-settings.service';
import { SignalRService } from 'src/app/shared/signalr.service';
import { Title } from '@angular/platform-browser';
import { StateHelperService } from 'src/app/shared/state-helper-service';
import { Status } from 'src/app/enums/status.enum';
import { VideoService } from '../media/video.service';
import { BetTicketComponent } from '../bet-ticket/bet-ticket.component';
import { AnalyticsManager } from '../../../shared/analytics-manager.service';
import { Router } from '@angular/router';
import { BetsInteractionResult } from 'src/app/enums/betsInteractionResult.enum';
import { BetTicketProvider } from 'src/app/enums/betTicketProvider.enum';

@Component({
  host: {
    '(document:click)': 'onClick($event)'
  },
  selector: 'app-track-bet',
  templateUrl: './track-bet.component.html',
  styleUrls: ['./track-bet.component.scss']
})
export class TrackBetComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChildren('ticket') ticketsReference: QueryList<BetTicketComponent>;

  refreshInterval: Subscription;
  cashoutInterval: Subscription;
  ticketSubscription: Subscription;
  logoutSubscription: Subscription;
  sendFixturesInterval: Subscription;
  ticketUpdateSubscription: Subscription;

  activeTab = 'Open';
  addedTicket: Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel;
  currentUser: User = null;

  openSortSelection = Sort.DatePlaced;
  settledFilterSelection = Filter.All;
  showSortMenu = false;
  scrollChecked = true;
  error = false;
  sort = Sort;
  filter = Filter;
  liveScoreRefreshInSeconds = null;
  ticketsLoading = true;
  filterMenuTimer = null;
  menuInitialOpen= false;
  filterAll = true;
  showStream : boolean;

  tickets: Steer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel;

  constructor(
    private titleService: Title,
    private ticketService: TicketService,
    private userService: UserService,
    private sideMenuService: SideMenuService,
    private appSetting: AppSettingsService,
    private signalR: SignalRService,
    private stateHelperService: StateHelperService,
    private videoService: VideoService,
    private eref: ElementRef,
    private router: Router,
    private analyticsManager: AnalyticsManager
  ) {
    this.titleService.setTitle('Track my bet');
    if (stateHelperService.hasState()) {
      this.scrollChecked = false;
      this.addedTicket = stateHelperService.getValueFromState('ticket');
      this.activeTab = stateHelperService.getValueFromState('activeTab');
    }
  }

  ngOnInit() {
    if (this.appSetting.fetch() && this.appSetting.fetch().LiveScoreRefreshInSeconds) {
      this.liveScoreRefreshInSeconds = this.appSetting.fetch().LiveScoreRefreshInSeconds * 1000;
    }

    this.logoutSubscription = this.userService.userData$.subscribe((user) => {
      this.currentUser = user;
      this.changeActiveTab(this.activeTab, true);
    });
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  ngAfterViewChecked() {
    if (!this.scrollChecked && this.tickets) {
      this.scrollChecked = true;
      this.scrollToTicket();
    }
  }

  onClick(event) {

    if (this.menuInitialOpen) {
      this.menuInitialOpen = false;
      return;
    }

    // This will close the menu if clicked outside. Sort menu ideally needs moving to a component
    if (this.eref.nativeElement.querySelector('.sort') && !this.eref.nativeElement.querySelector('.sort').contains(event.target)) // or some similar check
      this.showSortMenu = false;
  }

  changeActiveTab(tab: string, firstLoad = false) {
    if (firstLoad || this.activeTab !== tab) {
      this.activeTab = tab;

      this.unsubscribe();
      this.loadTickets();
      this.scrollToTop();
    }
  }

  loadTickets() {
    this.error = false;
    this.tickets = null;
    this.ticketsLoading = true;

    if (this.userService.getToken()) {
      this.ticketSubscription = this.ticketService
        .loadInitialTickets(this.activeTab, this.currentUser)
        .pipe(take(1))
        .subscribe((response) => {
          this.tickets = response;

          this.addAddedTicketIfNotPresent();
          this.addedTicket = null;
          this.filterAll = true;

          if (this.activeTab === 'Open') {
            if (localStorage.getItem('openTicketsSort') === null) {
              this.sortOpenTickets(this.openSortSelection);
            } else {
              this.sortOpenTickets(Sort[localStorage.getItem('openTicketsSort')]);
            }
          }

          this.refreshTickets();
          this.refreshCashout();
          this.retaTickets();
          this.refreshLiveScores();
          this.ticketsLoading = false;
        },
        (err) => {
          this.error = true;
          this.ticketsLoading = false;
        })
    } else {
      this.ticketsLoading = false;
    }
  }

  addAddedTicketIfNotPresent() {
    if (this.addedTicket && !this.tickets.Tickets.some(ticket => ticket.BetTicketNumber === this.addedTicket.BetTicketNumber)) {
      this.tickets.Tickets.push(this.addedTicket);
    }
  }

  refreshTickets() {
    this.refreshInterval = interval(15000).pipe(
      switchMap(() => this.ticketService.refreshTickets())
    ).subscribe((response: Steer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel) => {
      this.ticketService.updateExistingTickets(response.Tickets, this.tickets.Tickets);
    });
  }

  refreshCashout() {
    this.cashoutInterval = interval(15000).pipe(
      switchMap(() => this.ticketService.refreshCashout())
    ).subscribe((response: Steer73PaddypowerCoreServicesBetTicketManagerRetaCashOutModel[]) => {
      this.ticketService.updateCashoutOnExistingTickets(response, this.tickets.Tickets);
    });
  }

  retaTickets() {
    this.ticketUpdateSubscription = this.signalR.tickets
      .subscribe((ticket) => {
        this.ticketService.updateExistingTickets([ticket], this.tickets.Tickets);
      });
  }

  refreshLiveScores() {
    if (this.userService.user && this.liveScoreRefreshInSeconds) {
      this.sendFixturesInterval = interval(this.liveScoreRefreshInSeconds)
      .pipe(startWith(0))
      .subscribe(() => {
        this.signalR.sendFixtures(this.ticketService.getFixtures(this.tickets.Tickets));
      });
    }
  }

  trackTicket(index: number, obj) {
    return obj.BetTicketNumber;
  }

  showMenu() {
    this.sideMenuService.showMenu();
  }

  toggleSortFilter() {
    this.showSortMenu = !this.showSortMenu;

    if (this.showSortMenu) {
      this.menuInitialOpen = true;
    }
  }

  sortOpenTickets(sortBy: Sort) {
    this.openSortSelection = sortBy;
    localStorage.setItem('openTicketsSort', Sort[sortBy]);

    this.analyticsManager.betsInteraction(BetsInteractionResult.Sort, this.isOpenTicketsTabActive());

    switch (sortBy) {
      case Sort.CashOutValue:
        this.tickets.Tickets.sort((a, b) => {
          return b.Cashout.AmountAsDecimal - a.Cashout.AmountAsDecimal;
        });
        break;
      case Sort.LiveScore:
        this.tickets.Tickets = this.ticketService.sortLiveTickets(this.tickets.Tickets);
        break;
      case Sort.DatePlaced:
      default:
        this.tickets.Tickets.sort((a, b) => {
          return new Date(b.CreationDate).getTime() - new Date(a.CreationDate).getTime();
        });
        break;
    }

    this.showSortMenu = false;
    this.scrollToTop();
  }

  filterSettledTickets(filterBy: Filter) {
    this.analyticsManager.betsInteraction(BetsInteractionResult.Sort, this.isOpenTicketsTabActive());
    this.settledFilterSelection = filterBy;
    this.showSortMenu = false;
    this.filterAll = false;
    this.scrollToTop();
  }

  getFilteredTickets() {
    if(this.filterAll) {
      this.settledFilterSelection = Filter.All;
      return this.tickets.Tickets;
    } else {
      switch (this.settledFilterSelection) {
        case Filter.All:
          return this.tickets.Tickets;
        case Filter.Won:
          return this.tickets.Tickets.filter(x => x.Status === Status.Won)
        case Filter.Lost:
          return this.tickets.Tickets.filter(x => x.Status === Status.Lost)
      }
    }
  }

  getTickets() {
    return this.activeTab === 'Open' ? this.tickets.Tickets : this.getFilteredTickets();
  }

  unsubscribe() {
    if (this.refreshInterval) {
      this.refreshInterval.unsubscribe();
    }

    if (this.cashoutInterval) {
      this.cashoutInterval.unsubscribe();
    }

    if (this.ticketSubscription) {
      this.ticketSubscription.unsubscribe();
    }

    if (this.sendFixturesInterval) {
      this.sendFixturesInterval.unsubscribe();
    }

    if (this.ticketUpdateSubscription) {
      this.ticketUpdateSubscription.unsubscribe();
    }

    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }

  scrollToTicket() {
    if (this.addedTicket) {
      const ticket = document.querySelector(`#ticket${this.addedTicket.BetTicketNumber.substring(this.addedTicket.BetTicketNumber.length - 7)}`);

      if (ticket) {
        ticket.scrollIntoView();
      }
    }
  }

  showLoginNotification() {
    return !this.currentUser;
  }


  onToggleMediaEvent(betTicketNumber: string) {
    this.ticketsReference.forEach(ticket => ticket.hideMedia(betTicketNumber))
  }

  addTicket() {
    this.analyticsManager.betsInteraction(BetsInteractionResult.AddTicket, this.isOpenTicketsTabActive());
    this.router.navigate(['/add-ticket']);
  }

  onWindowScroll(event) {
    this.analyticsManager.betsInteraction(BetsInteractionResult.Scroll, this.isOpenTicketsTabActive());
  }

  private isOpenTicketsTabActive() {
    return this.activeTab === 'Open';
  }

  private scrollToTop() : void {
		this.eref.nativeElement.querySelector('.track-bet-tickets').scrollTo( 0, 0 );
  }
}
