import { Injectable } from '@angular/core';
import { AccaTrackerService, AuthService as AuthenticationService } from '../api/services';
import { map, catchError, take } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { AnalyticsManager } from '../shared/analytics-manager.service';
import { UserService } from '../shared/user.service';
import { LoginCreateAccountResult } from 'src/app/enums/loginCreateAccountResult.enum';
import { ThrowStmt } from '@angular/compiler';
import { SignalRService } from './signalr.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private accaTrackerService: AccaTrackerService,
    private authenticationService: AuthenticationService,
    private analyticsManager: AnalyticsManager,
    private userService: UserService,
    private signalRService: SignalRService
    ) {
  }

  login(username: string, password: string) {
    return this.accaTrackerService.AccaTrackerLoginByMobileNumber2(
      {
        apiVersion: 2,
        loginRequest: {
          MobileNumber: username,
          Password: password,
          ClientGuid: ''
        }
      }).pipe(
      map((resp) => {
        if (resp.Payload) {
          this.analyticsManager.loginCreateAccount(LoginCreateAccountResult.SuccessfulLogin, false);

          this.userService.storeUser(resp.Payload.MobileNumberCanonical);
          this.userService.anonymousUserId = null;
          this.signalRService.destroy();
          this.signalRService.init();
          return;
        }
      }),
      catchError(err => {
        this.analyticsManager.loginCreateAccount(LoginCreateAccountResult.StoppedAtPasswordInput, false);

        if (err.status === 401) {
          this.analyticsManager.loginError('Invalid password');

          return throwError({
            message: 'Invalid password'
          });
        } else {
          this.analyticsManager.loginError(err.ErrorResponse.message);

          return throwError({
            message: 'Unfortunately we cannot log you in at this time'
          });
        }
      })
    );
  }

  logout() {
    this.authenticationService.AuthLogout(1)
      .pipe(take(1))
      .subscribe(() => {
        this.userService.clearUser();
        this.signalRService.destroy();
      }, () => this.userService.clearUser());
  }
}

