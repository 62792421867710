import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SignalRConnection } from '../models/signal-r-connection';
import * as SignalR from '@microsoft/signalr';
import { AppSettingsService } from './app-settings.service';
import { UserService } from './user.service';
import { Fixture } from '../models/fixture';
import { Steer73PaddypowerCoreModelsLiveScoreModel, Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel } from '../api/models';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private signalRConnectionObservable: Subscription = null;

  fixtures: Subject<Steer73PaddypowerCoreModelsLiveScoreModel> = new Subject();
  tickets: Subject<Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel> = new Subject();
  subscriptions: string[] = [];

  private hubConnection: SignalR.HubConnection;

  constructor(private http: HttpClient,
              private appSettings: AppSettingsService,
              private userService: UserService) { }

  private getSignalRConnection(): Observable<SignalRConnection> {
    return this.http.post<SignalRConnection>(`${this.appSettings.fetch().Endpoints.SignalREndpoint}/api/negotiate`, null, this.getConfig());
  }

  private getConfig() {
    const config = {
      headers: { 'x-signalr-userid': this.userService.user ? this.userService.user.Number.substring(1) : this.userService.anonymousUserId }
    };
    return config;
  }

  init(): void {
    if (!this.signalRConnectionObservable && this.userService.user || this.userService.anonymousUserId) {
      this.initiateConnection();
    }
  }

  destroy() {
    if (this.signalRConnectionObservable) {
      this.signalRConnectionObservable.unsubscribe();
    }
  }

  initiateConnection() {
    this.signalRConnectionObservable = this.getSignalRConnection().subscribe(con => {
      if (!con) {
        this.destroy();
        return;
      }

      const options = {
        accessTokenFactory: () => con.accessToken
      };

      this.hubConnection = new SignalR.HubConnectionBuilder()
        .withUrl(con.url, options)
        .configureLogging(SignalR.LogLevel.Information)
        .build();

      this.hubConnection.on('notify',
        data => {
          console.log(`SignalR Message: ${data}`);
        });

      this.hubConnection.on('fixtureUpdate', this.updateFixtures.bind(this));

      this.hubConnection.on('RetaTicketUpdate', this.updateTickets.bind(this));

      this.hubConnection.start()
        .catch(error => console.error(error));

      this.hubConnection.serverTimeoutInMilliseconds = 300000;
      this.hubConnection.keepAliveIntervalInMilliseconds = 300000;

      this.hubConnection.onclose((error) => {
        this.hubConnection.start();
        console.error(`Something went wrong: ${error}`);
      });

    });
  }

  sendFixtures(fixtures: Fixture[]) {
    if (fixtures.length > 0) {
      return this.http.post(`${this.appSettings.fetch().Endpoints.SignalREndpoint}/api/ping`, {
        fixtures
      }).subscribe();
    }
  }

  updateFixtures(data: Steer73PaddypowerCoreModelsLiveScoreModel) {
      if (data) {
        this.fixtures.next(data);
      }
  }

  updateTickets(data: Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel) {
    if (data) {
      this.tickets.next(data);
    }
  }
}
