import { AppSettingsService } from './app-settings.service';
import { Router, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class GeoBlockingGuard implements CanActivate {

  constructor(private appSettings: AppSettingsService, private router: Router) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const geoblocked = this.appSettings.fetch().ErrorCode === '100000028' || false;

    if (geoblocked) {
      this.router.navigate(['geo-blocked'], {state: { blockChecked: true }});
    } else {
      return true;
    }
  }
}
