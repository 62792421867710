import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SignalRService } from './signalr.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _SHOWLOGINNOTIFICATION = true;
  private _INITIALLOAD = true;
  private _ANONYMOUSUSERID: string | null = null;

  private TOKEN = 'token';
  private USERKEY = 'user';
  private ANONYUSERID = 'anonuserid';

  private _USERDATA$: BehaviorSubject<any>;
  private currentUser = null;

  constructor() {
    this.currentUser = localStorage.getItem(this.USERKEY) ? JSON.parse(localStorage.getItem(this.USERKEY)) as User : null;
    this.anonymousUserId = localStorage.getItem(this.ANONYUSERID) ? localStorage.getItem(this.ANONYUSERID) : null;
    this._USERDATA$ = new BehaviorSubject(this.currentUser);
  }

  get user() {
    return this.currentUser;
  }

  get userData$() {
    return this._USERDATA$.asObservable();
  }

  get showLoginNotification() {
    return this._SHOWLOGINNOTIFICATION;
  }

  set showLoginNotification(value: boolean) {
    this._SHOWLOGINNOTIFICATION = value;
  }

  get initialLoad() {
    return this._INITIALLOAD;
  }
  set initialLoad(value: boolean) {
    this._INITIALLOAD = value;
  }

  get anonymousUserId() {
    return this._ANONYMOUSUSERID;
  }
  set anonymousUserId(value: string) {
    this._ANONYMOUSUSERID = value;

    if (value !== null) {
      localStorage.setItem(this.ANONYUSERID, value);
    }
  }

  getToken() {
    return localStorage.getItem(this.TOKEN);
  }

  setToken(token: string) {
    localStorage.setItem(this.TOKEN, token);
  }

  storeUser(userNumber: string) {
    const user = new User();
    user.Number = userNumber;
    localStorage.setItem(this.USERKEY, JSON.stringify(user));
    localStorage.removeItem(this.ANONYUSERID);
    this.anonymousUserId = null;
    this._USERDATA$.next(user);
  }

  clearUser() {
    localStorage.removeItem(this.TOKEN);
    localStorage.removeItem(this.USERKEY);
    localStorage.removeItem(this.ANONYUSERID);
    this.anonymousUserId = null;
    this._USERDATA$.next(null);
  }
}

export class User {
  Number: string;
}
