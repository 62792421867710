<app-header>
  <ng-container left>
    <a class="fas fa-times" (click)="goBack()"></a>
  </ng-container>
  <ng-container middle>
    Terms and Conditions
  </ng-container>
</app-header>

<div class="terms">
  <div class="terms__container">
    <div [innerHTML]="termsText" class="terms__success" *ngIf="show && !loading"></div>
    <div class="terms__error" *ngIf="!show && !loading">There is no data to show</div>
  </div>
</div>
<app-footer></app-footer>
