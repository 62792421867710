/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreTransferObjectsLmsSettingsResponse } from '../models/steer-73paddypower-core-dto-base-response-system-collections-generic-list-steer-73paddypower-core-transfer-objects-lms-settings-response';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCanUserAccessGameResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-transfer-objects-can-user-access-game-response';
import { Steer73PaddypowerCoreDtoLmsEnterGameRequest } from '../models/steer-73paddypower-core-dto-lms-enter-game-request';
import { Steer73PaddypowerCoreDtoBaseResponseSystemBoolean } from '../models/steer-73paddypower-core-dto-base-response-system-boolean';
import { Steer73PaddypowerCoreDtoLmsUseExtraLifeRequest } from '../models/steer-73paddypower-core-dto-lms-use-extra-life-request';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGamePeriodViewModel } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-game-period-view-model';
import { Steer73PaddypowerCoreDtoLmsGetWeeksAndFixturesRequest } from '../models/steer-73paddypower-core-dto-lms-get-weeks-and-fixtures-request';
import { Steer73PaddypowerCoreDtoBaseResponseSystemInt32 } from '../models/steer-73paddypower-core-dto-base-response-system-int-32';
import { Steer73PaddypowerCoreDtoLmsMakeSelectionRequest } from '../models/steer-73paddypower-core-dto-lms-make-selection-request';
import { Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerDataDomainResponsesGameEntrySelectionWithTeamResponse } from '../models/steer-73paddypower-core-dto-base-response-system-collections-generic-list-steer-73paddypower-data-domain-responses-game-entry-selection-with-team-response';
import { Steer73PaddypowerCoreDtoLmsGetGameEntryHistoryRequest } from '../models/steer-73paddypower-core-dto-lms-get-game-entry-history-request';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSGameEntrySelectionStatisticsResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-transfer-objects-lmsgame-entry-selection-statistics-response';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerStandingsResponseV2 } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-transfer-objects-lmsplayer-standings-response-v2';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerStandingsResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-transfer-objects-lmsplayer-standings-response';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerEliminationsResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-transfer-objects-lmsplayer-eliminations-response';
import { Steer73PaddypowerAccaTrackerControllersApiUpdateReceiveWeeklyReminderSmsRequest } from '../models/steer-73paddypower-acca-tracker-controllers-api-update-receive-weekly-reminder-sms-request';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoLMSYourPicksResult } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-lmsyour-picks-result';
@Injectable({
  providedIn: 'root',
})
class LMSService extends __BaseService {
  static readonly LMSGamesPath = '/api/lms/Games';
  static readonly LMSCheckGameAccessPath = '/api/lms/CheckGameAccess';
  static readonly LMSCheckGameAccessRestPath = '/api/lms/Games/{gameId}';
  static readonly LMSRegisterForGamePath = '/api/lms/RegisterForGame';
  static readonly LMSUseExtraLifePath = '/api/lms/UseExtraLife';
  static readonly LMSGetWeeksAndFixturesPath = '/api/lms/GetWeeksAndFixtures';
  static readonly LMSGetWeeksAndFixturesRestPath = '/api/lms/Entries/{gameEntryId}/GamePeriods';
  static readonly LMSGetWeeksAndFixturesAllowAnonRestPath = '/api/lms/Game/{gameId}/Entries/{gameEntryId}/GamePeriods';
  static readonly LMSGetNumberOfPlayersLeftPath = '/api/lms/Games/{gameId}/PlayersRemaining';
  static readonly LMSMakeSelectionPath = '/api/lms/MakeSelection';
  static readonly LMSGetHistoryPath = '/api/lms/GetHistory';
  static readonly LMSCheckNameAvailabilityPath = '/api/lms/Games/{gameId}/Players/{name}';
  static readonly LMSGetSelectionStatistics2Path = '/api/lms/Games/{gameId}/GamePeriods/Statistics';
  static readonly LMSGetStoreStandings2Path = '/api/lms/Games/{gameId}/gamePeriods/standings/stores/{storeId}';
  static readonly LMSGetNationalStandingsPath = '/api/lms/Games/{gameId}/gamePeriods/standings/National';
  static readonly LMSGetStoreEliminationsPath = '/api/lms/Games/{gameId}/Eliminations/UserStore';
  static readonly LMSGetNationalEliminationsPath = '/api/lms/Games/{gameId}/Eliminations/National';
  static readonly LMSUpdateReceiveWeeklyReminderSmsPath = '/api/lms/Entries/{gameEntryId}/UpdateReceiveWeeklyReminderSms';
  static readonly LMSGetYourPicksDataPath = '/api/lms/Game/{gameId}/YourPicks';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets a list active games
   *
   * No checking of the RefreshToken is performed.
   * @param api-version undefined
   * @return OK
   */
  LMSGamesResponse(apiVersion: number): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreTransferObjectsLmsSettingsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __headers = __headers.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/lms/Games`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreTransferObjectsLmsSettingsResponse>;
      })
    );
  }
  /**
   * Gets a list active games
   *
   * No checking of the RefreshToken is performed.
   * @param api-version undefined
   * @return OK
   */
  LMSGames(apiVersion: number): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreTransferObjectsLmsSettingsResponse> {
    return this.LMSGamesResponse(apiVersion).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreTransferObjectsLmsSettingsResponse)
    );
  }

  /**
   * Checks whether a user can access an LMS Game. Used by Legacy games - now deprecated.
   * @param params The `LMSService.LMSCheckGameAccessParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSCheckGameAccessResponse(params: LMSService.LMSCheckGameAccessParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCanUserAccessGameResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/lms/CheckGameAccess`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCanUserAccessGameResponse>;
      })
    );
  }
  /**
   * Checks whether a user can access an LMS Game. Used by Legacy games - now deprecated.
   * @param params The `LMSService.LMSCheckGameAccessParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSCheckGameAccess(params: LMSService.LMSCheckGameAccessParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCanUserAccessGameResponse> {
    return this.LMSCheckGameAccessResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCanUserAccessGameResponse)
    );
  }

  /**
   * Check if user has access to an LMS game and return essential info
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header
   * @param params The `LMSService.LMSCheckGameAccessRestParams` containing the following parameters:
   *
   * - `gameId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSCheckGameAccessRestResponse(params: LMSService.LMSCheckGameAccessRestParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCanUserAccessGameResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/lms/Games/${encodeURIComponent(String(params.gameId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCanUserAccessGameResponse>;
      })
    );
  }
  /**
   * Check if user has access to an LMS game and return essential info
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header
   * @param params The `LMSService.LMSCheckGameAccessRestParams` containing the following parameters:
   *
   * - `gameId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSCheckGameAccessRest(params: LMSService.LMSCheckGameAccessRestParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCanUserAccessGameResponse> {
    return this.LMSCheckGameAccessRestResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCanUserAccessGameResponse)
    );
  }

  /**
   * Registers the user for the current game
   * @param params The `LMSService.LMSRegisterForGameParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSRegisterForGameResponse(params: LMSService.LMSRegisterForGameParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCanUserAccessGameResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/lms/RegisterForGame`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCanUserAccessGameResponse>;
      })
    );
  }
  /**
   * Registers the user for the current game
   * @param params The `LMSService.LMSRegisterForGameParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSRegisterForGame(params: LMSService.LMSRegisterForGameParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCanUserAccessGameResponse> {
    return this.LMSRegisterForGameResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCanUserAccessGameResponse)
    );
  }

  /**
   * Consume one of the user's extra lives
   * @param params The `LMSService.LMSUseExtraLifeParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSUseExtraLifeResponse(params: LMSService.LMSUseExtraLifeParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/lms/UseExtraLife`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>;
      })
    );
  }
  /**
   * Consume one of the user's extra lives
   * @param params The `LMSService.LMSUseExtraLifeParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSUseExtraLife(params: LMSService.LMSUseExtraLifeParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean> {
    return this.LMSUseExtraLifeResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemBoolean)
    );
  }

  /**
   * Gets the Weeks and Fixtures for the current LMS Game / User
   * @param params The `LMSService.LMSGetWeeksAndFixturesParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetWeeksAndFixturesResponse(params: LMSService.LMSGetWeeksAndFixturesParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGamePeriodViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/lms/GetWeeksAndFixtures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGamePeriodViewModel>;
      })
    );
  }
  /**
   * Gets the Weeks and Fixtures for the current LMS Game / User
   * @param params The `LMSService.LMSGetWeeksAndFixturesParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetWeeksAndFixtures(params: LMSService.LMSGetWeeksAndFixturesParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGamePeriodViewModel> {
    return this.LMSGetWeeksAndFixturesResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGamePeriodViewModel)
    );
  }

  /**
   * Gets the Weeks and Fixtures for the current LMS Game / User. This version is a REST version of POST:GetWeeksAndFixtures.
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `LMSService.LMSGetWeeksAndFixturesRestParams` containing the following parameters:
   *
   * - `gameEntryId`: The LMS Game Entry ID for this user.
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetWeeksAndFixturesRestResponse(params: LMSService.LMSGetWeeksAndFixturesRestParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGamePeriodViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/lms/Entries/${encodeURIComponent(String(params.gameEntryId))}/GamePeriods`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGamePeriodViewModel>;
      })
    );
  }
  /**
   * Gets the Weeks and Fixtures for the current LMS Game / User. This version is a REST version of POST:GetWeeksAndFixtures.
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `LMSService.LMSGetWeeksAndFixturesRestParams` containing the following parameters:
   *
   * - `gameEntryId`: The LMS Game Entry ID for this user.
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetWeeksAndFixturesRest(params: LMSService.LMSGetWeeksAndFixturesRestParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGamePeriodViewModel> {
    return this.LMSGetWeeksAndFixturesRestResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGamePeriodViewModel)
    );
  }

  /**
   * Gets the Weeks and Fixtures for the current LMS Game / User. This version is a REST version of POST:GetWeeksAndFixtures.
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `LMSService.LMSGetWeeksAndFixturesAllowAnonRestParams` containing the following parameters:
   *
   * - `gameId`: The LMS Game ID.
   *
   * - `gameEntryId`: The LMS Game Entry ID for this user.
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetWeeksAndFixturesAllowAnonRestResponse(params: LMSService.LMSGetWeeksAndFixturesAllowAnonRestParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGamePeriodViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/lms/Game/${encodeURIComponent(String(params.gameId))}/Entries/${encodeURIComponent(String(params.gameEntryId))}/GamePeriods`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGamePeriodViewModel>;
      })
    );
  }
  /**
   * Gets the Weeks and Fixtures for the current LMS Game / User. This version is a REST version of POST:GetWeeksAndFixtures.
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `LMSService.LMSGetWeeksAndFixturesAllowAnonRestParams` containing the following parameters:
   *
   * - `gameId`: The LMS Game ID.
   *
   * - `gameEntryId`: The LMS Game Entry ID for this user.
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetWeeksAndFixturesAllowAnonRest(params: LMSService.LMSGetWeeksAndFixturesAllowAnonRestParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGamePeriodViewModel> {
    return this.LMSGetWeeksAndFixturesAllowAnonRestResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGamePeriodViewModel)
    );
  }

  /**
   * Returns the number of active players for the specified LMS Game. This is a statistic for the national game.
   * @param params The `LMSService.LMSGetNumberOfPlayersLeftParams` containing the following parameters:
   *
   * - `gameId`: The ID of the LMS Game to retrieve the active player count for.
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetNumberOfPlayersLeftResponse(params: LMSService.LMSGetNumberOfPlayersLeftParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemInt32>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/lms/Games/${encodeURIComponent(String(params.gameId))}/PlayersRemaining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemInt32>;
      })
    );
  }
  /**
   * Returns the number of active players for the specified LMS Game. This is a statistic for the national game.
   * @param params The `LMSService.LMSGetNumberOfPlayersLeftParams` containing the following parameters:
   *
   * - `gameId`: The ID of the LMS Game to retrieve the active player count for.
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetNumberOfPlayersLeft(params: LMSService.LMSGetNumberOfPlayersLeftParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemInt32> {
    return this.LMSGetNumberOfPlayersLeftResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemInt32)
    );
  }

  /**
   * Stores the user's selected team for the requested game period.
   * @param params The `LMSService.LMSMakeSelectionParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSMakeSelectionResponse(params: LMSService.LMSMakeSelectionParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/lms/MakeSelection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>;
      })
    );
  }
  /**
   * Stores the user's selected team for the requested game period.
   * @param params The `LMSService.LMSMakeSelectionParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSMakeSelection(params: LMSService.LMSMakeSelectionParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean> {
    return this.LMSMakeSelectionResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemBoolean)
    );
  }

  /**
   * Gets a list of the historical selections made by this user in the current LMS Game
   * @param params The `LMSService.LMSGetHistoryParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetHistoryResponse(params: LMSService.LMSGetHistoryParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerDataDomainResponsesGameEntrySelectionWithTeamResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/lms/GetHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerDataDomainResponsesGameEntrySelectionWithTeamResponse>;
      })
    );
  }
  /**
   * Gets a list of the historical selections made by this user in the current LMS Game
   * @param params The `LMSService.LMSGetHistoryParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetHistory(params: LMSService.LMSGetHistoryParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerDataDomainResponsesGameEntrySelectionWithTeamResponse> {
    return this.LMSGetHistoryResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerDataDomainResponsesGameEntrySelectionWithTeamResponse)
    );
  }

  /**
   * Check name availability and acceptability for given LMS game. Verifies the name is unique within the Game and that it doesn't contain any banned words
   * @param params The `LMSService.LMSCheckNameAvailabilityParams` containing the following parameters:
   *
   * - `name`: The name to check
   *
   * - `gameId`: The ID of the LMS Game to check the name for
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSCheckNameAvailabilityResponse(params: LMSService.LMSCheckNameAvailabilityParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/lms/Games/${encodeURIComponent(String(params.gameId))}/Players/${encodeURIComponent(String(params.name))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>;
      })
    );
  }
  /**
   * Check name availability and acceptability for given LMS game. Verifies the name is unique within the Game and that it doesn't contain any banned words
   * @param params The `LMSService.LMSCheckNameAvailabilityParams` containing the following parameters:
   *
   * - `name`: The name to check
   *
   * - `gameId`: The ID of the LMS Game to check the name for
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSCheckNameAvailability(params: LMSService.LMSCheckNameAvailabilityParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean> {
    return this.LMSCheckNameAvailabilityResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemBoolean)
    );
  }

  /**
   * Gets the team selection statistics for all active game periods for the specified LMS Game
   * @param params The `LMSService.LMSGetSelectionStatistics2Params` containing the following parameters:
   *
   * - `gameId`: The ID of the LMS Game
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetSelectionStatistics2Response(params: LMSService.LMSGetSelectionStatistics2Params): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSGameEntrySelectionStatisticsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/lms/Games/${encodeURIComponent(String(params.gameId))}/GamePeriods/Statistics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSGameEntrySelectionStatisticsResponse>;
      })
    );
  }
  /**
   * Gets the team selection statistics for all active game periods for the specified LMS Game
   * @param params The `LMSService.LMSGetSelectionStatistics2Params` containing the following parameters:
   *
   * - `gameId`: The ID of the LMS Game
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetSelectionStatistics2(params: LMSService.LMSGetSelectionStatistics2Params): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSGameEntrySelectionStatisticsResponse> {
    return this.LMSGetSelectionStatistics2Response(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSGameEntrySelectionStatisticsResponse)
    );
  }

  /**
   * Gets the players remaining in the specified store for the specified LMS Game
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `LMSService.LMSGetStoreStandings2Params` containing the following parameters:
   *
   * - `storeId`: The ID of the store
   *
   * - `gameId`: The ID of the LMS Game
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetStoreStandings2Response(params: LMSService.LMSGetStoreStandings2Params): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerStandingsResponseV2>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/lms/Games/${encodeURIComponent(String(params.gameId))}/gamePeriods/standings/stores/${encodeURIComponent(String(params.storeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerStandingsResponseV2>;
      })
    );
  }
  /**
   * Gets the players remaining in the specified store for the specified LMS Game
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `LMSService.LMSGetStoreStandings2Params` containing the following parameters:
   *
   * - `storeId`: The ID of the store
   *
   * - `gameId`: The ID of the LMS Game
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetStoreStandings2(params: LMSService.LMSGetStoreStandings2Params): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerStandingsResponseV2> {
    return this.LMSGetStoreStandings2Response(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerStandingsResponseV2)
    );
  }

  /**
   * Gets the players remaining nationally for the specified LMS Game
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `LMSService.LMSGetNationalStandingsParams` containing the following parameters:
   *
   * - `gameId`: The ID of the LMS Game
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetNationalStandingsResponse(params: LMSService.LMSGetNationalStandingsParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerStandingsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/lms/Games/${encodeURIComponent(String(params.gameId))}/gamePeriods/standings/National`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerStandingsResponse>;
      })
    );
  }
  /**
   * Gets the players remaining nationally for the specified LMS Game
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `LMSService.LMSGetNationalStandingsParams` containing the following parameters:
   *
   * - `gameId`: The ID of the LMS Game
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetNationalStandings(params: LMSService.LMSGetNationalStandingsParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerStandingsResponse> {
    return this.LMSGetNationalStandingsResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerStandingsResponse)
    );
  }

  /**
   * Gets the details of the players eliminated in the previous week from the user's store
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `LMSService.LMSGetStoreEliminationsParams` containing the following parameters:
   *
   * - `gameId`: The ID of the LMS Game
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetStoreEliminationsResponse(params: LMSService.LMSGetStoreEliminationsParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerEliminationsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/lms/Games/${encodeURIComponent(String(params.gameId))}/Eliminations/UserStore`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerEliminationsResponse>;
      })
    );
  }
  /**
   * Gets the details of the players eliminated in the previous week from the user's store
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `LMSService.LMSGetStoreEliminationsParams` containing the following parameters:
   *
   * - `gameId`: The ID of the LMS Game
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetStoreEliminations(params: LMSService.LMSGetStoreEliminationsParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerEliminationsResponse> {
    return this.LMSGetStoreEliminationsResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerEliminationsResponse)
    );
  }

  /**
   * Gets the details of the players eliminated in the previous week from national game
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `LMSService.LMSGetNationalEliminationsParams` containing the following parameters:
   *
   * - `gameId`: The ID of the LMS Game
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetNationalEliminationsResponse(params: LMSService.LMSGetNationalEliminationsParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerEliminationsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/lms/Games/${encodeURIComponent(String(params.gameId))}/Eliminations/National`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerEliminationsResponse>;
      })
    );
  }
  /**
   * Gets the details of the players eliminated in the previous week from national game
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header.
   * @param params The `LMSService.LMSGetNationalEliminationsParams` containing the following parameters:
   *
   * - `gameId`: The ID of the LMS Game
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetNationalEliminations(params: LMSService.LMSGetNationalEliminationsParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerEliminationsResponse> {
    return this.LMSGetNationalEliminationsResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsLMSPlayerEliminationsResponse)
    );
  }

  /**
   * @param params The `LMSService.LMSUpdateReceiveWeeklyReminderSmsParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `gameEntryId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSUpdateReceiveWeeklyReminderSmsResponse(params: LMSService.LMSUpdateReceiveWeeklyReminderSmsParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/lms/Entries/${encodeURIComponent(String(params.gameEntryId))}/UpdateReceiveWeeklyReminderSms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>;
      })
    );
  }
  /**
   * @param params The `LMSService.LMSUpdateReceiveWeeklyReminderSmsParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `gameEntryId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSUpdateReceiveWeeklyReminderSms(params: LMSService.LMSUpdateReceiveWeeklyReminderSmsParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean> {
    return this.LMSUpdateReceiveWeeklyReminderSmsResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemBoolean)
    );
  }

  /**
   * @param params The `LMSService.LMSGetYourPicksDataParams` containing the following parameters:
   *
   * - `gameId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetYourPicksDataResponse(params: LMSService.LMSGetYourPicksDataParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoLMSYourPicksResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/lms/Game/${encodeURIComponent(String(params.gameId))}/YourPicks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoLMSYourPicksResult>;
      })
    );
  }
  /**
   * @param params The `LMSService.LMSGetYourPicksDataParams` containing the following parameters:
   *
   * - `gameId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  LMSGetYourPicksData(params: LMSService.LMSGetYourPicksDataParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoLMSYourPicksResult> {
    return this.LMSGetYourPicksDataResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoLMSYourPicksResult)
    );
  }
}

module LMSService {

  /**
   * Parameters for LMSCheckGameAccess
   */
  export interface LMSCheckGameAccessParams {
    request: Steer73PaddypowerCoreDtoLmsEnterGameRequest;
    apiVersion: number;
  }

  /**
   * Parameters for LMSCheckGameAccessRest
   */
  export interface LMSCheckGameAccessRestParams {
    gameId: number;
    apiVersion: number;
  }

  /**
   * Parameters for LMSRegisterForGame
   */
  export interface LMSRegisterForGameParams {
    request: Steer73PaddypowerCoreDtoLmsEnterGameRequest;
    apiVersion: number;
  }

  /**
   * Parameters for LMSUseExtraLife
   */
  export interface LMSUseExtraLifeParams {
    request: Steer73PaddypowerCoreDtoLmsUseExtraLifeRequest;
    apiVersion: number;
  }

  /**
   * Parameters for LMSGetWeeksAndFixtures
   */
  export interface LMSGetWeeksAndFixturesParams {
    request: Steer73PaddypowerCoreDtoLmsGetWeeksAndFixturesRequest;
    apiVersion: number;
  }

  /**
   * Parameters for LMSGetWeeksAndFixturesRest
   */
  export interface LMSGetWeeksAndFixturesRestParams {

    /**
     * The LMS Game Entry ID for this user.
     */
    gameEntryId: number;
    apiVersion: number;
  }

  /**
   * Parameters for LMSGetWeeksAndFixturesAllowAnonRest
   */
  export interface LMSGetWeeksAndFixturesAllowAnonRestParams {

    /**
     * The LMS Game ID.
     */
    gameId: number;

    /**
     * The LMS Game Entry ID for this user.
     */
    gameEntryId: number;
    apiVersion: number;
  }

  /**
   * Parameters for LMSGetNumberOfPlayersLeft
   */
  export interface LMSGetNumberOfPlayersLeftParams {

    /**
     * The ID of the LMS Game to retrieve the active player count for.
     */
    gameId: number;
    apiVersion: number;
  }

  /**
   * Parameters for LMSMakeSelection
   */
  export interface LMSMakeSelectionParams {
    request: Steer73PaddypowerCoreDtoLmsMakeSelectionRequest;
    apiVersion: number;
  }

  /**
   * Parameters for LMSGetHistory
   */
  export interface LMSGetHistoryParams {
    request: Steer73PaddypowerCoreDtoLmsGetGameEntryHistoryRequest;
    apiVersion: number;
  }

  /**
   * Parameters for LMSCheckNameAvailability
   */
  export interface LMSCheckNameAvailabilityParams {

    /**
     * The name to check
     */
    name: string;

    /**
     * The ID of the LMS Game to check the name for
     */
    gameId: number;
    apiVersion: number;
  }

  /**
   * Parameters for LMSGetSelectionStatistics2
   */
  export interface LMSGetSelectionStatistics2Params {

    /**
     * The ID of the LMS Game
     */
    gameId: number;
    apiVersion: number;
  }

  /**
   * Parameters for LMSGetStoreStandings2
   */
  export interface LMSGetStoreStandings2Params {

    /**
     * The ID of the store
     */
    storeId: number;

    /**
     * The ID of the LMS Game
     */
    gameId: number;
    apiVersion: number;
  }

  /**
   * Parameters for LMSGetNationalStandings
   */
  export interface LMSGetNationalStandingsParams {

    /**
     * The ID of the LMS Game
     */
    gameId: number;
    apiVersion: number;
  }

  /**
   * Parameters for LMSGetStoreEliminations
   */
  export interface LMSGetStoreEliminationsParams {

    /**
     * The ID of the LMS Game
     */
    gameId: number;
    apiVersion: number;
  }

  /**
   * Parameters for LMSGetNationalEliminations
   */
  export interface LMSGetNationalEliminationsParams {

    /**
     * The ID of the LMS Game
     */
    gameId: number;
    apiVersion: number;
  }

  /**
   * Parameters for LMSUpdateReceiveWeeklyReminderSms
   */
  export interface LMSUpdateReceiveWeeklyReminderSmsParams {
    request: Steer73PaddypowerAccaTrackerControllersApiUpdateReceiveWeeklyReminderSmsRequest;
    gameEntryId: number;
    apiVersion: number;
  }

  /**
   * Parameters for LMSGetYourPicksData
   */
  export interface LMSGetYourPicksDataParams {
    gameId: number;
    apiVersion: number;
  }
}

export { LMSService }
