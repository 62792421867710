<app-header>
  <ng-container left>
    <a class="fas fa-times" (click)="goBack()"></a>
  </ng-container>
  <ng-container middle>
    Add a ticket
  </ng-container>
</app-header>

<div class="add-ticket" [ngClass]="{'add-ticket--success': success}">
  <div class="add-ticket__container mw">
    <form (ngSubmit)="addTicket()" [formGroup]="form" *ngIf="form && isManualSelection" class="add-ticket__form">
      <img class="add-ticket__img" src="/assets/images/icons/add-bet.svg" alt="" width="320" height="200" />

      <p class="add-ticket__message message">Enter your ticket ID</p>

      <div class="form__group">
        <div class="form__input-with-icon">
          <span class="form__icon">B</span>
          <input type="text" inputmode="numeric" formControlName="betticketnumber"
            class="form__input" maxlength="{{longTicketLength}}"  [ngClass]="{'form__input--error' : showError && errorMessage}" (keyup.enter)="$event.target.blur()"/>
        </div>
        <div class="form__errors" *ngIf="showError && errorMessage">{{errorMessage}}</div>

        <p class="small-text">Your ticket ID is located near the top of your bet ticket.</p>
      </div>

      <button type="submit" [disabled]="submitted || form.controls.betticketnumber.invalid" class="button button--large button--center">
        <i *ngIf="submitted; else buttonText" class="spinner spinner--light spinner--small"></i>
        <ng-template #buttonText>Submit</ng-template>
      </button>
    </form>

    <div class="add-ticket__barcode" [hidden]="isManualSelection" *ngIf="supportsCamera()">
      <barcode-scanner-livestream
        type="code_128"
        (valueChanges)="onValueChanges($event)"
        max-width="100%"
        max-height="300"
      ></barcode-scanner-livestream>
    </div>
  </div>

  <div class="button-group mw" *ngIf="supportsCamera()">
    <button (click)="changeInputType(false)" class="button button--large" [ngClass]="{'is-active': !isManualSelection}">Barcode scanner</button>
    <button (click)="changeInputType(true)" class="button button--large" [ngClass]="{'is-active': isManualSelection}">Manual Entry</button>
  </div>

  <div class="overlay" *ngIf="success || (!isManualSelection && showError)"></div>
  <div class="overlay-container" *ngIf="success">
    <div class="overlay-container__inner">
      <div class="overlay-container__success">
        <i class="far fa-check-circle overlay-container__icon"></i>

        <p class="overlay-container__para">Ticket {{form.controls.betticketnumber.value}} has been added to your bets.</p>

        <div class="button-group">
          <button class="button button--large button--alpha" (click)="reset()">Add another</button>
          <a class="button button--large" (click)="viewBet()">View Bet</a>
        </div>
      </div>
    </div>
  </div>

  <div class="overlay-container overlay-container--error" *ngIf="!isManualSelection && showError && errorMessage">
    <div class="overlay-container__inner">
      <div class="overlay-container__error">
        <p class="overlay-container__para">
          {{errorMessage}}
        </p>

        <div class="button-group">
          <button class="button button--large" (click)="reset()">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>

