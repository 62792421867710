export const environment = {
  production: false,
  baseUrl: 'https://onside-lms.s73.co',
  appInsights: {
    instrumentationKey: '8d934473-3797-4ef0-a432-625a2fdcb76a',
    enableDebug: false
  },
  oneTrust: {
    id: '723526cc-21d1-4657-bf6d-9f338e9cf26e-test'
  },
  firebase: {
    apiKey: "AIzaSyDUJXOEIqX4tJB1YmbY_TZgdNVbmKtFB0w",
    authDomain: "pp-onside-native-apps.firebaseapp.com",
    databaseURL: "https://pp-onside-native-apps.firebaseio.com",
    projectId: "pp-onside-native-apps",
    storageBucket: "pp-onside-native-apps.appspot.com",
    messagingSenderId: "598140900178",
    appId: "1:598140900178:web:2f2cd28c1d9f6386cf208b",
    measurementId: "G-SJ3R5B9BNB",
    enableDebug: true
  }
};