import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { UserService, User } from '../../../shared/user.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit, OnDestroy {
  authSubscription: Subscription;
  termsLoading = true;
  currentUser: User = null;

  @ViewChild('skipBtn') containerOne: ElementRef;
  @ViewChild('nextBtn') containerTwo: ElementRef;
  activeSlideIndex = 0;


  constructor(
    private titleService: Title,
    private eref: ElementRef,
    private renderer: Renderer2,
    private userService: UserService,
    private router: Router
  ) {
    this.titleService.setTitle('Onboarding');
    localStorage.setItem('seenOnboarding', 'true');
  }

  ngOnInit(): void {
    this.authSubscription = this.userService.userData$
    .subscribe((user) => {
      this.currentUser = user;
    });
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.initSlider();
  }

  createPagination(label) {
    const paginator = this.renderer.createElement('span');
    const dynamicControls = this.eref.nativeElement.getElementsByClassName('slider__pagination')[0];
    this.renderer.addClass(paginator, 'switch');
    paginator.setAttribute('data-id', label);
    paginator.addEventListener('click', (event) => {
      const targetDataid = event.target.getAttribute('data-id');
      this.activeSlideIndex = Number(targetDataid);
      this.navigateSlides(this.activeSlideIndex);
    });
    dynamicControls.appendChild(paginator);
  }

  initSlider() {
    let switches = this.eref.nativeElement.getElementsByClassName('switch');
    const slides = this.eref.nativeElement.getElementsByClassName('slider__content');
    const bottomSlides = this.eref.nativeElement.getElementsByClassName('slider__bottom_content');
    const activeClass = 'selected';
    if(slides.length > 1){
      for (let i = 0; i < slides.length; ++i){
        this.createPagination(i);
      };
    }
    switches = this.eref.nativeElement.getElementsByClassName('switch');
    this.renderer.addClass(slides[0], activeClass);
    this.renderer.addClass(bottomSlides[0], activeClass);
    this.renderer.addClass(switches[0], activeClass);
    this.switchLastSlide();
  }

  switchLastSlide() {
    const slides = this.eref.nativeElement.getElementsByClassName('slider__content');
    const controls = this.eref.nativeElement.getElementsByClassName('slider__footer')[0];
    if (this.activeSlideIndex + 1 === slides.length) {
      this.renderer.addClass(controls, 'on-last-slide');
    } else{
      this.renderer.removeClass(controls, 'on-last-slide');
    }
  }

  navigateSlides(slideIndex) {
    const slides = this.eref.nativeElement.getElementsByClassName('slider__content');
    const bottomSlides = this.eref.nativeElement.getElementsByClassName('slider__bottom_content');
    const switches = this.eref.nativeElement.getElementsByClassName('switch');
    const activeClass = 'selected';

    for (let i = 0; i < slides.length; ++i){
      this.renderer.removeClass(slides[i], activeClass);
      this.renderer.removeClass(bottomSlides[i], activeClass);
    }
    this.renderer.addClass(slides[slideIndex], activeClass);
    this.renderer.addClass(bottomSlides[slideIndex], activeClass);
    for (let i = 0; i < slides.length; ++i){
      this.renderer.removeClass(switches[i], activeClass);
    }
    this.renderer.addClass(switches[slideIndex], activeClass);
    this.switchLastSlide()
  }

  nextSlides() {
    const slides = this.eref.nativeElement.getElementsByClassName('slider__content');
    if(this.activeSlideIndex < slides.length - 1){
      this.activeSlideIndex++;
      this.navigateSlides(this.activeSlideIndex);
    }
  }

  finishOnboarding() {
    if(this.currentUser) {
      this.router.navigate(['track-my-bet']);
    } else {
      this.router.navigate(['home']);
    }
  }

}
