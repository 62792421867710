import { Component, OnInit } from '@angular/core';
import { GamesService } from '../../../api/services';
import { Title } from '@angular/platform-browser';
import { AppSettingsService } from 'src/app/shared/app-settings.service';

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit {
  imageUri: string;
  loading = true;

  constructor(
    private titleService: Title,
    private gamesService: GamesService,
    private appSettingsService: AppSettingsService
  ) {
    this.titleService.setTitle('Games');
  }

  ngOnInit(): void {
    this.getImageUri();
    this.loading = false;
  }

  getImageUri() {
    const appSettings = this.appSettingsService.fetch();
    this.imageUri = appSettings.ImagesUrls?.GamesTabImageUrl;
  }

}
