<div class="login mw">
  <h1 class="login__title">Hello <br>There<span>.</span></h1>

  <form (ngSubmit)="login()" [formGroup]="form" *ngIf="form" class="form">
    <div class="form__group">
      <label for="number" class="form__label" [ngClass]="{'form__label--error' : showError && f.number.errors}">Your phone number</label>
      <div class="form__input-with-icon">
        <i class="fas fa-mobile-alt form__icon login__mobile"></i>
        <input id="number" type="text" inputmode="tel" placeholder="" formControlName="number" class="form__input" [ngClass]="{'form__input--error' : showError && f.number.errors}"/>
      </div>
      <p class="form__errors" *ngIf="showError && f.number.errors && f.number.errors.required">Phone number required</p>
      <p class="form__errors" *ngIf="showError && f.number.errors && f.number.errors.pattern">Whoops! Your phone number has to be 10 or 11 digits</p>
    </div>

    <div class="form__group">
      <label for="password" class="form__label" [ngClass]="{'form__label--error' : showError && errorMessage}">Password</label>
      <div class="form__input-with-icon form__input-with-icon--right">
        <i class="fas fa-unlock-alt form__icon"></i>
        <input id="password" [type]="showPassword ? 'text' : 'password'" placeholder="" formControlName="password" class="form__input" [ngClass]="{'form__input--error' : showError && errorMessage}" (keyup.enter)="$event.target.blur()"/>
        <i class="fas form__icon login__eye" [ngClass]="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}" (click)="togglePassword()"></i>
      </div>
      <p class="form__errors" *ngIf="showError && f.password.errors && f.password.errors.required">Password required</p>
      <p class="form__errors" *ngIf="showError && errorMessage">{{errorMessage}}</p>
    </div>

    <a (click)="resetPassword()" class="login__forgot">Forgot your password?</a>

    <button type="submit" [disabled]="isLoading" class="button button--large button--center login__button">
      <i *ngIf="isLoading; else buttonText" class="spinner spinner--light spinner--small"></i>
      <ng-template #buttonText>Log in</ng-template>
    </button>
  </form>

  <div class="create">
    <a (click)="createAccount()" class="create__link">Create a new account</a>
    <div class="hr-helper">
      <span class="hr-helper__stroke"></span>
      <span class="hr-helper__text">or</span>
    </div>
    <a [routerLink]="['/home']" class="button button--large button--center button--alpha">Track bets as a guest</a>
  </div>
</div>


