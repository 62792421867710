import { Injectable } from '@angular/core';
import { SettingsService } from '../api/services';
import { AppSettingsService } from './app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class StartupService {

  constructor(private settingsService: SettingsService,
              private appSettings: AppSettingsService) { }

  async loadSettings() {
    const res = await this.settingsService.SettingsGetSettings(1).toPromise();

    this.appSettings.store(res.Payload);
  }
}
