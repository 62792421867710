<div class="cashout">
  <div class="cashout__available" *ngIf="cashout.IsAvailable" (click)="showCashoutSlider()">
    <div class="cashout__info">
      <span class="icon icon--cash-white"></span>
      Cashout Available
      <span class="cashout__amount">{{cashout.AmountAsDecimal | currency: currency }}</span>
    </div>
    <ng-container *ngIf="!showSlider && !showOptions">
      <div class="cashout__slider dragdealer" appSlider (sliderComplete)="showOptions = true">
        <div class="dragdealer__text" (click)="$event.stopPropagation()">Slide to cash out</div>
        <div class="fa fa-arrow-right dragdealer__handle handle" (click)="$event.stopPropagation()">
          <div (click)="$event.stopPropagation()">
            <span (click)="$event.stopPropagation()">Slide to cash out</span>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="cashout__options" *ngIf="showOptions">
      <button class="button" (click)="confirmCashOut()">Confirm</button>
      <button class="button" (click)="showOptions = false;">Cancel</button>
    </div>
  </div>
  <div class="cashout__unavailable" *ngIf="!cashout.IsAvailable">
    <span class="icon icon--cash"></span>
    Cashout Unavailable
    <i class="fas fas-question-circle"></i>
  </div>
</div>
