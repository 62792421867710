import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { TermsService } from '../../../api/services';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {
  termsText: string;
  show = true;
  loading = true;

  constructor(
    private titleService: Title,
    private termsService: TermsService,
    private location: Location
  ) {
    this.titleService.setTitle('Terms and conditions');
  }

  ngOnInit(): void {
    this.loadTerms();
  }

  goBack() {
    this.location.back();
  }

  loadTerms() {
    this.termsService.TermsGetLatest(1)
    .pipe(take(1))
    .subscribe((res) => {
      this.termsText = res.Payload;
      this.show = true;
      this.loading = false;
    },
    (err) => {
      this.show = false;
      this.loading = false;
    });
  }
}
