import { Component, OnInit } from '@angular/core';
import { SideMenuService } from 'src/app/shared/side-menu.service';
import { AuthService } from 'src/app/shared/auth.service';
import { UserService } from 'src/app/shared/user.service';
import { Subscription } from 'rxjs';
import { AccaTrackerService } from 'src/app/api/services';
import { Router, NavigationStart } from '@angular/router';
import { take } from 'rxjs/operators';
import { MenuSmsMarketingResult } from 'src/app/enums/menuSmsMarketingResult.enum';
import { AnalyticsManager } from '../../../shared/analytics-manager.service';

declare let OneTrust: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  hasMarketing: boolean;
  subscription: Subscription;
  disableMarketing: boolean;


  constructor(private sideMenuService: SideMenuService,
              private authService: AuthService,
              private userService: UserService,
              private accaTrackerService: AccaTrackerService,
              private analyticsManager: AnalyticsManager,
              private router: Router) {
                router.events.subscribe(event => {

                  if (event instanceof NavigationStart) {
                      this.hideMenu();
                  }
                });
              }


  get currentUser() {
    return this.userService.userData$;
  }

  ngOnInit() {
    this.loadUserProfile();
  }

  hideMenu() {
    this.sideMenuService.hideMenu();
  }

  toggleSwitch(acceptMarketing) {

    this.analyticsManager.menuSmsMarketing(acceptMarketing ? MenuSmsMarketingResult.OptIn : MenuSmsMarketingResult.OptOut);

    this.disableMarketing = true;
    this.accaTrackerService.AccaTrackerUpdateUserProfileByMobileNumber({
      apiVersion: 1,
      updateRequest: {
      AcceptMarketingMsgs: acceptMarketing, // update with the toggle value
      ExistingRefreshToken: this.userService.getToken(),
      ClientGuid: null
    }})
    .pipe(take(1))
    .subscribe(
      (res) => {
        this.disableMarketing = false;
        if (!res.Payload.Success) {
          this.hasMarketing = !this.hasMarketing;
        }
      },
      (error) => {
        this.disableMarketing = false;
        this.hasMarketing = !this.hasMarketing;
      });
  }

  loadUserProfile() {
    this.accaTrackerService.AccaTrackerGetUserProfileByMobileNumber({
      apiVersion: 1,
      request: {
        ExistingRefreshToken: this.userService.getToken(),
        ClientGuid: null,
        DeviceId: null,
        PushChannelId: null,
        PushEnabled: null
      }
    })
    .pipe(take(1))
    .subscribe(
      (res) => {
        this.hasMarketing = res.Payload.Profile.AcceptMarketingMsgs;
      }
    );
  }

  manageCookie() {
    OneTrust.ToggleInfoDisplay();
  }

  logout() {
    this.authService.logout();
    this.hideMenu();
  }
}
