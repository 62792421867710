/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AccaTrackerService } from './services/acca-tracker.service';
import { AnnoucementService } from './services/annoucement.service';
import { AppVersionsService } from './services/app-versions.service';
import { AtTheRacesV1Service } from './services/at-the-races-v1.service';
import { AuthService } from './services/auth.service';
import { BetTicketsService } from './services/bet-tickets.service';
import { BgtV1Service } from './services/bgt-v1.service';
import { FaqsService } from './services/faqs.service';
import { GamesService } from './services/games.service';
import { HomeService } from './services/home.service';
import { LMSService } from './services/lms.service';
import { MediaService } from './services/media.service';
import { MonitorService } from './services/monitor.service';
import { PerformV1Service } from './services/perform-v1.service';
import { PromoV2Service } from './services/promo-v2.service';
import { RewardsService } from './services/rewards.service';
import { SettingsService } from './services/settings.service';
import { StoreService } from './services/store.service';
import { StreaksService } from './services/streaks.service';
import { TermsService } from './services/terms.service';
import { TestService } from './services/test.service';
import { TestV1Service } from './services/test-v1.service';
import { TestV2Service } from './services/test-v2.service';
import { UserService } from './services/user.service';
import { WelcomeOnboardingService } from './services/welcome-onboarding.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AccaTrackerService,
    AnnoucementService,
    AppVersionsService,
    AtTheRacesV1Service,
    AuthService,
    BetTicketsService,
    BgtV1Service,
    FaqsService,
    GamesService,
    HomeService,
    LMSService,
    MediaService,
    MonitorService,
    PerformV1Service,
    PromoV2Service,
    RewardsService,
    SettingsService,
    StoreService,
    StreaksService,
    TermsService,
    TestService,
    TestV1Service,
    TestV2Service,
    UserService,
    WelcomeOnboardingService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
