import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel } from 'src/app/api/models';
import { Status } from 'src/app/enums/status.enum';
import { TicketService } from 'src/app/shared/ticket.service';
import { Router } from '@angular/router';
import { AnalyticsManager } from '../../../shared/analytics-manager.service';
import { BetCardInteractionResult } from 'src/app/enums/betCardInteractionResult.enum';

@Component({
  selector: 'app-bet-ticket',
  templateUrl: './bet-ticket.component.html',
  styleUrls: ['./bet-ticket.component.scss']
})
export class BetTicketComponent implements OnInit, OnDestroy {
  @Output() toggleMediaEvent = new EventEmitter<string>();
  @Input() ticket: Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel;
  showCardInfo = false;
  showMedia = false;

  constructor(private ticketService: TicketService,
              private router: Router, 
              private analyticsManager: AnalyticsManager
              ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  flipCard() {
    this.showCardInfo = !this.showCardInfo;
    
    if(this.showCardInfo) {
      this.analyticsManager.betCardInteraction(BetCardInteractionResult.BetTicketInfo);
    }
  }

  hasTicketWon() {
    return this.ticket.Status === Status.Won;
  }

  hasTicketLost() {
    return this.ticket.Status === Status.Lost;
  }

  isTicketCancelled() {
    return this.ticket.Status === Status.Cancelled;
  }

  isTicketOpen() {
    return this.ticketService.isTicketOpen(this.ticket);
  }

  hasLiveMedia() {
    return this.ticketService.hasLiveStream(this.ticket);
  }

  showCollectedStatus() {
    return !this.ticketService.isTicketOpen(this.ticket) && !this.hasTicketLost() && !this.isTicketCancelled();
  }

  getCollectedMessage() {
    return this.ticketService.getCollectedMessage(this.ticket);
  }

  trackItem(index) {
    return index;
  }

  toggleMedia() {
    this.showMedia = !this.showMedia;

    if(this.showMedia) {
      this.analyticsManager.betCardInteraction(BetCardInteractionResult.Stream);
    }
    this.toggleMediaEvent.emit(this.ticket.BetTicketNumber);
  }

  hideMedia(ticketNumber) {
    if (ticketNumber !== this.ticket.BetTicketNumber) {
      this.showMedia = false;
    }
  }

  confirmCashout() {
    this.router.navigateByUrl('cash-out', {
      state: {
        CashoutAmountAsPennies: this.ticket.Cashout.AmountAsPennies,
        MobileNumber: null,
        Pin: null,
        UpdateId: this.ticket.UpdateId,
        CurrencySymbol: this.ticket.CurrencyAsSymbol,
        BetTicketNumber: this.ticket.BetTicketNumber,
        BetSlipId: this.ticket.Cashout.BetSlipId,
        PointOfSaleId: this.ticket.PointOfSaleId
    }});
  }

}
