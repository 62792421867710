import { Component, Input, Output } from '@angular/core';
import { Steer73PaddypowerCoreServicesBetTicketManagerCashoutModel } from 'src/app/api/models';
import { EventEmitter } from '@angular/core';
import { AnalyticsManager } from '../../../shared/analytics-manager.service';
import { BetCardInteractionResult } from 'src/app/enums/betCardInteractionResult.enum';

@Component({
  selector: 'app-cash-out',
  templateUrl: './cash-out.component.html',
  styleUrls: ['./cash-out.component.scss']
})
export class CashOutComponent {
  @Input() cashout: Steer73PaddypowerCoreServicesBetTicketManagerCashoutModel;
  @Input() currency: string;
  @Output() confirmCashout = new EventEmitter();

  showSlider = true;
  showOptions = false;

  constructor(
    private analyticsManager: AnalyticsManager
  ) {

  }

  confirmCashOut() {
    this.confirmCashout.emit(null);
  }

  showCashoutSlider() {
    this.showSlider = !this.showSlider;
    this.analyticsManager.betCardInteraction(!this.showSlider ? BetCardInteractionResult.OpenCashoutSlider : BetCardInteractionResult.CloseCashoutSlider);

  }
}
