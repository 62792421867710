/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreMediaProvidersModelsGetStreamingRequestResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-media-providers-models-get-streaming-request-response';
@Injectable({
  providedIn: 'root',
})
class MediaService extends __BaseService {
  static readonly MediaGetMediaStreamUrlPath = '/api/media/UserQualified/{mediaType}/{provider}/{eventData}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Contacts the provider to obtain a URL for the selected media strea
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header
   * @param params The `MediaService.MediaGetMediaStreamUrlParams` containing the following parameters:
   *
   * - `provider`: The name of the procider who provides the stream
   *
   * - `mediaType`:
   *
   * - `eventData`: The unique identifier for the stream - this is provided initially by the provider
   *
   * - `api-version`:
   *
   * @return OK
   */
  MediaGetMediaStreamUrlResponse(params: MediaService.MediaGetMediaStreamUrlParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreMediaProvidersModelsGetStreamingRequestResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/media/UserQualified/${encodeURIComponent(String(params.mediaType))}/${encodeURIComponent(String(params.provider))}/${encodeURIComponent(String(params.eventData))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreMediaProvidersModelsGetStreamingRequestResponse>;
      })
    );
  }
  /**
   * Contacts the provider to obtain a URL for the selected media strea
   *
   * Requires the RefreshToken to be provided in the "X-Refresh-Token" request header
   * @param params The `MediaService.MediaGetMediaStreamUrlParams` containing the following parameters:
   *
   * - `provider`: The name of the procider who provides the stream
   *
   * - `mediaType`:
   *
   * - `eventData`: The unique identifier for the stream - this is provided initially by the provider
   *
   * - `api-version`:
   *
   * @return OK
   */
  MediaGetMediaStreamUrl(params: MediaService.MediaGetMediaStreamUrlParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreMediaProvidersModelsGetStreamingRequestResponse> {
    return this.MediaGetMediaStreamUrlResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreMediaProvidersModelsGetStreamingRequestResponse)
    );
  }
}

module MediaService {

  /**
   * Parameters for MediaGetMediaStreamUrl
   */
  export interface MediaGetMediaStreamUrlParams {

    /**
     * The name of the procider who provides the stream
     */
    provider: string;
    mediaType: 1 | 2;

    /**
     * The unique identifier for the stream - this is provided initially by the provider
     */
    eventData: string;
    apiVersion: number;
  }
}

export { MediaService }
