import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService, User } from 'src/app/shared/user.service';
import { Subscription } from 'rxjs';
import { Steer73PaddypowerCoreTransferObjectsFreeBetCodeItem } from 'src/app/api/models';
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AccaTrackerService } from 'src/app/api/services/acca-tracker.service';
import { AppSettingsService } from 'src/app/shared/app-settings.service';
import { AnalyticsManager } from '../../../../shared/analytics-manager.service';
import { RewardsBetWithPlayResult } from 'src/app/enums/rewardsBetWithPlayResult.enum';

@Component({
  selector: 'app-new-rewards',
  templateUrl: './new-rewards.component.html',
  styleUrls: ['./new-rewards.component.scss']
})
export class NewRewardsComponent implements OnInit, OnDestroy {
  authSubscription: Subscription;
  currentUser: User = null;
  currencySymbol: string;
  averageStake: string;
  stamps: number;
  freeBetCodes: Array<Steer73PaddypowerCoreTransferObjectsFreeBetCodeItem>;
  showPlayReasons = false;
  status;

  constructor(
    private titleService: Title,
    private userService: UserService,
    private accaService: AccaTrackerService,
    private location: Location,
    private appSettingsService: AppSettingsService,
    private analyticsManager: AnalyticsManager
  ) {
    this.titleService.setTitle('Rewards');
  }

  ngOnInit(): void {
    this.authSubscription = this.userService.userData$
    .subscribe((user) => {
      this.currentUser = user;
    });

    if (this.appSettingsService.fetch() && this.appSettingsService.fetch().Features) {
      this.status = this.appSettingsService.fetch().Features.SixthBetFreeStatus;
    }

    this.getOfferStatus();
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  getOfferStatus() {
    if(this.currentUser) {
      this.accaService.AccaTrackerOffersStatusV2(2)
      .pipe(take(1))
      .subscribe((res) => {
        this.currencySymbol = res.Payload.CurrencySymbol;
        this.averageStake = res.Payload.TicketProgressTrackers[0].AverageStake;
        this.freeBetCodes = res.Payload.RecentFreeBetCodes;
        this.stamps = res.Payload.TicketProgressTrackers[0].StampsRedeemable;
      });
    }
  }

  goBack() {
    this.location.back();
  }

  signUp() {    
    this.analyticsManager.rewardsBetWithPlay(RewardsBetWithPlayResult.SignUp);

    window.open('https://promotions.paddypower.com/prs/sports-acq-uk-retail-bau-generic?rfr=5300614', '_blank');
  }

  terms() {    
    this.analyticsManager.rewardsBetWithPlay(RewardsBetWithPlayResult.Terms);

    window.open('https://www.paddypower.com/paddys-rewards-club', '_blank');
  }
}
