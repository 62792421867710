/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoVideoStreamResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-video-stream-response';
import { Steer73PaddypowerCoreDtoVideoStreamRequest } from '../models/steer-73paddypower-core-dto-video-stream-request';
@Injectable({
  providedIn: 'root',
})
class PerformV1Service extends __BaseService {
  static readonly PerformV1VideoStreamPath = '/api/perform/VideoStream';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Contacts Perfrom Group to get the URL for the media stream
   * @param params The `PerformV1Service.PerformV1VideoStreamParams` containing the following parameters:
   *
   * - `request`: The details of the stream to get the URL for
   *
   * - `api-version`:
   *
   * @return OK
   */
  PerformV1VideoStreamResponse(params: PerformV1Service.PerformV1VideoStreamParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoVideoStreamResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/perform/VideoStream`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoVideoStreamResponse>;
      })
    );
  }
  /**
   * Contacts Perfrom Group to get the URL for the media stream
   * @param params The `PerformV1Service.PerformV1VideoStreamParams` containing the following parameters:
   *
   * - `request`: The details of the stream to get the URL for
   *
   * - `api-version`:
   *
   * @return OK
   */
  PerformV1VideoStream(params: PerformV1Service.PerformV1VideoStreamParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoVideoStreamResponse> {
    return this.PerformV1VideoStreamResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoVideoStreamResponse)
    );
  }
}

module PerformV1Service {

  /**
   * Parameters for PerformV1VideoStream
   */
  export interface PerformV1VideoStreamParams {

    /**
     * The details of the stream to get the URL for
     */
    request: Steer73PaddypowerCoreDtoVideoStreamRequest;
    apiVersion: number;
  }
}

export { PerformV1Service }
