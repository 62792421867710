import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AppSettingsService } from './shared/app-settings.service';
import { GoogleAnalyticsService } from './shared/google-analytics.service';
import { OneTrustService } from './shared/onetrust.service';
import { ApplicationInsightsService } from './shared/application-insights.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'onside';
  initAppInsightsListener;
  disableAppInsightsListener;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    public router: Router,
    public renderer: Renderer2,
    public googleAnalyticsService: GoogleAnalyticsService,
    public applicationInsightsService: ApplicationInsightsService,
    public settings: AppSettingsService,
    public oneTrust: OneTrustService,
    private analytics: AngularFireAnalytics
    ) {
      const appSettings = this.settings.fetch();
      this.googleAnalyticsService.loadAnalytics(appSettings.GoogleAnalyticsId);
      
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if(typeof ga === 'function') {
            ga('set', 'page', event.urlAfterRedirects);
            ga('send', 'pageview');
          }
        }
      });

      this.initAppInsightsListener = this.renderer.listen('window', 'initAppInsights', () => {
        this.applicationInsightsService.initAppInisghts();
        this.analytics.setAnalyticsCollectionEnabled(true);
      });
      this.disableAppInsightsListener = this.renderer.listen('window', 'disableCookies', () => {
        this.applicationInsightsService.disableAppInsights();
        this.analytics.setAnalyticsCollectionEnabled(false);
      });

      // Always load this last.
      this.oneTrust.loadOnetrust();
      
    }

  ngOnInit() {
    // If there is no startup data received (maybe an error!)
    // navigate to error route

    // if (!this.appSettings.fetch()) {
    //  this.router.navigate(['error'], { replaceUrl: true });
    // }
  }

  tryAgain() {

  }
}
