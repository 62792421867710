import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class OneTrustService {

  constructor(
    private environmentService: EnvironmentService
  ) { }

  loadOnetrust() {
    const oneTrustScript = document.createElement('script');
    oneTrustScript.setAttribute('defer', 'true');
    oneTrustScript.setAttribute('charset', 'UTF-8');
    oneTrustScript.setAttribute('type', 'text/javascript');
    oneTrustScript.setAttribute('data-domain-script', this.environmentService.getEnvironmentConfiguration().oneTrust.id);
    oneTrustScript.setAttribute('src', 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js');

    document.documentElement.firstChild.appendChild(oneTrustScript);
  }
}
