/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class MonitorService extends __BaseService {
  static readonly MonitorMailJetApiPath = '/api/monitor/mailjet';
  static readonly MonitorTextLocalApiPath = '/api/monitor/textlocal';
  static readonly MonitorBgtPath = '/api/monitor/bgt';
  static readonly MonitorRetaPath = '/api/monitor/reta';
  static readonly MonitorMaxMindPath = '/api/monitor/maxmind';
  static readonly MonitorDatabasePath = '/api/monitor/database';
  static readonly MonitorRedisPath = '/api/monitor/redis';
  static readonly MonitorBlobPath = '/api/monitor/blob';
  static readonly MonitorServiceBusPath = '/api/monitor/serviceBus';
  static readonly MonitorSslExpirationPath = '/api/monitor/ssl/{token}';
  static readonly MonitorEndpointForSslCheckPath = '/api/monitor/health/sslcheck/{token}';
  static readonly MonitorAdminSslExpirationPath = '/api/monitor/admin-ssl';
  static readonly MonitorWebJobBetStreamStatusPath = '/api/monitor/betstream';
  static readonly MonitorWebJobMediaParseStatusPath = '/api/monitor/mediaparse';
  static readonly MonitorWebJobMessagingStatusPath = '/api/monitor/messaging';
  static readonly MonitorWebJobStoreSyncStatusPath = '/api/monitor/storesync';
  static readonly MonitorWebJobTaskRunnerStatusPath = '/api/monitor/taskrunner';
  static readonly MonitorWebJobReportingStatusPath = '/api/monitor/reporting';
  static readonly MonitorWebJobDataPurgeStatusPath = '/api/monitor/datapurge';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Used to verify if the mailjet integration is functional.
   * @return OK
   */
  MonitorMailJetApiResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/mailjet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Used to verify if the mailjet integration is functional.
   * @return OK
   */
  MonitorMailJetApi(): __Observable<{}> {
    return this.MonitorMailJetApiResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Used to verify if the textlocal integration is functional.
   * @return OK
   */
  MonitorTextLocalApiResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/textlocal`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Used to verify if the textlocal integration is functional.
   * @return OK
   */
  MonitorTextLocalApi(): __Observable<{}> {
    return this.MonitorTextLocalApiResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Used to verify if the bgt integration is functional.
   * @return OK
   */
  MonitorBgtResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/bgt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Used to verify if the bgt integration is functional.
   * @return OK
   */
  MonitorBgt(): __Observable<{}> {
    return this.MonitorBgtResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Used to verify if the bgt integration is functional.
   * @return OK
   */
  MonitorRetaResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/reta`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Used to verify if the bgt integration is functional.
   * @return OK
   */
  MonitorReta(): __Observable<{}> {
    return this.MonitorRetaResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Used to verify if the maxmind integration is functional.
   * @return OK
   */
  MonitorMaxMindResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/maxmind`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Used to verify if the maxmind integration is functional.
   * @return OK
   */
  MonitorMaxMind(): __Observable<{}> {
    return this.MonitorMaxMindResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @return OK
   */
  MonitorDatabaseResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/database`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @return OK
   */
  MonitorDatabase(): __Observable<{}> {
    return this.MonitorDatabaseResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @return OK
   */
  MonitorRedisResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/redis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @return OK
   */
  MonitorRedis(): __Observable<{}> {
    return this.MonitorRedisResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @return OK
   */
  MonitorBlobResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/blob`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @return OK
   */
  MonitorBlob(): __Observable<{}> {
    return this.MonitorBlobResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @return OK
   */
  MonitorServiceBusResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/serviceBus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @return OK
   */
  MonitorServiceBus(): __Observable<{}> {
    return this.MonitorServiceBusResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param params The `MonitorService.MonitorSslExpirationParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `days`:
   *
   * @return OK
   */
  MonitorSslExpirationResponse(params: MonitorService.MonitorSslExpirationParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.days != null) __params = __params.set('days', params.days.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/ssl/${encodeURIComponent(String(params.token))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `MonitorService.MonitorSslExpirationParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `days`:
   *
   * @return OK
   */
  MonitorSslExpiration(params: MonitorService.MonitorSslExpirationParams): __Observable<{}> {
    return this.MonitorSslExpirationResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param token undefined
   * @return OK
   */
  MonitorEndpointForSslCheckResponse(token: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/health/sslcheck/${encodeURIComponent(String(token))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param token undefined
   * @return OK
   */
  MonitorEndpointForSslCheck(token: string): __Observable<{}> {
    return this.MonitorEndpointForSslCheckResponse(token).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param days undefined
   * @return OK
   */
  MonitorAdminSslExpirationResponse(days?: number): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (days != null) __params = __params.set('days', days.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/admin-ssl`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param days undefined
   * @return OK
   */
  MonitorAdminSslExpiration(days?: number): __Observable<{}> {
    return this.MonitorAdminSslExpirationResponse(days).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @return OK
   */
  MonitorWebJobBetStreamStatusResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/betstream`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @return OK
   */
  MonitorWebJobBetStreamStatus(): __Observable<{}> {
    return this.MonitorWebJobBetStreamStatusResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @return OK
   */
  MonitorWebJobMediaParseStatusResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/mediaparse`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @return OK
   */
  MonitorWebJobMediaParseStatus(): __Observable<{}> {
    return this.MonitorWebJobMediaParseStatusResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @return OK
   */
  MonitorWebJobMessagingStatusResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/messaging`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @return OK
   */
  MonitorWebJobMessagingStatus(): __Observable<{}> {
    return this.MonitorWebJobMessagingStatusResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @return OK
   */
  MonitorWebJobStoreSyncStatusResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/storesync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @return OK
   */
  MonitorWebJobStoreSyncStatus(): __Observable<{}> {
    return this.MonitorWebJobStoreSyncStatusResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @return OK
   */
  MonitorWebJobTaskRunnerStatusResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/taskrunner`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @return OK
   */
  MonitorWebJobTaskRunnerStatus(): __Observable<{}> {
    return this.MonitorWebJobTaskRunnerStatusResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @return OK
   */
  MonitorWebJobReportingStatusResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/reporting`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @return OK
   */
  MonitorWebJobReportingStatus(): __Observable<{}> {
    return this.MonitorWebJobReportingStatusResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @return OK
   */
  MonitorWebJobDataPurgeStatusResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/monitor/datapurge`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @return OK
   */
  MonitorWebJobDataPurgeStatus(): __Observable<{}> {
    return this.MonitorWebJobDataPurgeStatusResponse().pipe(
      __map(_r => _r.body as {})
    );
  }
}

module MonitorService {

  /**
   * Parameters for MonitorSslExpiration
   */
  export interface MonitorSslExpirationParams {
    token: string;
    days?: number;
  }
}

export { MonitorService }
