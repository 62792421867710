import { NgModule } from '@angular/core';
import { TrackBetComponent } from './track-bet/track-bet.component';
import { AddTicketComponent } from './add-ticket/add-ticket.component';
import { RouterModule, Routes } from '@angular/router';
import { TrackMyBetComponent } from './track-my-bet.component';
import { CashOutRequestComponent } from './cash-out-request/cash-out-request.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { FaqsComponent } from './faqs/faqs.component';
import { GeoBlockingGuard } from '../../shared/geo-blocking-guard';
import { AnnouncementComponent } from './announcement/announcement.component';
import { RewardsComponent } from './rewards/rewards.component';
import { GamesComponent } from './games/games.component';
import { HomeComponent } from './home/home.component';
import { PromoComponent } from './promo/promo.component';
import { InitialLoginGuard } from '../../shared/initial-login-guard';
import { OnboardingComponent } from './onboarding/onboarding.component';

export const routes: Routes = [
  {
    path: '',
    component: TrackMyBetComponent,
    canActivate: [GeoBlockingGuard, InitialLoginGuard],
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'track-my-bet', component: TrackBetComponent},
      { path: 'add-ticket', component: AddTicketComponent },
      { path: 'cash-out', component: CashOutRequestComponent },
      { path: 'terms-and-conditions', component: TermsConditionComponent },
      { path: 'faqs', component: FaqsComponent },
      { path: 'announcement/:id', component: AnnouncementComponent },
      { path: 'promo/:id', component: PromoComponent },
      { path: 'rewards', component: RewardsComponent },
      { path: 'games', component: GamesComponent },
      { path: 'onboarding', component: OnboardingComponent }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class TrackMyBetRoutingModule { }
