/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoCashOutResult } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-cash-out-result';
import { Steer73PaddypowerCoreDtoCashOutRequest } from '../models/steer-73paddypower-core-dto-cash-out-request';
@Injectable({
  providedIn: 'root',
})
class BgtV1Service extends __BaseService {
  static readonly BgtV1CashOutPath = '/api/bgt/CashOut';
  static readonly BgtV1GetBetTicketFromBGTPath = '/api/bgt/GetBetTicketFromBGT/{betTicketId}';
  static readonly BgtV1GetBetTicketPath = '/api/bgt/GetBetTicket/{betTicketId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Initiates a bet ticket cash out
   * @param params The `BgtV1Service.BgtV1CashOutParams` containing the following parameters:
   *
   * - `request`: Details of the bet ticket, user and amount that is to be cashed out
   *
   * - `api-version`:
   *
   * @return OK
   */
  BgtV1CashOutResponse(params: BgtV1Service.BgtV1CashOutParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoCashOutResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/bgt/CashOut`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoCashOutResult>;
      })
    );
  }
  /**
   * Initiates a bet ticket cash out
   * @param params The `BgtV1Service.BgtV1CashOutParams` containing the following parameters:
   *
   * - `request`: Details of the bet ticket, user and amount that is to be cashed out
   *
   * - `api-version`:
   *
   * @return OK
   */
  BgtV1CashOut(params: BgtV1Service.BgtV1CashOutParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoCashOutResult> {
    return this.BgtV1CashOutResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoCashOutResult)
    );
  }

  /**
   * @param params The `BgtV1Service.BgtV1GetBetTicketFromBGTParams` containing the following parameters:
   *
   * - `betTicketId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BgtV1GetBetTicketFromBGTResponse(params: BgtV1Service.BgtV1GetBetTicketFromBGTParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/bgt/GetBetTicketFromBGT/${encodeURIComponent(String(params.betTicketId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `BgtV1Service.BgtV1GetBetTicketFromBGTParams` containing the following parameters:
   *
   * - `betTicketId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BgtV1GetBetTicketFromBGT(params: BgtV1Service.BgtV1GetBetTicketFromBGTParams): __Observable<{}> {
    return this.BgtV1GetBetTicketFromBGTResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param params The `BgtV1Service.BgtV1GetBetTicketParams` containing the following parameters:
   *
   * - `betTicketId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BgtV1GetBetTicketResponse(params: BgtV1Service.BgtV1GetBetTicketParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/bgt/GetBetTicket/${encodeURIComponent(String(params.betTicketId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `BgtV1Service.BgtV1GetBetTicketParams` containing the following parameters:
   *
   * - `betTicketId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BgtV1GetBetTicket(params: BgtV1Service.BgtV1GetBetTicketParams): __Observable<{}> {
    return this.BgtV1GetBetTicketResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }
}

module BgtV1Service {

  /**
   * Parameters for BgtV1CashOut
   */
  export interface BgtV1CashOutParams {

    /**
     * Details of the bet ticket, user and amount that is to be cashed out
     */
    request: Steer73PaddypowerCoreDtoCashOutRequest;
    apiVersion: number;
  }

  /**
   * Parameters for BgtV1GetBetTicketFromBGT
   */
  export interface BgtV1GetBetTicketFromBGTParams {
    betTicketId: string;
    apiVersion: number;
  }

  /**
   * Parameters for BgtV1GetBetTicket
   */
  export interface BgtV1GetBetTicketParams {
    betTicketId: string;
    apiVersion: number;
  }
}

export { BgtV1Service }
