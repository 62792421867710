import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StateHelperService {
  menuShown = false;

  constructor(
    private router: Router
  ) { }

  getValueFromState(value: string) {
    return this.hasState() ?
        this.router.getCurrentNavigation().extras.state[value] : null;
  }

  hasExtras() {
    return this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras
  }

  hasState() {
    return this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state
  }
}
