<app-header>
  <ng-container middle>
    Rewards
  </ng-container>
</app-header>

<div class="rewards">
  <div class="rewards__play">
    <div class="rewards__loggedin" *ngIf="(currentUser) && status === 1">
      <div class="freebets" [ngClass]="{'freebets--no-margin': !freeBetCodes || freeBetCodes.length > 0}">
        <p class="freebets__title">Looking for<br/> 6<sup>th</sup> bet free?</p>
        <p>Don't worry, we've just moved it to Play.<br/>
          Ask and you shall receive your Play Card in shop.<br/>
          You can still view your unused free bets.
        </p>
      </div>

      <div class="rewards__freebets" *ngIf="!freeBetCodes || freeBetCodes.length > 0">
        <p class="rewards__para">Active bet codes</p>
        <ng-container *ngFor="let freeBetCode of freeBetCodes">
          <div class="rewards__batch-container">
            <div class="rewards__batch-value">
              <span>{{ freeBetCode.AmountWithCurrencySymbol }}</span>
              <span>Bet</span>
            </div>
            <div class="rewards__batch-code">{{ freeBetCode.Code }}</div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="rewards__inner">
      <div class="rewards__club">
        <img class="rewards__image" src="assets/images/vectors/rewards/rewards.svg" alt="rewards club" width="350" height="248">
        <h2>Be a part of Paddy's Rewards Club <span>with Play</span></h2>
      </div>
      <div class="rewards__description">
        <h4>Place 5 bets in a week and get up to a £/€10 free online bet</h4>
        <p>Eligible bets are Betting Machine bets placed with Play in shop or online bets. Free bet is added on the following Monday. <a (click)="terms()">Terms and conditions</a> apply.</p>
      </div>

      <p class="rewards__need-account">Need an online account?</p>
      <a (click)="signUp()" class="button button--large">Sign up to play</a>

      <div class="rewards__discover">
        <div class="rewards__discover-top">
          <img class="rewards__image-discover" src="assets/images/rasters/rewards/banner-discover.png" alt="rewards discover" width="301" height="191">
          <p (click)="showPlayReasons = !showPlayReasons" class="rewards__link-more">
            <i class="fas" [ngClass]="!showPlayReasons ? 'fa-plus-circle' : 'fa-minus-circle'"></i>
            <span>{{!showPlayReasons ? "show more" : "show less"}}</span>
          </p>
        </div>
        <ul class="reasons" *ngIf="showPlayReasons">
          <li class="reasons__item reasons__item--1">All Money Back Specials paid as cash (over the counter bets)</li>
          <li class="reasons__item reasons__item--2">Deposit and withdraw your PP funds in any PP shop</li>
          <li class="reasons__item reasons__item--3">Bet with your PP funds at the counter, on betting machines or online</li>
          <li class="reasons__item reasons__item--4">Access shop winnings online instantly</li>
          <li class="reasons__item reasons__item--5">Contactless Payment</li>
          <li class="reasons__item reasons__item--6">Track your counter bets on the PP app</li>
        </ul>
      </div>

      <h4>Play Your Way – Online or In Shop</h4>

      <p>Just ask in shop for your Play Card to get access to offers and benefits that work hard so you can play easily.</p>
      <p>New to Play? You will need to do a quick warm-up first:</p>

      <ul class="steps">
        <li class="steps__item">
          <p class="steps__title">Step 1</p>
          <p>Make sure your Play card is linked up to your PP online account</p>
        </li>
        <li class="steps__item">
          <p class="steps__title">Step 2</p>
          <p>Activate your Card at the counter (bring some ID)</p>
        </li>
        <li class="steps__item">
          <p class="steps__title">Step 3</p>
          <p>You're ready to Play! Place a bet with your new Card</p>
        </li>
      </ul>

      <p>And remember, our friendly shop teams are always ready to help a player out.</p>

      <p class="rewards__need-account">Need an online account?</p>
      <a (click)="signUp()" class="button button--large">Sign up to play</a>
    </div>
  </div>
  <div class="footer__brands">
        <div class="row">
          <div class="col5-s">
            <a href="https://www.authorisation.mga.org.mt/verification.aspx?lang=en&company=4f2ce9bc-6584-440c-8643-9314defffd0e" target="_blank">
              <img class="ir ir--gc" src="assets/images/rasters/logos/mga.png" alt="MGA" width="146" height="29">
            </a>
          </div>
          <div class="col5-s">
            <a href="https://www.gamcare.org.uk/" target="_blank">
              <img class="ir ir--gs" src="assets/images/rasters/logos/gamcare.png" alt="Gamcare" width="126" height="36">
            </a>
          </div>
          <div class="col2-s">
            <a href="https://www.gamblingtherapy.org/" target="_blank">
              <img class="ir ir--eteen" src="assets/images/rasters/logos/gamingtheraphy.png" alt="Gambling Therapy" width="37" height="32">
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col5-s">
            <a href="https://www.gamblingcommission.gov.uk/home.aspx" target="_blank">
              <img class="ir ir--gc" src="assets/images/vectors/logos/gambling-commision.svg" alt="Gambling Commision" width="98" height="26">
            </a>
          </div>
          <div class="col7-s">
            <a href="https://www.begambleaware.org/" target="_blank">
              <img class="ir ir--gc" src="assets/images/rasters/logos/gambleaware.png" alt="Be Gamble Aware" width="156" height="16">
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col5-s">
            <a href="https://www.gamstop.co.uk/" target="_blank">
              <img class="ir ir--gs" src="assets/images/vectors/logos/gamstop.svg" alt="Gam Stop" width="111" height="20">
            </a>
          </div>
          <div class="col5-s">
            <a href="https://bettingandgamingcouncil.com/" target="_blank">
              <img class="ir ir--gs" src="assets/images/rasters/logos/wtfss.png" alt="When the fun stops STOP" width="149" height="26">
            </a>
          </div>
          <div class="col2-s">
            <a href="https://helpcenter.paddypower.com/app/answers/detail/p/6/a_id/70/" target="_blank">
              <img class="ir ir--eteen" src="assets/images/vectors/logos/18.svg" alt="18 +" width="32" height="32">
            </a>
          </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
