<div class="media" [ngSwitch]="type">
  <ng-container *ngSwitchCase="'audio'">
    <audio *ngIf="src" [src]="src" class="player" appMediaControl></audio>
  </ng-container>
  <ng-container *ngSwitchCase="'video'">
    <video appMediaControl
      *ngIf="src"
      class="player player__video"
      preload="metadata"
      playsinline
      [src]="src"
      autoplay
      controls></video>
  </ng-container>
</div>
