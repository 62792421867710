import { Component, OnInit, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService, User } from 'src/app/shared/user.service';
import { Subscription } from 'rxjs';
import { Steer73PaddypowerCoreTransferObjectsFreeBetCodeItem } from 'src/app/api/models';
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AccaTrackerService } from 'src/app/api/services/acca-tracker.service';
import { TermsService } from 'src/app/api/services/terms.service';

@Component({
  selector: 'app-current-rewards',
  templateUrl: './current-rewards.component.html',
  styleUrls: ['./current-rewards.component.scss']
})
export class CurrentRewardsComponent implements OnInit, OnDestroy {
  authSubscription: Subscription;
  termsLoading = true;
  termsShow = false;
  currentUser: User = null;
  currencySymbol: string;
  averageStake: string;
  stamps: number;
  termsText: string;
  freeBetCodes: Array<Steer73PaddypowerCoreTransferObjectsFreeBetCodeItem>;
  status = false;
  listener;

  constructor(
    private titleService: Title,
    private userService: UserService,
    private accaService: AccaTrackerService,
    private termsService: TermsService,
    private location: Location,
    private renderer: Renderer2,
    private eref: ElementRef
  ) {
    this.titleService.setTitle('Rewards');
    this.listener = this.renderer.listen('window', 'resize', () => {
      this.adjustHeights();
    });
  }

  ngOnInit(): void {
    this.authSubscription = this.userService.userData$
    .subscribe((user) => {
      this.currentUser = user;
    });

    this.getTerms();
    this.getOfferStatus();
  }

  ngAfterViewInit(): void {
    this.adjustHeights();
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    this.listener();
  }

  getOfferStatus() {
    if(this.currentUser) {
      this.accaService.AccaTrackerOffersStatusV2(2)
      .pipe(take(1))
      .subscribe((res) => {
        this.currencySymbol = res.Payload.CurrencySymbol;
        this.averageStake = res.Payload.TicketProgressTrackers[0].AverageStake;
        this.freeBetCodes = res.Payload.RecentFreeBetCodes;
        this.stamps = res.Payload.TicketProgressTrackers[0].StampsRedeemable;
      });
    }
  }
  adjustHeights() {
    const rewards = this.eref.nativeElement.querySelector('.rewards');
    const containerTerms = this.eref.nativeElement.querySelector('.terms');
    const containersHeight = this.eref.nativeElement.querySelector('.containerTop').offsetHeight;
    if (
      Math.floor(rewards.scrollTop) >=
      Math.floor(
        this.eref.nativeElement.querySelector('.containerTop').offsetTop +
        containersHeight -
          rewards.offsetHeight
      )
    ) {
      if(!containerTerms.classList.contains('auto') &&
        !containerTerms.classList.contains('open')) {
          this.renderer.addClass(containerTerms, 'auto');
          this.renderer.addClass(this.eref.nativeElement.querySelector('.containerTop'), 'nopadding');
      }
    } else {
      this.renderer.removeClass(containerTerms, 'auto');
      this.renderer.removeClass(this.eref.nativeElement.querySelector('.containerTop'), 'nopadding');
    }
  }

  getTerms() {
    this.termsService.TermsGetLatestSixBetFree(1)
    .pipe(take(1))
    .subscribe((res) => {
      this.termsText = res.Payload;
      this.termsShow = true;
      this.termsLoading = false;
    },
    (err) => {
      this.termsLoading = false;
    });

  }

  goBack() {
    this.location.back();
  }

  open() {
    this.eref.nativeElement.querySelector('.terms').scrollTo( 0, 0 );
    this.status = !this.status;
  }

}
