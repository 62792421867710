import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationInsightsService } from './application-insights.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorsHandlerService extends ErrorHandler {

  constructor(private applicationInsightsService: ApplicationInsightsService) {      
      super();
    }

  handleError(error: Error | HttpErrorResponse): void {
    if (error instanceof HttpErrorResponse) {
      if (!navigator.onLine) {
        // show no connection modal
      }
    }

    this.applicationInsightsService.logException(error);
    console.error('Error: ', error);
  }
}
