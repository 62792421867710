import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { BetTicketsService } from '../api/services';
import {
  Steer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel,
  Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel,
  Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel,
  Steer73PaddypowerCoreServicesBetTicketManagerRetaCashOutModel,
  Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoCashOutResult,
} from '../api/models';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from './user.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppSettingsService } from './app-settings.service';
import { Fixture } from '../models/fixture';
import { AnalyticsManager } from '../shared/analytics-manager.service';
import { BetTicketProvider } from '../enums/betTicketProvider.enum';

@Injectable({
  providedIn: 'root'
})
export class TicketService implements OnInit, OnDestroy {

  constructor(private betTicketService: BetTicketsService,
              private appSettingsService: AppSettingsService,
              private http: HttpClient,
              private analyticsManager: AnalyticsManager) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  loadInitialTickets(ticketStatus: string, user: User): Observable<Steer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel> {
    if (user) {
      switch (ticketStatus) {
        case 'Settled':
          return this.getSettledTickets();
        case 'Open':
        default:
          return this.getOpenTickets();
      }
    } else {
      return this.getOpenTickets().pipe(
        map(res => {
          res.Tickets = res.Tickets.filter((ticket) => {
            switch (ticketStatus) {
              case 'Settled':
              case 'Won':
              case 'Lost':
                return !this.isTicketOpen(ticket);
              case 'Open':
              default:
                return this.isTicketOpen(ticket);
            }
          });
          return res;
        })
      );
    }
  }

  getOpenTickets(): Observable<Steer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel> {
    return this.pipeTicketRequests(
      this.betTicketService.BetTicketsGetOpenBetsFromToken(1),
      catchError(error => this.handleTicketError(error))
    );
  }

  getSettledTickets() {
    return this.pipeTicketRequests(
      this.betTicketService.BetTicketsGetSettledBetsFromToken(1),
      catchError(error => this.handleTicketError(error))
    );
  }

  refreshTickets(): Observable<Steer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel> {
    return this.pipeTicketRequests(
      this.http.post(this.appSettingsService.fetch().Endpoints.RefreshTicketsEndpoint, null),
      catchError(error => this.handleTicketError(error))
    );
  }

  refreshCashout(): Observable<Steer73PaddypowerCoreServicesBetTicketManagerRetaCashOutModel[]> {
    return this.http.get(this.appSettingsService.fetch().Endpoints.CashoutInfoEndpoint).pipe(
      map((res: any) => res.Payload),
      catchError(error => {
        return throwError({
          message: 'Error updating cashout details'
        });
      }));
  }

  addTicket(betticketnumber: string, scanned: boolean): Observable<Steer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel> {
    return this.pipeTicketRequests(this.betTicketService.BetTicketsAddTicket({
      apiVersion:1,
      request: {
        TicketIdWithPin: betticketnumber,
        Scanned: scanned
      }
    }), catchError((error: HttpErrorResponse) => {
      if (error.status === 403) {
        return throwError({
          message: error.error.ErrorResponse.Message
        });
      } else {
        return throwError({
          message: 'Error adding ticket'
        });
      }
    }));
  }

  isTicketOpen(ticket: Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel): boolean {
    switch (ticket.Status) {
      case -1:
      case 0:
      case 3000:
        return true;
      default:
        return false;
    }
  }

  // tslint:disable-next-line:max-line-length
  pipeTicketRequests(ticketObservable: Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel>, errorMethod)
    : Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel> {
    return ticketObservable.pipe(
      map(res => res.Payload),
      errorMethod
    );
  }

  handleTicketError(err) {

    let errorResponse = err['error']['ErrorResponse'];
    let message = '';

    if (errorResponse) {
      message = errorResponse['Message'];
    }

    if(message === null || message === '') {
      message = 'We can\'t track this bet right now. Please try again shortly';
    }

    this.analyticsManager.errorBetsNotLoading(message);

    switch (err.error.StatusCode) {
      case 429:
      case 401:
        return throwError('Access denied');
      default:
        return throwError('Unkown reason');
    }
  }

  hasLiveStream(ticket: Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel) {
    return ticket.Bets.some(bet =>
      bet.Legs.some(leg =>
        (leg.HasAudio || leg.HasVideo)
          && (leg.MatchMedia && leg.MatchMedia.some(media => media.Active)))
    );
  }

  sortLiveTickets(Tickets: Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel[]): Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel[] {
    let newTickets: Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel[] = [];

    newTickets = newTickets.concat(Tickets.filter(ticket => {
        return ticket.Bets[0].Legs.some((leg) => leg.LiveScore && leg.LiveScore.IsLive)
    }).sort((ticket, prevTicket) => {
      if (this.hasLiveStream(ticket)) {
        return 1;
      }

      const leg = ticket.Bets[0].Legs.find((leg) => leg.LiveScore && leg.LiveScore.IsLive);
      const previous = prevTicket.Bets[0].Legs.find((leg) => leg.LiveScore && leg.LiveScore.IsLive);

      return new Date(leg.StartDate).getTime() - new Date(previous.StartDate).getTime();
    }));

    newTickets = newTickets.concat(Tickets.filter(ticket => {
      return newTickets.indexOf(ticket) < 0 && !this.hasLiveStream(ticket) &&
              ticket.Bets[0].Legs.some((leg) => leg.HasAudio || leg.HasVideo)
    }).sort((ticket, prevTicket) => {
      const leg = ticket.Bets[0].Legs.find((leg) => leg.HasAudio || leg.HasVideo);
      const previous = prevTicket.Bets[0].Legs.find((leg) => leg.HasAudio || leg.HasVideo);

      return new Date(leg.StartDate).getTime() - new Date(previous.StartDate).getTime();
    }));

    newTickets = newTickets.concat(Tickets.filter(ticket => {
      return newTickets.indexOf(ticket) < 0
    }).sort((ticket, prevTicket) => {
      const leg = ticket.Bets[0].Legs[0];
      const previous = prevTicket.Bets[0].Legs[0];

      return new Date(leg.StartDate).getTime() - new Date(previous.StartDate).getTime();
    }));

    return newTickets;
  }

  updateExistingTickets(tickets: Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel[], existingTickets: Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel[]) {
    tickets.forEach((element) => {
      const foundIndex = existingTickets.findIndex(x => x.BetTicketNumber === element.BetTicketNumber);
      if (foundIndex >= 0) {
        const oldTicketObj = existingTickets[foundIndex];
        existingTickets[foundIndex] = element;

        if (oldTicketObj.BetTicketProvider === BetTicketProvider.Reta) {
          existingTickets[foundIndex].Cashout = oldTicketObj.Cashout;
        }

        if (existingTickets[foundIndex].Bets) {
          existingTickets[foundIndex].Bets.forEach((bet, betIndex) => {

          if (bet.Legs) {
              bet.Legs.forEach((leg) => {
                const liveScoreLeg = oldTicketObj.Bets[betIndex].Legs.find(oldLeg => oldLeg.LiveScore && oldLeg.StatisticId === leg.StatisticId)

                if (liveScoreLeg) {
                  leg.LiveScore = liveScoreLeg.LiveScore;
                }
              });
            }
          });
        }
      }
    });
  }

  updateCashoutOnExistingTickets(cashoutDetailInfo: Steer73PaddypowerCoreServicesBetTicketManagerRetaCashOutModel[], existingTickets: Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel[]) {
    cashoutDetailInfo.forEach((cashOut) => {
      const foundIndex = existingTickets.findIndex(x => x.BetTicketNumber === cashOut.BetTicketNumber);
      if (foundIndex >= 0) {
        const oldTicketObj = existingTickets[foundIndex];
        existingTickets[foundIndex].Cashout = cashOut.Cashout;
      }
    });
  }

  getFixtures(tickets: Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel[]): Fixture[] {
    const fixtures: Fixture[] = [];

    tickets.forEach((ticket) => {
      ticket.Bets.forEach((bet) => {
        bet.Legs.filter(leg => leg.StatisticId > 0).forEach((leg) => {
          if (!fixtures.find(f => f.FixtureId === leg.StatisticId && leg.SportsId === f.SportsId )) {
            fixtures.push({
                FixtureId: leg.StatisticId,
                SportsId: leg.SportsId
            });
          }
        });
      });
    });

    return fixtures;
  }

  getCollectedMessage(ticket: Steer73PaddypowerCoreServicesBetTicketManagerBetCardInfoModel) {
    return ticket.IsOmniChannel ? 'SETTLED TO ACCOUNT' : (ticket.FullyPaidOut ? 'Collected' : 'Uncollected');
  }

}
