<div id="ticket{{ticket.BetTicketNumber.substring(ticket.BetTicketNumber.length - 7)}}" class="betcard" [ngClass]="{'betcard--won': hasTicketWon(), 'betcard--lost': hasTicketLost() || isTicketCancelled() }">
  <div class="betcard__header" [ngClass]="showCardInfo ? 'is-active' : ''">
    <span (click)="flipCard()" class="icon" [ngClass]="!showCardInfo ? 'icon--info' : 'fa fa-times'"></span>
    <span *ngIf="showCardInfo; else defaultHeader" class="betcard__header-info">Bet info</span>
    <ng-template #defaultHeader>
      <span>{{ticket.Bets[0].BetTypeName}}</span>
      <span class="betcard__collected" *ngIf="showCollectedStatus()">{{ getCollectedMessage() }}</span>
    </ng-template>
    <span class="betcard__stream" *ngIf="!showCardInfo" (click)="toggleMedia()" [ngClass]="showMedia ? 'is-active' : ''">
        <i class="icon" [ngClass]="hasLiveMedia() ? 'icon--streaming' : 'icon--streaming-light'"></i>
        Stream
    </span>
  </div>
  <div class="betcard__bets" *ngIf="!showCardInfo">
    <ng-container *ngFor="let bet of ticket.Bets; trackBy: trackItem">
      <app-bet-event *ngFor="let event of bet.Legs; trackBy: trackItem" [legInfoEvent]="event" [showMedia]="showMedia"></app-bet-event>
    </ng-container>
  </div>
  <div class="betcard__info" *ngIf="showCardInfo">
    <div>
      <div class="betcard__id">
        <p>Bet Id</p>
        <span>{{ ticket.BetTicketNumber }}</span>
      </div>
      <div class="betcard__placed">
        <p>Bet Placed</p>
        <span>{{ ticket.CreationDate | date: 'dd/MM/yyyy' }}</span>
      </div>
    </div>
    <div>
      <div class="betcard__stake">
        <p>TOTAL STAKE</p>
        <span>{{ ticket.TotalStake ? (ticket.TotalStake | currency: ticket.CurrencyISO) : 'N/A' }}</span>
      </div>
      <div class="betcard__return">
        <p>MAX RETURN</p>
        <span>{{ ticket.MaxReturn ? (ticket.MaxReturn | currency: ticket.CurrencyISO) : 'N/A' }}</span>
      </div>
    </div>
  </div>
  <div class="betcard__footer" *ngIf="!showCardInfo">
    <div class="betcard__stakes">
      <span>Stake: <strong class="black-text">{{ ticket.TotalStake ? (ticket.TotalStake | currency: ticket.CurrencyISO) : 'N/A' }}</strong></span>
      <span *ngIf="isTicketOpen()">Return: <strong class="black-text">{{ ticket.MaxReturn ? (ticket.MaxReturn | currency: ticket.CurrencyISO) : 'N/A' }}</strong></span>
      <span class="betcard__status" *ngIf="hasTicketWon()">
        {{ticket.WonStatus}}: <strong>{{ ticket.WonAmount | currency: ticket.CurrencyISO }}</strong>
      </span>
      <span class="betcard__status" *ngIf="hasTicketLost()">
        <strong class="gunpowder-text">Your bet lost!</strong>
      </span>
    </div>
    <app-cash-out class="betcard__cashout" [cashout]="ticket.Cashout" [currency]="ticket.CurrencyISO" *ngIf="!showCardInfo && isTicketOpen()" (confirmCashout)="confirmCashout()"></app-cash-out>
  </div>
</div>
