<app-header>
  <ng-container left>
    <i class="icon icon--sort" *ngIf="tickets && tickets.Tickets.length > 1" (click)="toggleSortFilter()"></i>
    <i class="icon icon--sort icon--inactive" *ngIf="!tickets || tickets.Tickets.length <= 1"></i>
  </ng-container>
  <ng-container middle>
    <ul class="tabs">
      <li class="tabs__item" (click)="changeActiveTab('Open')" [ngClass]="{'is-active': activeTab === 'Open'}">
        <span class="tabs__text">Open</span>
      </li>
      <li class="tabs__item" (click)="changeActiveTab('Settled')" [ngClass]="{'is-active': activeTab === 'Settled'}">
        <span class="tabs__text">Settled</span>
      </li>
    </ul>
  </ng-container>
  <ng-container right>
    <a class="icon icon--add-bet" (click)="addTicket()"></a>
  </ng-container>
</app-header>

<div class="track-bet-tickets" (scroll)="onWindowScroll($event)" [ngClass]="{'track-bet-tickets__empty': !tickets || tickets && tickets.Tickets.length === 0 || error}">
  <div class="sort mw" [ngSwitch]="activeTab" *ngIf="showSortMenu">
    <p class="sort__title">{{activeTab === 'Open' ? 'Order by' : 'Filter by'}}</p>
    <ul class="sort__list" *ngSwitchCase="'Open'">
      <li class="sort__item" (click)="sortOpenTickets(sort.DatePlaced)" [ngClass]="{'is-active': openSortSelection === sort.DatePlaced || openSortSelection === null}">Placed time</li>
      <li class="sort__item" (click)="sortOpenTickets(sort.CashOutValue)" [ngClass]="{'is-active': openSortSelection === sort.CashOutValue}">Cashout value</li>
      <li class="sort__item" (click)="sortOpenTickets(sort.LiveScore)" [ngClass]="{'is-active': openSortSelection === sort.LiveScore}">Live events</li>
    </ul>
    <ul class="sort__list" *ngSwitchCase="'Settled'">
      <li class="sort__item" (click)="filterSettledTickets(filter.All)" [ngClass]="{'is-active': settledFilterSelection === filter.All}">All</li>
      <li class="sort__item" (click)="filterSettledTickets(filter.Won)" [ngClass]="{'is-active': settledFilterSelection === filter.Won}">Won</li>
      <li class="sort__item" (click)="filterSettledTickets(filter.Lost)" [ngClass]="{'is-active': settledFilterSelection === filter.Lost}">Lost</li>
    </ul>
  </div>

  <div class="login-notification" *ngIf="showLoginNotification()">
    <a class="login-notification__overlay" [routerLink]="['/login']">

    </a>
    <div class="login-notification__header">
      <h4 class="login-notification__title">You're logged out</h4>
      <a class="login-notification__icon">
        <i class="fas fa-long-arrow-alt-right"></i>
      </a>
    </div>
    <p class="login-notification__para">Log in to save your bets and learn more about our retail games and rewards.</p>
  </div>
  <ng-container *ngIf="!ticketsLoading && !error; else loading">

    <ng-container *ngIf="tickets">
      <app-bet-ticket #ticket *ngFor="let ticket of getTickets(); trackBy: trackTicket" [ticket]="ticket" class="mw" (toggleMediaEvent)="onToggleMediaEvent($event)"></app-bet-ticket>
    </ng-container>

    <div class="no-tickets" *ngIf="!tickets || getTickets().length === 0">
      <div [ngSwitch]="activeTab">
        <div class="no-tickets__open" *ngSwitchCase="'Open'">
          <img class="no-tickets__img" src="assets/images/icons/empty-open_bets.svg" alt="" width="308" height="194" [ngClass]="{'no-tickets__img--hide':showLoginNotification()}" >
          <h3 class="no-tickets__title">Nothing to see here</h3>
          <p class="no-tickets__para"><strong>You don't have any tracked open bets.</strong></p>
          <p class="no-tickets__para"><strong>Find your nearest terminal and get betting!</strong></p>
          <button class="button button--large button--icon button--center" [routerLink]="['/add-ticket']"><i class="icon icon--add-bet-light"></i>Add a bet</button>
        </div>
        <div class="no-tickets__settled" *ngSwitchCase="'Settled'">
          <img class="no-tickets__img" src="assets/images/icons/empty-won_bets.svg" alt="" width="308" height="194">
          <h3 class="no-tickets__title">Nothing to see here</h3>
          <p class="no-tickets__para"><strong>You don't have any settled bets over the last 30 days.</strong></p>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="no-tickets" *ngIf="!ticketsLoading && error">
    <div>
      <div class="no-tickets__error" >
        <img class="no-tickets__img" src="assets/images/icons/empty-cant_load.svg" alt="" width="308" height="194">
        <h3 class="no-tickets__title">Nothing to see here</h3>
        <p class="no-tickets__para"><strong>We can't load your bets right now. Please try again shortly.</strong></p>
        <button class="button button--large button--icon button--center" (click)="loadTickets()"><i class="fas fa-redo"></i>Try again</button>
      </div>
    </div>
  </div>

  <ng-template #loading>
    <div class="loader mw" *ngIf="!error">
      <div class="loader__img"></div>
      <div class="spinner spinner--center"></div>
    </div>
  </ng-template>
</div>

<ngx-smart-modal #statisticsView identifier="statisticsView" customClass="modal modal--fullscreen modal--iframe">
  <iframe *ngIf="statisticsView.hasData()" frameborder="0" [src]="statisticsView.getData().statisticsUrl | safe"></iframe>
</ngx-smart-modal>

<ngx-smart-modal #videoView identifier="videoView" customClass="modal modal--fullscreen modal--center modal--no-padding">
  <app-media *ngIf="videoView.hasData()" [mediaService]="videoService" [src]="videoView.getData().src" type="video"></app-media>
</ngx-smart-modal>
<app-footer></app-footer>
