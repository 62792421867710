<app-header>
  <ng-container left>
    <a class="fas fa-times" [routerLink]="['/track-my-bet']"></a>
  </ng-container>
  <ng-container middle>
    FAQ
  </ng-container>
</app-header>

<div class="faqs">
  <div class="faqs__container">
    <div [innerHTML]="faqText" class="faqs__success" *ngIf="show && !loading"></div>
    <div class="faqs__error" *ngIf="!show && !loading">There is no data to show</div>
  </div>
</div>
<app-footer></app-footer>
