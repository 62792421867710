import { Component, OnInit, Input, ViewChild, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import {
  Steer73PaddypowerCoreServicesBetTicketManagerLegInfoModel,
  Steer73PaddypowerCoreModelsLiveScoreModel,
  Steer73PaddypowerCoreServicesBetTicketManagerWagerInfoModel
} from 'src/app/api/models';
import { Status } from 'src/app/enums/status.enum';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AppSettingsService } from 'src/app/shared/app-settings.service';
import { MediaService } from 'src/app/api/services';
import { MediaComponent } from '../media/media.component';
import { Subscription } from 'rxjs';
import { SignalRService } from 'src/app/shared/signalr.service';
import { AudioService } from '../media/audio.service';
import { VideoService } from '../media/video.service';
import { formatDate } from '@angular/common';
import { LegType } from 'src/app/enums/legType.enum';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { AnalyticsManager } from '../../../shared/analytics-manager.service';
import { StreamingInteractionResult } from 'src/app/enums/streamingInteractionResult.enum';

@Component({
  selector: 'app-bet-event',
  templateUrl: './bet-event.component.html',
  styleUrls: ['./bet-event.component.scss']
})
export class BetEventComponent implements OnInit, OnDestroy {
  @Input() legInfoEvent: Steer73PaddypowerCoreServicesBetTicketManagerLegInfoModel;
  @Input() showMedia = false;
  @ViewChild('audioChild') audio: MediaComponent;
  @ViewChild('videoChild') video: MediaComponent;

  fixtureSubscription: Subscription;
  authSubscription: Subscription;
  status = Status;
  showEventInfo = false;
  showWagerDetail = true;
  audioSrc: string;
  eventScore: string;

  constructor(private ngxSmartModalService: NgxSmartModalService,
              private appSettings: AppSettingsService,
              private mediaService: MediaService,
              private audioService: AudioService,
              private videoService: VideoService,
              private signalR: SignalRService,
              private http: HttpClient,
              private analyticsManager: AnalyticsManager,
              @Inject(LOCALE_ID) private locale: string
            ) { }

  ngOnInit() {
    this.eventScore = this.legInfoEvent.LiveScore?.Result?.Score;
    this.showWagerDetail = (this.legInfoEvent.LegType !== LegType.Forecast && this.legInfoEvent.LegType !== LegType.Tricast);

    this.fixtureSubscription = this.signalR.fixtures
      .subscribe((liveFixture) => {
        if (this.legInfoEvent.StatisticId === liveFixture.StatisticId) {
          this.updateFixture(liveFixture);
        }
      });
  }

  ngOnDestroy() {
    if (this.fixtureSubscription) {
      this.fixtureSubscription.unsubscribe();
    }
  }

  updateFixture(liveFixture: Steer73PaddypowerCoreModelsLiveScoreModel) {
    this.legInfoEvent.LiveScore = liveFixture;
    this.eventScore = liveFixture.Result.Score;
  }

  eventWon(wager: Steer73PaddypowerCoreServicesBetTicketManagerWagerInfoModel) {
    return wager.Status === Status.Won
        || wager.Status === Status.CashedOut;
  }

  eventLost(wager: Steer73PaddypowerCoreServicesBetTicketManagerWagerInfoModel) {
    return wager.Status === Status.Lost;
  }

  eventOpen(wager: Steer73PaddypowerCoreServicesBetTicketManagerWagerInfoModel) {
    return wager.Status === Status.Open
        || wager.Status === Status.Unknown;
  }

  eventVoid(wager: Steer73PaddypowerCoreServicesBetTicketManagerWagerInfoModel) {
    return wager.Status === Status.Cancelled;
  }

  eventName(wager: Steer73PaddypowerCoreServicesBetTicketManagerWagerInfoModel) {
    let eventName = `(${wager.MarketName ? wager.MarketName : 'Market unkown'}) ${this.legInfoEvent.EventName }`;

    if (this.legInfoEvent.SportsName === "Horse-Racing" || this.legInfoEvent.SportsName === "Dog-Racing") {
      eventName += ` ${formatDate(this.legInfoEvent.StartDate, 'hh:mm', this.locale)}`
    }

    return eventName;
  }

  showInfo() {
    this.showEventInfo = !this.showEventInfo;
  }

  openStats() {
    this.analyticsManager.streamingInteraction(StreamingInteractionResult.Tracker);

    if (this.isLive()) {
      const statisticsUrl = `${this.appSettings.fetch().IFrames.BetradarStatsUrl}/${this.legInfoEvent.StatisticId}`;
      this.ngxSmartModalService.setModalData({
        statisticsUrl
      }, 'statisticsView', true);

      this.ngxSmartModalService.open('statisticsView');
    }
  }

  mediaLive(hasMedia: boolean) {
    return hasMedia && this.legInfoEvent.MatchMedia && this.legInfoEvent.MatchMedia[0].Active;
  }

  isLiveScoreLive() {
    return this.legInfoEvent.LiveScore?.IsLive;
  }

  playAudio() {
    if (this.hasActiveStream(1)) {
      this.analyticsManager.streamingInteraction(StreamingInteractionResult.Audio);

      if (!this.audio.src) {
        if (this.mediaLive(this.legInfoEvent.HasAudio)) {
          const media = this.findMatchedMediumByType(1);
          this.audio.src = media.Value;
          setTimeout(() => {
            this.audio.play();
          }, 0);
        }
      } else {
        this.audio.toggle();
      }
    }
  }

  loadVideoModal() {
    if (this.hasActiveStream(2)) {

      this.analyticsManager.streamingInteraction(StreamingInteractionResult.Video);

      const media = this.findMatchedMediumByType(2);
      this.mediaService.MediaGetMediaStreamUrl({
        apiVersion: 1,
        eventData: media.Value,
        mediaType: 2,
        provider: media.Provider
      })
      .pipe(take(1))
      .subscribe(async response => {
        const src = await this.loadVideoSrc(response.Payload);
        if (src) {
          this.ngxSmartModalService.setModalData({
            src
          }, 'videoView', true);

          this.ngxSmartModalService.open('videoView');
        }
      });
    }
  }

  async loadVideoSrc(streamingResponse) {
    return streamingResponse.IsMediaUrl ?
      streamingResponse.Url
      : await this.getExternalStream(streamingResponse.Url);
  }

  async getExternalStream(url: string) {
    const response:any = await this.http.get(url).toPromise();
    return typeof response.hlsUrl !== 'undefined' ? response.hlsUrl : '';
  }

  findMatchedMediumByType(type) {
    return this.legInfoEvent.MatchMedia
      .find(mm => typeof mm.Provider === 'string' && mm.Provider.length > 0 && mm.MediaType === type);
  }

  hasMedia() {
    return this.legInfoEvent.IsTrackerAvailable || this.legInfoEvent.HasAudio || this.legInfoEvent.HasVideo;
  }

  hasAudio() {
    return this.legInfoEvent.HasAudio;
  }

  hasVideo() {
    return this.legInfoEvent.HasVideo;
  }

  hasActiveStream(mediaType) {
    if (this.legInfoEvent.MatchMedia) {
      if (this.legInfoEvent.MatchMedia.some(media => media.Active && media.MediaType === mediaType)) {
        return true;
      }
    }
    return false;
  }

  isLive() {
    const now = new Date();
    return now > new Date(this.legInfoEvent.StartDate);
  }
}
