import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { UserService, User } from 'src/app/shared/user.service';
import { AnnoucementService, HomeService, PromoV2Service } from '../../../api/services';
import { Steer73PaddypowerCoreDtoAnnoucementViewModel, Steer73PaddypowerCoreDtoPromoV2ViewModel } from 'src/app/api/models';
import { take } from 'rxjs/operators';

declare let OneTrust: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  authSubscription: Subscription;
  currentUser: User = null;
  annoucements: Steer73PaddypowerCoreDtoAnnoucementViewModel[];
  promos: Steer73PaddypowerCoreDtoPromoV2ViewModel[];
  loaded: boolean = false;

  constructor(
    private titleService: Title,
    private userService: UserService,
    private homeService: HomeService
  ) {
    this.titleService.setTitle('Home');
  }

  get currentUserObs() {
    return this.userService.userData$;
  }

  ngOnInit(): void {
    this.authSubscription = this.currentUserObs.subscribe((user) => {
      this.currentUser = user;
    });

    this.getHomePayload();
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  getHomePayload() {
    if(!this.currentUser) {
      this.homeService.HomeGet(1).pipe(take(1)).subscribe((res) => {
        this.annoucements = res.Payload.Annoucements;
        this.promos = res.Payload.Promos;

        this.loaded = true;
      });
    }
  }

  showLoginNotification() {
    return !this.currentUser;
  }

  manageCookie() {
    OneTrust.ToggleInfoDisplay();
  }
}
