import { Injectable } from '@angular/core';
import { IMediaService } from './IMedia.service';

@Injectable({
  providedIn: 'root'
})
export class VideoService implements IMediaService {

  constructor() { }

  play(element: HTMLVideoElement) {
    element.play();
  }

  stop(element: HTMLVideoElement) {
    element.pause();
  }

  toggle(element: HTMLVideoElement) {
    if (element.paused) {
      this.play(element);
    } else {
      this.stop(element);
    }
  }
}
