<div class="games">
  <div class="games__container">
    <img class="games__bg" src="assets/images/rasters/games/game-bg.png" alt="Games Background" *ngIf="!loading && !imageUri" />
    <img class="games__bg" [src]="imageUri" alt="Games Background" *ngIf="!loading && imageUri"  />
    <div class="games__overlay">
      <h2>Download our app to play</h2>
      <div>
        <a href="https://apps.apple.com/gb/app/paddy-power-onside-shop-app/id1115230050" target="_blank"><img src="assets/images/vectors/games/appstore.svg" alt="Download from apple store" width="202" height="91"></a>
        <a href="https://play.google.com/store/apps/details?id=com.paddypower.retailapp&hl=en&gl=IE" target="_blank"><img src="assets/images/vectors/games/gplay.svg" alt="Download from play store" width="202" height="91"></a>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
