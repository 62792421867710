import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  loadAnalytics(trackingID) {
    if (trackingID) {
      const gaScript1 = document.createElement('script');
      gaScript1.innerText = `window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;ga(\'create\', \'${ trackingID }\', \'auto\');`;
      gaScript1.setAttribute('type', 'text/plain');
      gaScript1.setAttribute('class', 'optanon-category-C0002');

      const gaScript = document.createElement('script');
      gaScript.setAttribute('async', 'true');
      gaScript.setAttribute('type', 'text/plain');
      gaScript.setAttribute('class', 'optanon-category-C0002');
      gaScript.setAttribute('src', 'https://www.google-analytics.com/analytics.js');

      document.documentElement.firstChild.appendChild(gaScript1);
      document.documentElement.firstChild.appendChild(gaScript);
    }
  }
}
