<div class="bet-event">
  <ul class="bet-event__interactions" *ngIf="showMedia" [ngClass]="{'bet-event__interactions--no-stream': !hasMedia()}">
    <li *ngIf="legInfoEvent.IsTrackerAvailable" class="bet-event__stats" (click)="openStats()" [ngClass]="{'is-active': isLive()}">
      <i class="far fa-chart-bar"></i>
      <p>Tracker</p>
    </li>
    <li *ngIf="hasAudio()" class="bet-event__audio" (click)="playAudio()" [ngClass]="{'is-active': hasActiveStream(1)}">
      <i class="fas fa-microphone-alt"></i>
      <p>Audio</p>
      <app-media #audioChild [mediaService]="audioService" type="audio"></app-media>
    </li>
    <li *ngIf="hasVideo()" class="bet-event__video" (click)="loadVideoModal()" [ngClass]="{'is-active': hasActiveStream(2)}">
      <i class="fas fa-play-circle"></i>
      <p>Video</p>
    </li>
    <li *ngIf="!hasMedia()" class="bet-event__no-stream">
      <p>no <br/>streams</p>
    </li>
  </ul>
  <div class="bet-event__container" *ngFor="let wager of legInfoEvent.Wagers; first as isFirst; last as isLast" [ngClass]="{'bet-event__container--no-pad-top': !isFirst && !showWagerDetail}">
    <div class="bet-event__icon">
      <i class="icon" [ngClass]="{
        'icon--inplay': eventOpen(wager),
        'icon--won': eventWon(wager),
        'icon--lost': eventLost(wager),
        'icon--void': eventVoid(wager)
      }" *ngIf="showWagerDetail || isFirst"></i>
    </div>
    <div class="bet-event__info">
      <div class="bet-event__wager-name">
        <strong>{{ wager.BetName ? wager.BetName : 'Selection unkown' }}</strong>
        <span *ngIf="wager.IsEachWay">(E/W)</span>
      </div>
      <div class="bet-event__detail" *ngIf="showWagerDetail || isLast">
        <div class="bet-event__name">
          {{eventName(wager)}}
          <span class="bet-event__score" [ngClass]="{'bet-event__score--highlight': isLiveScoreLive()}">{{ eventScore }}</span>
        </div>
        <div class="bet-event__other" *ngIf="isLast">
          <span class="bet-event__start">{{ legInfoEvent.StartDate | date: 'HH:mm dd/MM/yy' }}</span>
          <span *ngIf="legInfoEvent.CompetitionName">&nbsp;&bull;&nbsp;{{ legInfoEvent.CompetitionName }}</span>
        </div>
      </div>
    </div>
    <div class="bet-event__market" *ngIf="showWagerDetail || isFirst">
      <div class="bet-event__odds"><strong>{{ wager.OddsFraction ? wager.OddsFraction : '' }}</strong></div>
    </div>
</div>
