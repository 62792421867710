import { Component, OnInit } from '@angular/core';
import { BgtV1Service } from 'src/app/api/services';
import { Router } from '@angular/router';
import { CashoutErrorCode } from 'src/app/enums/cashoutErrorCode.enum';
import { CashoutStatus } from 'src/app/enums/cashoutStatus.enum';
import { Title } from '@angular/platform-browser';
import { StateHelperService } from 'src/app/shared/state-helper-service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-cash-out-request',
  templateUrl: './cash-out-request.component.html',
  styleUrls: ['./cash-out-request.component.scss']
})
export class CashOutRequestComponent implements OnInit {
  private Error1 = 'Cashout is not currently available. We\'re working on it. Please try again soon.';
  private Error2 = 'One of your bets has a market suspended right now. Probably VAR again. Please try again shortly.';
  private Error3 = 'This bet does not qualify for cashout.';

  cashoutParams: object;
  requesting = true;
  errorMessage: string;
  amount: number;
  cashoutStatus: CashoutStatus;

  CashoutStatusEnum = CashoutStatus;

  constructor(
    private titleService: Title,
    private bgtV1Service: BgtV1Service,
    private router: Router,
    private stateHelperService: StateHelperService) {

      this.titleService.setTitle('Cashout');

      if (stateHelperService.hasExtras()) {
        this.cashoutParams = router.getCurrentNavigation().extras.state;
      }
  }

  ngOnInit() {
    if (this.cashoutParams) {
      this.cashout();
    } else {
      this.router.navigateByUrl('/track-my-bet');
    }
  }

  cashout() {
    this.requesting = true;
    this.amount = null;

    this.bgtV1Service.BgtV1CashOut({
      request: this.cashoutParams,
      apiVersion: 1
    })
    .pipe(take(1))
    .subscribe((response) => {
      if (response.Payload.Success) {
        this.cashoutStatus = CashoutStatus.Success;
        this.amount = response.Payload.WonAmount;
      } else {
        this.cashoutStatus = this.mapStatus(response.Payload.Reason);
        this.amount = response.Payload.NewAmount / 100;
        this.errorMessage = this.mapError(response.Payload.Reason);
      }

      this.requesting = false;
    });
  }

  tryAgain() {
    this.cashoutParams['CashoutAmountAsPennies'] = this.amount * 100;
    this.cashout()
  }

  mapStatus(errorCode) : CashoutStatus {
    switch (errorCode) {
      case CashoutErrorCode.EmsErrorOddsChangeDown:
      case CashoutErrorCode.EmsErrorOddsChangeUp:
      case CashoutErrorCode.BsCashOutAmountOutOfRange:
        return CashoutStatus.Partial;
      default:
        return CashoutStatus.Fail
    }
  }

  mapError(errorCode) {
    switch (errorCode) {
      case CashoutErrorCode.SbsCashoutTemporaryNotPossible:
        return this.Error2;
      case CashoutErrorCode.SbsCashoutNotPossible:
      case CashoutErrorCode.InvalidTicketPinWithUpdateIdException:
      case CashoutErrorCode.BsCashOutBetTicketNotOpen:
      case CashoutErrorCode.EmsErrorClosed:
        return this.Error3;
      case CashoutErrorCode.GenError:
      default:
        return this.Error1;
    }
  }
}
