/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Steer73PaddypowerCoreDtoBaseResponseSystemString } from '../models/steer-73paddypower-core-dto-base-response-system-string';
@Injectable({
  providedIn: 'root',
})
class TermsService extends __BaseService {
  static readonly TermsGetLatestPath = '/api/terms';
  static readonly TermsGetLatestSixBetFreePath = '/api/terms/SixthBetFree';
  static readonly TermsGetLatestSDKPath = '/api/terms/SDK';
  static readonly TermsGetLatestLMSPath = '/api/terms/LMS';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the system URLs, endpoints and settings
   * @param api-version undefined
   * @return OK
   */
  TermsGetLatestResponse(apiVersion: number): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __headers = __headers.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/terms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemString>;
      })
    );
  }
  /**
   * Gets the system URLs, endpoints and settings
   * @param api-version undefined
   * @return OK
   */
  TermsGetLatest(apiVersion: number): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemString> {
    return this.TermsGetLatestResponse(apiVersion).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemString)
    );
  }

  /**
   * Gets the system URLs, endpoints and settings
   * @param api-version undefined
   * @return OK
   */
  TermsGetLatestSixBetFreeResponse(apiVersion: number): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __headers = __headers.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/terms/SixthBetFree`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemString>;
      })
    );
  }
  /**
   * Gets the system URLs, endpoints and settings
   * @param api-version undefined
   * @return OK
   */
  TermsGetLatestSixBetFree(apiVersion: number): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemString> {
    return this.TermsGetLatestSixBetFreeResponse(apiVersion).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemString)
    );
  }

  /**
   * Gets the system URLs, endpoints and settings
   * @param api-version undefined
   * @return OK
   */
  TermsGetLatestSDKResponse(apiVersion: number): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __headers = __headers.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/terms/SDK`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemString>;
      })
    );
  }
  /**
   * Gets the system URLs, endpoints and settings
   * @param api-version undefined
   * @return OK
   */
  TermsGetLatestSDK(apiVersion: number): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemString> {
    return this.TermsGetLatestSDKResponse(apiVersion).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemString)
    );
  }

  /**
   * Gets the system URLs, endpoints and settings
   * @param api-version undefined
   * @return OK
   */
  TermsGetLatestLMSResponse(apiVersion: number): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __headers = __headers.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/terms/LMS`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemString>;
      })
    );
  }
  /**
   * Gets the system URLs, endpoints and settings
   * @param api-version undefined
   * @return OK
   */
  TermsGetLatestLMS(apiVersion: number): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemString> {
    return this.TermsGetLatestLMSResponse(apiVersion).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemString)
    );
  }
}

module TermsService {
}

export { TermsService }
