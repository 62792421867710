/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Steer73PaddypowerCoreDtoBaseResponseSystemBoolean } from '../models/steer-73paddypower-core-dto-base-response-system-boolean';
import { Steer73PaddypowerCoreDtoBaseResponseSystemString } from '../models/steer-73paddypower-core-dto-base-response-system-string';
import { Steer73PaddypowerAccaTrackerControllersApiAuthControllerCreateFingerPrintTokenRequest } from '../models/steer-73paddypower-acca-tracker-controllers-api-auth-controller-create-finger-print-token-request';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoLoadFromLinkResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-load-from-link-response';
import { Steer73PaddypowerAccaTrackerControllersApiAuthControllerVerifyFingerPrintTokenRequest } from '../models/steer-73paddypower-acca-tracker-controllers-api-auth-controller-verify-finger-print-token-request';
@Injectable({
  providedIn: 'root',
})
class AuthService extends __BaseService {
  static readonly AuthLogoutPath = '/api/Auth/Logout';
  static readonly AuthCreateFingerPrintTokenPath = '/api/Auth/Biometric/token/create';
  static readonly AuthVerifyFingerPrintTokenPath = '/api/Auth/Biometric/token/verify';
  static readonly AuthDeleteFingerPrintTokenPath = '/api/Auth/Biometric/token/{deviceId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param api-version undefined
   * @return OK
   */
  AuthLogoutResponse(apiVersion: number): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __headers = __headers.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Auth/Logout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean>;
      })
    );
  }
  /**
   * @param api-version undefined
   * @return OK
   */
  AuthLogout(apiVersion: number): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemBoolean> {
    return this.AuthLogoutResponse(apiVersion).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemBoolean)
    );
  }

  /**
   * Creates a new fingerprint token and saves it to the database.
   * @param params The `AuthService.AuthCreateFingerPrintTokenParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  AuthCreateFingerPrintTokenResponse(params: AuthService.AuthCreateFingerPrintTokenParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Auth/Biometric/token/create`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemString>;
      })
    );
  }
  /**
   * Creates a new fingerprint token and saves it to the database.
   * @param params The `AuthService.AuthCreateFingerPrintTokenParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  AuthCreateFingerPrintToken(params: AuthService.AuthCreateFingerPrintTokenParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemString> {
    return this.AuthCreateFingerPrintTokenResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemString)
    );
  }

  /**
   * Verifies the fingerprint token is valid and returns a refreshtoken, logging the user in.
   * @param params The `AuthService.AuthVerifyFingerPrintTokenParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  AuthVerifyFingerPrintTokenResponse(params: AuthService.AuthVerifyFingerPrintTokenParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoLoadFromLinkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Auth/Biometric/token/verify`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoLoadFromLinkResponse>;
      })
    );
  }
  /**
   * Verifies the fingerprint token is valid and returns a refreshtoken, logging the user in.
   * @param params The `AuthService.AuthVerifyFingerPrintTokenParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  AuthVerifyFingerPrintToken(params: AuthService.AuthVerifyFingerPrintTokenParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoLoadFromLinkResponse> {
    return this.AuthVerifyFingerPrintTokenResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoLoadFromLinkResponse)
    );
  }

  /**
   * Deletes a users fingerprint token from the system.
   * @param params The `AuthService.AuthDeleteFingerPrintTokenParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  AuthDeleteFingerPrintTokenResponse(params: AuthService.AuthDeleteFingerPrintTokenParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Auth/Biometric/token/${encodeURIComponent(String(params.deviceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Deletes a users fingerprint token from the system.
   * @param params The `AuthService.AuthDeleteFingerPrintTokenParams` containing the following parameters:
   *
   * - `deviceId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  AuthDeleteFingerPrintToken(params: AuthService.AuthDeleteFingerPrintTokenParams): __Observable<{}> {
    return this.AuthDeleteFingerPrintTokenResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }
}

module AuthService {

  /**
   * Parameters for AuthCreateFingerPrintToken
   */
  export interface AuthCreateFingerPrintTokenParams {
    request: Steer73PaddypowerAccaTrackerControllersApiAuthControllerCreateFingerPrintTokenRequest;
    apiVersion: number;
  }

  /**
   * Parameters for AuthVerifyFingerPrintToken
   */
  export interface AuthVerifyFingerPrintTokenParams {
    request: Steer73PaddypowerAccaTrackerControllersApiAuthControllerVerifyFingerPrintTokenRequest;
    apiVersion: number;
  }

  /**
   * Parameters for AuthDeleteFingerPrintToken
   */
  export interface AuthDeleteFingerPrintTokenParams {
    deviceId: string;
    apiVersion: number;
  }
}

export { AuthService }
