/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoSendMobileValidationTokenResult } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-send-mobile-validation-token-result';
import { Steer73PaddypowerCoreDtoMobileNumberRequest } from '../models/steer-73paddypower-core-dto-mobile-number-request';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoMobileValidationResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-mobile-validation-response';
import { Steer73PaddypowerCoreDtoMobileValidationRequest } from '../models/steer-73paddypower-core-dto-mobile-validation-request';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdatePasswordResult } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-update-password-result';
import { Steer73PaddypowerCoreDtoMobileSetPasswordRequest } from '../models/steer-73paddypower-core-dto-mobile-set-password-request';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoLoadFromLinkResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-load-from-link-response';
import { Steer73PaddypowerCoreDtoMobileLoginRequest } from '../models/steer-73paddypower-core-dto-mobile-login-request';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdateProfileResult } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-update-profile-result';
import { Steer73PaddypowerCoreDtoUpdateUserProfileRequest } from '../models/steer-73paddypower-core-dto-update-user-profile-request';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdateProfileBuilderResult } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-update-profile-builder-result';
import { Steer73PaddypowerCoreTransferObjectsUpdateUserProfileBuilderRequest } from '../models/steer-73paddypower-core-transfer-objects-update-user-profile-builder-request';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGetUserProfileSettingsResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-get-user-profile-settings-response';
import { Steer73PaddypowerCoreDtoGetUserProfileSettingsRequest } from '../models/steer-73paddypower-core-dto-get-user-profile-settings-request';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoOffersResponseV2 } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-offers-response-v2';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCheckForNewVersionResponse } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-transfer-objects-check-for-new-version-response';
import { Steer73PaddypowerCoreTransferObjectsCheckForNewVersionRequest } from '../models/steer-73paddypower-core-transfer-objects-check-for-new-version-request';
@Injectable({
  providedIn: 'root',
})
class AccaTrackerService extends __BaseService {
  static readonly AccaTrackerSendMobileValidationTextMessageGetPath = '/api/AccaTracker/SendMobileValidationTextMessage';
  static readonly AccaTrackerSendMobileValidationTextMessagePostPath = '/api/AccaTracker/SendMobileValidationTextMessage';
  static readonly AccaTrackerValidateMobileValidationToken2Path = '/api/AccaTracker/ValidateMobileValidationToken';
  static readonly AccaTrackerSetPasswordByRefreshTokenPath = '/api/AccaTracker/SetPasswordByRefreshToken';
  static readonly AccaTrackerLoginByMobileNumber2Path = '/api/AccaTracker/LoginByMobileNumber';
  static readonly AccaTrackerUpdateUserProfileByMobileNumberPath = '/api/AccaTracker/UpdateUserProfileByMobileNumber';
  static readonly AccaTrackerUpdateUserProfileBuilderByMobileNumber2Path = '/api/AccaTracker/UpdateUserProfileBuilderByMobileNumber';
  static readonly AccaTrackerGetUserProfileByMobileNumberPath = '/api/AccaTracker/GetUserProfileByMobileNumber';
  static readonly AccaTrackerOffersStatusV2Path = '/api/AccaTracker/OffersStatus';
  static readonly AccaTrackerCheckForNewVersion3Path = '/api/AccaTracker/CheckForNewVersion';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Send a text message for the user to verify their phone number.
   *
   * HTTPGet version is needed for legacy versions.
   * @param params The `AccaTrackerService.AccaTrackerSendMobileValidationTextMessageGetParams` containing the following parameters:
   *
   * - `mobileNumber`: The user's mobile number
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerSendMobileValidationTextMessageGetResponse(params: AccaTrackerService.AccaTrackerSendMobileValidationTextMessageGetParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoSendMobileValidationTokenResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.mobileNumber != null) __params = __params.set('mobileNumber', params.mobileNumber.toString());
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AccaTracker/SendMobileValidationTextMessage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoSendMobileValidationTokenResult>;
      })
    );
  }
  /**
   * Send a text message for the user to verify their phone number.
   *
   * HTTPGet version is needed for legacy versions.
   * @param params The `AccaTrackerService.AccaTrackerSendMobileValidationTextMessageGetParams` containing the following parameters:
   *
   * - `mobileNumber`: The user's mobile number
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerSendMobileValidationTextMessageGet(params: AccaTrackerService.AccaTrackerSendMobileValidationTextMessageGetParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoSendMobileValidationTokenResult> {
    return this.AccaTrackerSendMobileValidationTextMessageGetResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoSendMobileValidationTokenResult)
    );
  }

  /**
   * Send a text message for the user to verify their phone number.
   * @param params The `AccaTrackerService.AccaTrackerSendMobileValidationTextMessagePostParams` containing the following parameters:
   *
   * - `mobileNumberRequest`: The user's mobile number
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerSendMobileValidationTextMessagePostResponse(params: AccaTrackerService.AccaTrackerSendMobileValidationTextMessagePostParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoSendMobileValidationTokenResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.mobileNumberRequest;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AccaTracker/SendMobileValidationTextMessage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoSendMobileValidationTokenResult>;
      })
    );
  }
  /**
   * Send a text message for the user to verify their phone number.
   * @param params The `AccaTrackerService.AccaTrackerSendMobileValidationTextMessagePostParams` containing the following parameters:
   *
   * - `mobileNumberRequest`: The user's mobile number
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerSendMobileValidationTextMessagePost(params: AccaTrackerService.AccaTrackerSendMobileValidationTextMessagePostParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoSendMobileValidationTokenResult> {
    return this.AccaTrackerSendMobileValidationTextMessagePostResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoSendMobileValidationTokenResult)
    );
  }

  /**
   * Verifies that the provided token matches the token for the user.
   *
   * Does not return th elist of bet tickets
   * @param params The `AccaTrackerService.AccaTrackerValidateMobileValidationToken2Params` containing the following parameters:
   *
   * - `mobileValidationRequest`: User details and the validation token
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerValidateMobileValidationToken2Response(params: AccaTrackerService.AccaTrackerValidateMobileValidationToken2Params): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoMobileValidationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.mobileValidationRequest;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AccaTracker/ValidateMobileValidationToken`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoMobileValidationResponse>;
      })
    );
  }
  /**
   * Verifies that the provided token matches the token for the user.
   *
   * Does not return th elist of bet tickets
   * @param params The `AccaTrackerService.AccaTrackerValidateMobileValidationToken2Params` containing the following parameters:
   *
   * - `mobileValidationRequest`: User details and the validation token
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerValidateMobileValidationToken2(params: AccaTrackerService.AccaTrackerValidateMobileValidationToken2Params): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoMobileValidationResponse> {
    return this.AccaTrackerValidateMobileValidationToken2Response(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoMobileValidationResponse)
    );
  }

  /**
   * Sets a users password. The user has to be logged in first.
   *
   * Appears to be a duplicate of SetPasswordByMobileNumber
   * @param params The `AccaTrackerService.AccaTrackerSetPasswordByRefreshTokenParams` containing the following parameters:
   *
   * - `setPasswordRequest`: The password reset details such as new password and the refresh token
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerSetPasswordByRefreshTokenResponse(params: AccaTrackerService.AccaTrackerSetPasswordByRefreshTokenParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdatePasswordResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.setPasswordRequest;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AccaTracker/SetPasswordByRefreshToken`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdatePasswordResult>;
      })
    );
  }
  /**
   * Sets a users password. The user has to be logged in first.
   *
   * Appears to be a duplicate of SetPasswordByMobileNumber
   * @param params The `AccaTrackerService.AccaTrackerSetPasswordByRefreshTokenParams` containing the following parameters:
   *
   * - `setPasswordRequest`: The password reset details such as new password and the refresh token
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerSetPasswordByRefreshToken(params: AccaTrackerService.AccaTrackerSetPasswordByRefreshTokenParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdatePasswordResult> {
    return this.AccaTrackerSetPasswordByRefreshTokenResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdatePasswordResult)
    );
  }

  /**
   * Logs into the system
   *
   * This version DOES NOT contact BGT to refresh the bet tickets and return all active bet tickets
   * @param params The `AccaTrackerService.AccaTrackerLoginByMobileNumber2Params` containing the following parameters:
   *
   * - `loginRequest`: Credentials and device details
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerLoginByMobileNumber2Response(params: AccaTrackerService.AccaTrackerLoginByMobileNumber2Params): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoLoadFromLinkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.loginRequest;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AccaTracker/LoginByMobileNumber`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoLoadFromLinkResponse>;
      })
    );
  }
  /**
   * Logs into the system
   *
   * This version DOES NOT contact BGT to refresh the bet tickets and return all active bet tickets
   * @param params The `AccaTrackerService.AccaTrackerLoginByMobileNumber2Params` containing the following parameters:
   *
   * - `loginRequest`: Credentials and device details
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerLoginByMobileNumber2(params: AccaTrackerService.AccaTrackerLoginByMobileNumber2Params): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoLoadFromLinkResponse> {
    return this.AccaTrackerLoginByMobileNumber2Response(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoLoadFromLinkResponse)
    );
  }

  /**
   * Updates the users marketing messgaes preference
   *
   * The users identity is established from the refresh token
   * @param params The `AccaTrackerService.AccaTrackerUpdateUserProfileByMobileNumberParams` containing the following parameters:
   *
   * - `updateRequest`: The new preference setting and the refresh token
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerUpdateUserProfileByMobileNumberResponse(params: AccaTrackerService.AccaTrackerUpdateUserProfileByMobileNumberParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdateProfileResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.updateRequest;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AccaTracker/UpdateUserProfileByMobileNumber`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdateProfileResult>;
      })
    );
  }
  /**
   * Updates the users marketing messgaes preference
   *
   * The users identity is established from the refresh token
   * @param params The `AccaTrackerService.AccaTrackerUpdateUserProfileByMobileNumberParams` containing the following parameters:
   *
   * - `updateRequest`: The new preference setting and the refresh token
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerUpdateUserProfileByMobileNumber(params: AccaTrackerService.AccaTrackerUpdateUserProfileByMobileNumberParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdateProfileResult> {
    return this.AccaTrackerUpdateUserProfileByMobileNumberResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdateProfileResult)
    );
  }

  /**
   * Updates a users Profile Builder Profile
   *
   * Does not appear to be any different to the v1 End Point
   * @param params The `AccaTrackerService.AccaTrackerUpdateUserProfileBuilderByMobileNumber2Params` containing the following parameters:
   *
   * - `updateRequest`: New/updated profile builder details
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerUpdateUserProfileBuilderByMobileNumber2Response(params: AccaTrackerService.AccaTrackerUpdateUserProfileBuilderByMobileNumber2Params): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdateProfileBuilderResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.updateRequest;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AccaTracker/UpdateUserProfileBuilderByMobileNumber`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdateProfileBuilderResult>;
      })
    );
  }
  /**
   * Updates a users Profile Builder Profile
   *
   * Does not appear to be any different to the v1 End Point
   * @param params The `AccaTrackerService.AccaTrackerUpdateUserProfileBuilderByMobileNumber2Params` containing the following parameters:
   *
   * - `updateRequest`: New/updated profile builder details
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerUpdateUserProfileBuilderByMobileNumber2(params: AccaTrackerService.AccaTrackerUpdateUserProfileBuilderByMobileNumber2Params): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdateProfileBuilderResult> {
    return this.AccaTrackerUpdateUserProfileBuilderByMobileNumber2Response(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdateProfileBuilderResult)
    );
  }

  /**
   * Returns the user's profile
   * @param params The `AccaTrackerService.AccaTrackerGetUserProfileByMobileNumberParams` containing the following parameters:
   *
   * - `request`: Refresh token and other client identifiers
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerGetUserProfileByMobileNumberResponse(params: AccaTrackerService.AccaTrackerGetUserProfileByMobileNumberParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGetUserProfileSettingsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AccaTracker/GetUserProfileByMobileNumber`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGetUserProfileSettingsResponse>;
      })
    );
  }
  /**
   * Returns the user's profile
   * @param params The `AccaTrackerService.AccaTrackerGetUserProfileByMobileNumberParams` containing the following parameters:
   *
   * - `request`: Refresh token and other client identifiers
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerGetUserProfileByMobileNumber(params: AccaTrackerService.AccaTrackerGetUserProfileByMobileNumberParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGetUserProfileSettingsResponse> {
    return this.AccaTrackerGetUserProfileByMobileNumberResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoGetUserProfileSettingsResponse)
    );
  }

  /**
   * Gets the user's current progress for the Ticket Progress Tracker - The program where the user gets a 6th free bet if they track 5 bets of £5 or more
   * @param api-version undefined
   * @return OK
   */
  AccaTrackerOffersStatusV2Response(apiVersion: number): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoOffersResponseV2>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __headers = __headers.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AccaTracker/OffersStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoOffersResponseV2>;
      })
    );
  }
  /**
   * Gets the user's current progress for the Ticket Progress Tracker - The program where the user gets a 6th free bet if they track 5 bets of £5 or more
   * @param api-version undefined
   * @return OK
   */
  AccaTrackerOffersStatusV2(apiVersion: number): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoOffersResponseV2> {
    return this.AccaTrackerOffersStatusV2Response(apiVersion).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoOffersResponseV2)
    );
  }

  /**
   * Checks if there is a newer version of an app for the user's device
   *
   * Does return Informative updates
   * @param params The `AccaTrackerService.AccaTrackerCheckForNewVersion3Params` containing the following parameters:
   *
   * - `checkRequest`: Details of the user's device and the version of their current app
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerCheckForNewVersion3Response(params: AccaTrackerService.AccaTrackerCheckForNewVersion3Params): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCheckForNewVersionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.checkRequest;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AccaTracker/CheckForNewVersion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCheckForNewVersionResponse>;
      })
    );
  }
  /**
   * Checks if there is a newer version of an app for the user's device
   *
   * Does return Informative updates
   * @param params The `AccaTrackerService.AccaTrackerCheckForNewVersion3Params` containing the following parameters:
   *
   * - `checkRequest`: Details of the user's device and the version of their current app
   *
   * - `api-version`:
   *
   * @return OK
   */
  AccaTrackerCheckForNewVersion3(params: AccaTrackerService.AccaTrackerCheckForNewVersion3Params): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCheckForNewVersionResponse> {
    return this.AccaTrackerCheckForNewVersion3Response(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsCheckForNewVersionResponse)
    );
  }
}

module AccaTrackerService {

  /**
   * Parameters for AccaTrackerSendMobileValidationTextMessageGet
   */
  export interface AccaTrackerSendMobileValidationTextMessageGetParams {

    /**
     * The user's mobile number
     */
    mobileNumber: string;
    apiVersion: number;
  }

  /**
   * Parameters for AccaTrackerSendMobileValidationTextMessagePost
   */
  export interface AccaTrackerSendMobileValidationTextMessagePostParams {

    /**
     * The user's mobile number
     */
    mobileNumberRequest: Steer73PaddypowerCoreDtoMobileNumberRequest;
    apiVersion: number;
  }

  /**
   * Parameters for AccaTrackerValidateMobileValidationToken2
   */
  export interface AccaTrackerValidateMobileValidationToken2Params {

    /**
     * User details and the validation token
     */
    mobileValidationRequest: Steer73PaddypowerCoreDtoMobileValidationRequest;
    apiVersion: number;
  }

  /**
   * Parameters for AccaTrackerSetPasswordByRefreshToken
   */
  export interface AccaTrackerSetPasswordByRefreshTokenParams {

    /**
     * The password reset details such as new password and the refresh token
     */
    setPasswordRequest: Steer73PaddypowerCoreDtoMobileSetPasswordRequest;
    apiVersion: number;
  }

  /**
   * Parameters for AccaTrackerLoginByMobileNumber2
   */
  export interface AccaTrackerLoginByMobileNumber2Params {

    /**
     * Credentials and device details
     */
    loginRequest: Steer73PaddypowerCoreDtoMobileLoginRequest;
    apiVersion: number;
  }

  /**
   * Parameters for AccaTrackerUpdateUserProfileByMobileNumber
   */
  export interface AccaTrackerUpdateUserProfileByMobileNumberParams {

    /**
     * The new preference setting and the refresh token
     */
    updateRequest: Steer73PaddypowerCoreDtoUpdateUserProfileRequest;
    apiVersion: number;
  }

  /**
   * Parameters for AccaTrackerUpdateUserProfileBuilderByMobileNumber2
   */
  export interface AccaTrackerUpdateUserProfileBuilderByMobileNumber2Params {

    /**
     * New/updated profile builder details
     */
    updateRequest: Steer73PaddypowerCoreTransferObjectsUpdateUserProfileBuilderRequest;
    apiVersion: number;
  }

  /**
   * Parameters for AccaTrackerGetUserProfileByMobileNumber
   */
  export interface AccaTrackerGetUserProfileByMobileNumberParams {

    /**
     * Refresh token and other client identifiers
     */
    request: Steer73PaddypowerCoreDtoGetUserProfileSettingsRequest;
    apiVersion: number;
  }

  /**
   * Parameters for AccaTrackerCheckForNewVersion3
   */
  export interface AccaTrackerCheckForNewVersion3Params {

    /**
     * Details of the user's device and the version of their current app
     */
    checkRequest: Steer73PaddypowerCoreTransferObjectsCheckForNewVersionRequest;
    apiVersion: number;
  }
}

export { AccaTrackerService }
