import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable()
export class InitialLoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.userService.initialLoad) {
      return true;
    }

    if(localStorage.getItem('seenOnboarding') === null) {
      this.userService.initialLoad = false;
      this.router.navigate(['onboarding']);
      return;
    }

    if (state.url === '/home') {
      this.userService.initialLoad = false;
      return true;
    }

    return this.userService.userData$.pipe(take(1)).toPromise().then((user) => {
      this.userService.initialLoad = false;

      if (!user && state.url === '/track-my-bet') {
        this.router.navigate(['home']);
        return;
      } else {
        return true;
      }
    });
  }
}
