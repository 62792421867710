/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreDtoPromoV2ViewModel } from '../models/steer-73paddypower-core-dto-base-response-system-collections-generic-list-steer-73paddypower-core-dto-promo-v2view-model';
import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoPromoV2ViewModel } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-promo-v2view-model';
@Injectable({
  providedIn: 'root',
})
class PromoV2Service extends __BaseService {
  static readonly PromoV2GetPath = '/api/promov2';
  static readonly PromoV2Get_1Path = '/api/promov2/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the system URLs, endpoints and settings
   * @param api-version undefined
   * @return OK
   */
  PromoV2GetResponse(apiVersion: number): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreDtoPromoV2ViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __headers = __headers.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/promov2`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreDtoPromoV2ViewModel>;
      })
    );
  }
  /**
   * Gets the system URLs, endpoints and settings
   * @param api-version undefined
   * @return OK
   */
  PromoV2Get(apiVersion: number): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreDtoPromoV2ViewModel> {
    return this.PromoV2GetResponse(apiVersion).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreDtoPromoV2ViewModel)
    );
  }

  /**
   * Gets the system URLs, endpoints and settings
   * @param params The `PromoV2Service.PromoV2Get_1Params` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  PromoV2Get_1Response(params: PromoV2Service.PromoV2Get_1Params): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoPromoV2ViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/promov2/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoPromoV2ViewModel>;
      })
    );
  }
  /**
   * Gets the system URLs, endpoints and settings
   * @param params The `PromoV2Service.PromoV2Get_1Params` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  PromoV2Get_1(params: PromoV2Service.PromoV2Get_1Params): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoPromoV2ViewModel> {
    return this.PromoV2Get_1Response(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoPromoV2ViewModel)
    );
  }
}

module PromoV2Service {

  /**
   * Parameters for PromoV2Get_1
   */
  export interface PromoV2Get_1Params {
    id: number;
    apiVersion: number;
  }
}

export { PromoV2Service }
