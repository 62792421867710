<div class="header mw" [ngClass]="{'header--add-ticket' : router.isActive('/add-ticket', true)}">
  <div class="header__left">
    <ng-content select="[left]"></ng-content>
  </div>
  <div class="header__middle">
    <ng-content select="[middle]"></ng-content>
  </div>
  <div class="header__right">
    <ng-content select="[right]"></ng-content>
  </div>
</div>
