import { Component } from '@angular/core';
import { SideMenuService } from 'src/app/shared/side-menu.service';

@Component({
  selector: 'app-track-my-bet',
  templateUrl: './track-my-bet.component.html',
  styleUrls: ['./track-my-bet.component.scss']
})
export class TrackMyBetComponent {
  title: '';

  constructor(private sideMenuService: SideMenuService) { }

  showMenu() {
    return this.sideMenuService.doShowMenu();
  }
}
