import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PromoV2Service } from '../../../api/services';
import { take } from 'rxjs/operators';
import { Steer73PaddypowerCoreDtoPromoV2ViewModel } from 'src/app/api/models';
import { Location } from '@angular/common';

@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.scss']
})
export class PromoComponent implements OnInit {
  promo: Steer73PaddypowerCoreDtoPromoV2ViewModel;
  loading = true;

  constructor(
    private route: ActivatedRoute,    
    private promoService: PromoV2Service,
    private location: Location) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    this.getPromo(parseInt(id));
  }

  getPromo(id: number) {    
      this.promoService.PromoV2Get_1({
        id: id,
        apiVersion: 1
      }).pipe(take(1)).subscribe((res) => {
        this.loading = false;
        this.promo = res.Payload;
      });    
  }
  
  goBack() {
    this.location.back();
  }
}
