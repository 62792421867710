/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdatePasswordResult } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-dto-update-password-result';
import { Steer73PaddypowerCoreDtoSetPasswordRequest } from '../models/steer-73paddypower-core-dto-set-password-request';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly UserSetPasswordPath = '/api/user/SetPassword';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Sets a users password. The user has to be logged in first.
   *
   * Appears to be a duplicate of SetPasswordByMobileNumber
   * @param params The `UserService.UserSetPasswordParams` containing the following parameters:
   *
   * - `request`: The password reset details such as new password and the refresh token
   *
   * - `api-version`:
   *
   * @return OK
   */
  UserSetPasswordResponse(params: UserService.UserSetPasswordParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdatePasswordResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/user/SetPassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdatePasswordResult>;
      })
    );
  }
  /**
   * Sets a users password. The user has to be logged in first.
   *
   * Appears to be a duplicate of SetPasswordByMobileNumber
   * @param params The `UserService.UserSetPasswordParams` containing the following parameters:
   *
   * - `request`: The password reset details such as new password and the refresh token
   *
   * - `api-version`:
   *
   * @return OK
   */
  UserSetPassword(params: UserService.UserSetPasswordParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdatePasswordResult> {
    return this.UserSetPasswordResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreDtoUpdatePasswordResult)
    );
  }
}

module UserService {

  /**
   * Parameters for UserSetPassword
   */
  export interface UserSetPasswordParams {

    /**
     * The password reset details such as new password and the refresh token
     */
    request: Steer73PaddypowerCoreDtoSetPasswordRequest;
    apiVersion: number;
  }
}

export { UserService }
