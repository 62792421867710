import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WildcardRoutingModule } from './wildcard-routing.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    WildcardRoutingModule
  ]
})
export class WildcardModule { }
