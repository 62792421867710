import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/auth.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { AnalyticsManager } from '../../../shared/analytics-manager.service';
import { LoginInteractionResult } from 'src/app/enums/loginInteractionResult.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  showError = false;
  isLoading = false;
  showPassword = false;
  errorMessage: string;
  loginIncorrectLength: string = 'Whoops! Your phone number has to be 10 or 11 digits';

  constructor(
    private titleService: Title,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router, 
    private analyticsManager: AnalyticsManager
  ) {
    this.titleService.setTitle('Login');
  }

  ngOnInit() {
    this.form = this.fb.group({
      number: ['', [
        Validators.required,
        Validators.pattern(/^((07[\d]{8,9}|447[\d]{8,9}|\+447[\d]{8,9})|(08[\d]{8,10}|3538[\d]{8,10}|\+3538[\d]{8,10}))$/)
      ]],
      password: ['', [
        Validators.required
      ]]
    });
  }

  get f() { return this.form.controls; }

  login() {
    this.showError = false;
    this.isLoading = true;
    this.analyticsManager.loginIneraction(LoginInteractionResult.Login);
    if (this.form.valid) {
      this.authService.login(this.form.controls.number.value, this.form.controls.password.value)
      .pipe(take(1))
      .subscribe((resp) => {
        this.isLoading = false;
        this.router.navigate(['/track-my-bet']);
      },
      (err) => {
        this.isLoading = false;
        if (err) {
          this.errorMessage = err.message;
        }
        this.setError();
      });
    } else {      
      if(this.form.controls.number.errors.pattern) {
        this.analyticsManager.loginError(this.loginIncorrectLength);
      }

      this.isLoading = false;
      this.setError();
    }
  }

  setError() {
    this.showError = true;
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
    
    this.analyticsManager.loginIneraction(LoginInteractionResult.ShowPassword);
  }

  resetPassword() {
    this.analyticsManager.loginIneraction(LoginInteractionResult.ForgotPassword);
    this.router.navigate(['/reset-password']);
  }

  createAccount() {
    this.analyticsManager.loginIneraction(LoginInteractionResult.CreateAccount);
    this.router.navigate(['/create-account']);
  }
}
