<app-header>
    <ng-container left>
      <i class="fas fa-times" (click)="hideMenu()"></i>
    </ng-container>
    <ng-container middle>
      Menu
    </ng-container>
</app-header>

<div class="menu">
  <div class="menu__section" *ngIf="currentUser | async; let user">
    <span class="menu__title">Account</span>
    <div>
      <ul class="menu__links">
        <li class="menu__info">
          <i class="menu__left-icon fas fa-mobile-alt"></i>
          <span>
            Phone number
            <span class="menu__phone">{{user.Number}}</span>
          </span>
        </li>
      </ul>
    </div>
  </div>

  <div class="menu__section" *ngIf="(currentUser | async)">
    <span class="menu__title">Settings</span>
    <div>
      <ul class="menu__links">
        <li class="menu__info">
          <i class="menu__left-icon far fa-comment-alt"></i>
          <span>SMS marketing messages
            <input class="form__toggle menu__toggle" [disabled]="disableMarketing" type="checkbox" [(ngModel)]="hasMarketing" (change)="toggleSwitch(hasMarketing)" />
          </span>
          <small class="helper-text">I'm happy to be contacted by Paddy Power about shop offers, free bets and online sign up offers</small>
        </li>
      </ul>
    </div>
  </div>

  <div class="menu__section menu__login" *ngIf="!(currentUser | async)">
    <a class="menu__button" [routerLink]="['/login']">
      Login
    </a>
  </div>

  <div class="menu__section menu__information">
    <span class="menu__title">Information</span>
    <nav>
      <ul class="menu__links">
        <li class="menu__info">
          <a [routerLink]="['/faqs']">
            <i class="menu__left-icon far fa-question-circle"></i>
            <span>
              Frequently asked questions
              <i class="fas fa-chevron-right menu__right-icon"></i>
            </span>
          </a>
        </li>
        <li class="menu__info">
          <a [routerLink]="['/terms-and-conditions']">
            <i class="menu__left-icon far fa-file-alt"></i>
            <span>
              Terms and conditions
              <i class="fas fa-chevron-right menu__right-icon"></i>
            </span>
          </a>
        </li>
        <li class="menu__info">
          <a href="https://support.paddypower.com/app/answers/detail/a_id/9/~/privacy-%26-cookie-policy" target="_blank">
            <i class="menu__left-icon fas fa-cookie-bite"></i>
            <span>
              Privacy and cookie policy
              <i class="fas fa-external-link-alt menu__right-icon"></i>
            </span>
          </a>
        </li>
        <li class="menu__info">
          <a href="https://responsiblegaming.paddypower.com/" target="_blank">
            <i class="menu__left-icon far fa-hand-paper"></i>
            <span>
              Safer gambling
              <i class="fas fa-external-link-alt menu__right-icon"></i>
            </span>
          </a>
        </li>
      </ul>
    </nav>
  </div>

  <div class="menu__section menu__logout" *ngIf="currentUser | async">
    <div class="menu__button" (click)="logout()">
      Logout
    </div>
  </div>

  <footer class="menu__footer">

    <div class="menu__footer-cookie">
      <a (click)="manageCookie()">Manage Cookie Preferences</a>
    </div>

    <div class="footer__brands">
        <div class="row">
          <div class="col5-s">
            <a href="https://www.authorisation.mga.org.mt/verification.aspx?lang=en&company=4f2ce9bc-6584-440c-8643-9314defffd0e" target="_blank">
              <img class="ir ir--gc" src="assets/images/rasters/logos/mga.png" alt="MGA" width="146" height="29">
            </a>
          </div>
          <div class="col5-s">
            <a href="https://www.gamcare.org.uk/" target="_blank">
              <img class="ir ir--gs" src="assets/images/rasters/logos/gamcare.png" alt="Gamcare" width="126" height="36">
            </a>
          </div>
          <div class="col2-s">
            <a href="https://www.gamblingtherapy.org/" target="_blank">
              <img class="ir ir--eteen" src="assets/images/rasters/logos/gamingtheraphy.png" alt="Gambling Therapy" width="37" height="32">
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col5-s">
            <a href="https://www.gamblingcommission.gov.uk/home.aspx" target="_blank">
              <img class="ir ir--gc" src="assets/images/vectors/logos/gambling-commision.svg" alt="Gambling Commision" width="98" height="26">
            </a>
          </div>
          <div class="col7-s">
            <a href="https://www.begambleaware.org/" target="_blank">
              <img class="ir ir--gc" src="assets/images/rasters/logos/gambleaware.png" alt="Be Gamble Aware" width="156" height="16">
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col5-s">
            <a href="https://www.gamstop.co.uk/" target="_blank">
              <img class="ir ir--gs" src="assets/images/vectors/logos/gamstop.svg" alt="Gam Stop" width="111" height="20">
            </a>
          </div>
          <div class="col5-s">
            <a href="https://bettingandgamingcouncil.com/" target="_blank">
              <img class="ir ir--gs" src="assets/images/rasters/logos/wtfss.png" alt="When the fun stops STOP" width="149" height="26">
            </a>
          </div>
          <div class="col2-s">
            <a href="https://helpcenter.paddypower.com/app/answers/detail/p/6/a_id/70/" target="_blank">
              <img class="ir ir--eteen" src="assets/images/vectors/logos/18.svg" alt="18 +" width="32" height="32">
            </a>
          </div>
        </div>
    </div>
    <div class="gambling__info">
      <h5>Safer Gambling Support</h5>
      <div class="row">
        <div class="col6-s flex-column">
          <h6>For UK customers:</h6>
          <a href="tel:08088020133">0808 8020 133</a>
          <a href="https://www.begambleaware.org/" target="_blank"><strong>www.begambleaware.org</strong></a>
        </div>
        <div class="col6-s flex-column">
          <h6>For ROI customers:</h6>
          <a href="tel:1800936725">1800 936 725</a>
          <a href="https://www.gamblingcare.ie/" target="_blank"><strong>www.gamblingcare.ie</strong></a>
        </div>
      </div>
    </div>
  </footer>

</div>
