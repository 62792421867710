import { NgModule } from '@angular/core';
import { GeoBlockingComponent } from './geo-blocking.component';
import { GeoBlockingRoutingModule } from './geo-blocking-routing.module';

@NgModule({
  declarations: [
    GeoBlockingComponent
  ],
  imports: [
    GeoBlockingRoutingModule,
  ]
})
export class GeoBlockingModule { }
