import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {
  @Input() src: string;
  @Input() type: string;
  @Input() mediaService: any;

  constructor() { }

  ngOnInit() { }

  play() {
    this.mediaService.play(this.getPlayer());
  }

  stop() {
    this.mediaService.stop(this.getPlayer());
  }

  toggle() {
    this.mediaService.toggle(this.getPlayer());
  }

  getPlayer() {
    return document.querySelector('.player');
  }
}
