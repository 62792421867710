import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

import { LoginCreateAccountResult } from 'src/app/enums/loginCreateAccountResult.enum';
import { LoginInteractionResult } from 'src/app/enums/loginInteractionResult.enum';
import { BetsInteractionResult } from 'src/app/enums/betsInteractionResult.enum';
import { BetCardInteractionResult } from 'src/app/enums/betCardInteractionResult.enum';
import { StreamingInteractionResult } from 'src/app/enums/streamingInteractionResult.enum';
import { AddTicketResult } from 'src/app/enums/addTicketResult.enum';
import { AddTicketSuccessResult } from 'src/app/enums/addTicketSuccessResult.enum';
import { MenuSmsMarketingResult } from 'src/app/enums/menuSmsMarketingResult.enum';
import { RewardsBetWithPlayResult } from 'src/app/enums/rewardsBetWithPlayResult.enum';
import { Subscription } from 'rxjs';
import { UserService, User } from './user.service';

@Injectable({
    providedIn: 'root'
})

export class AnalyticsManager {

    authSubscription: Subscription;
    currentUser: User = null;

    LoginCreateAccountResult = LoginCreateAccountResult;
    LoginInteractionResult = LoginInteractionResult;
    BetsInteractionResult = BetsInteractionResult;
    BetCardInteractionResult = BetCardInteractionResult;
    StreamingInteractionResult = StreamingInteractionResult;
    AddTicketResult = AddTicketResult;
    AddTicketSuccessResult = AddTicketSuccessResult;
    MenuSmsMarketingResult = MenuSmsMarketingResult;
    RewardsBetWithPlayResult = RewardsBetWithPlayResult;

    AnonymousEventPostfix: string = "_anonymous";

    constructor(
        private analytics: AngularFireAnalytics,
        private userService: UserService) {

            this.authSubscription = this.userService.userData$
                .subscribe((user) => {
                    this.currentUser = user;
                });
    }

    logEvent(name: string, param: string, value: string, distinguishAnonymous: boolean) {
        if (distinguishAnonymous && !this.currentUser) {
            name += this.AnonymousEventPostfix;
        }

        this.analytics.logEvent(name, { param, value });
    }

    loginCreateAccount(result: LoginCreateAccountResult, creatingAccount: boolean) {
        let evnt = creatingAccount ? 'login_create_account' : 'login_forgot_password';
        let param = creatingAccount ? 'create_account_result' : 'forgot_password_result';

        this.logEvent(evnt, param, this.LoginCreateAccountResult[result], false);
    }

    loginIneraction(result: LoginInteractionResult) {
        this.logEvent('login_interaction', 'login_interaction_result', this.LoginInteractionResult[result], false);
    }

    loginError(reason: string) {
        this.logEvent('login_error', 'login_error_reason', reason, false);
    }

    betsInteraction(result: BetsInteractionResult, open: boolean) {
        let param = open ? 'open_bets_interaction' : 'settled_bets_interaction';
        this.logEvent('bets_interaction', param, this.BetsInteractionResult[result], true);
    }

    betCardInteraction(result: BetCardInteractionResult) {
        this.logEvent('bet_card_interaction', 'interaction', this.BetCardInteractionResult[result], true);
    }

    streamingInteraction(result: StreamingInteractionResult) {
        this.logEvent('streaming', 'stream_type', this.StreamingInteractionResult[result], true);
    }

    addTicket(result: AddTicketResult, isScanned: boolean) {
        let evnt = isScanned ? 'add_ticket_barcode_scanner' : 'add_ticket_manual';

        this.logEvent(evnt, 'addticket_result', this.AddTicketResult[result], true);
    }

    addTicketError(reason: string) {
        this.logEvent('addticket_error', 'addticket_error_reason', reason, true);
    }

    addTicketSuccess(result: AddTicketSuccessResult) {
        this.logEvent('add_ticket_success', 'success_action', this.AddTicketSuccessResult[result], true);
    }

    menuSmsMarketing(result: MenuSmsMarketingResult) {
        this.logEvent('menu_sms_marketing', 'sms_marketing', this.MenuSmsMarketingResult[result], true);
    }

    errorBetsNotLoading(reason: string) {
        this.logEvent('error_bets_not_loading', 'reason', reason, true);
    }

    rewardsBetWithPlay(result: RewardsBetWithPlayResult) {
        this.logEvent('rewards_bet_with_play', 'bet_with_play_result', this.RewardsBetWithPlayResult[result], true);
    }
}