import { Injectable } from '@angular/core';
import { Steer73PaddypowerCoreTransferObjectsSettingsResponseModel } from '../api/models';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  private settings: Steer73PaddypowerCoreTransferObjectsSettingsResponseModel;

  constructor() { }

  store(settings: Steer73PaddypowerCoreTransferObjectsSettingsResponseModel) {
    this.settings = settings;
  }

  fetch(): Steer73PaddypowerCoreTransferObjectsSettingsResponseModel {
    return this.settings;
  }
}
