import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { RewardsService } from 'src/app/api/services/rewards.service';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class RewardsComponent implements OnInit {
  showRewards = false;
  status;

  constructor(
    private titleService: Title,
    private rewardsService: RewardsService
  ) {
    this.titleService.setTitle('Rewards');
  }

  ngOnInit(): void {
    this.rewardsService.RewardsGetPlayRewards(1)
    .pipe(take(1))
    .subscribe((res) => {
      this.status = res.Payload.SixthBetFreeStatus;
      this.showRewards = true;
    });

  }
}
