<div class="navigation mw">
  <a *ngIf="currentStep === formStep.number" [routerLink]="['/login']" routerLinkActive="router-link-active" class="charcoal-text"><i class="fa fa-arrow-left">&nbsp;</i></a>
  <i *ngIf="currentStep !== formStep.number" class="fa fa-arrow-left charcoal-text" (click)="currentStep = formStep.number">&nbsp;</i>
  <span>{{currentTitleStep}}</span>
</div>

<div class="account mw">
  <h1 class="account__title">Hello <br>There<span>.</span></h1>

  <form (ngSubmit)="sendNumber()" [formGroup]="form" *ngIf="form && currentStep === formStep.number" class="form form--number">
    <div class="form__group">
      <label class="form__label" [ngClass]="{'form__label--error' : showError && f.number.errors}">Your phone number</label>
      <div class="form__input-with-icon">
        <i class="fas fa-mobile-alt form__icon"></i>
        <input type="text" inputmode="tel" placeholder="" formControlName="number" class="form__input" [ngClass]="{'form__input--error' : showError && f.number.errors}" (keyup.enter)="$event.target.blur()"/>
      </div>
      <div class="form__errors" *ngIf="showError && f.number.errors && f.number.errors.required">Phone number required</div>
      <div class="form__errors" *ngIf="showError && f.number.errors && f.number.errors.pattern">Whoops! Phone number has to be 10 or 11 digits</div>
    </div>

    <button type="submit" [disabled]="isLoading" class="button button--large button--center">
      <i *ngIf="isLoading; else submitText" class="spinner spinner--light spinner--small"></i>
      <ng-template #submitText>Submit</ng-template>
    </button>

    <p class="account__terms small-text">By clicking submit I agree to the <a [routerLink]="['/terms-and-conditions']">Terms &amp; Conditions</a></p>
  </form>

  <form (ngSubmit)="validateNumber()" [formGroup]="formValidate" *ngIf="formValidate && currentStep === formStep.validate" class="form form--validate">
    <div class="form__group">
      <label class="form__label" [ngClass]="{'form__label--error' : showError && f.number.errors}">Your phone number</label>
      <div class="form__input-with-icon">
        <i class="fas fa-mobile-alt form__icon"></i>
        <input type="text" placeholder="" formControlName="number" class="form__input" [ngClass]="{'form__input--error' : showError && f.number.errors}"/>
      </div>
      <div class="form__errors" *ngIf="showError && f.number.errors && f.number.errors.required account__tooltip">Phone number required</div>
      <div class="form__errors" *ngIf="showError && f.number.errors && f.number.errors.pattern account__tooltip">Whoops! Phone number has to be 10 or 11 digits</div>
    </div>

    <div class="form__group">
      <label for="validationCode" class="form__label" [ngClass]="{'form__label--error' : showError && f.validationCode.errors}">Enter the 6 digit validation code</label>
      <div class="form__input-with-icon">
        <i class="fas fa-key form__icon"></i>
        <input type="test" placeholder="" formControlName="validationCode" class="form__input" [ngClass]="{'form__input--error' : showError && f.validationCode.errors}" (keyup.enter)="$event.target.blur()"/>
      </div>
      <div class="form__errors" *ngIf="showError && f.validationCode.errors && f.validationCode.errors.required account__tooltip">Validation code required</div>
      <div class="form__errors" *ngIf="showError && f.validationCode.errors && f.validationCode.errors.pattern account__tooltip">Validation code must be 6 characters long</div>
      <div class="form__errors" *ngIf="showError && errorMessage">{{errorMessage}}</div>
    </div>

    <a class="account__resend" (click)="sendNumber()">Resend validation code</a>

    <button type="submit" [disabled]="isLoading" class="button button--large button--center">
      <i *ngIf="isLoading; else validationVerificationText" class="spinner spinner--light spinner--small"></i>
      <ng-template #validationVerificationText>Verify</ng-template>
    </button>
  </form>

  <form (ngSubmit)="createPassword()" [formGroup]="formPassword" *ngIf="formPassword && currentStep === formStep.password" class="form form--password">
    <div class="form__group">
      <label for="number" class="form__label" [ngClass]="{'form__label--error' : showError && f.number.errors}">Your phone number</label>
      <div class="form__input-with-icon">
        <i class="fas fa-mobile-alt form__icon"></i>
        <input type="text" placeholder="" formControlName="number" class="form__input" [ngClass]="{'form__input--error' : showError && f.number.errors}"/>
      </div>
      <div class="form__errors" *ngIf="showError && f.number.errors && f.number.errors.required">Phone number required</div>
      <div class="form__errors" *ngIf="showError && f.number.errors && f.number.errors.pattern">Whoops! Phone number has to be 10 or 11 digits</div>
    </div>

    <div class="form__group">
      <label for="password" class="form__label" [ngClass]="{'form__label--error' : showError && f.password.errors}">Password</label>
      <div class="form__input-with-icon form__input-with-icon--right">
        <i class="fas fa-unlock-alt form__icon"></i>
        <input id="password" [type]="showPassword ? 'text' : 'password'" placeholder="" formControlName="password" class="form__input" [ngClass]="{'form__input--error' : showError && f.password.errors}" (keyup.enter)="$event.target.blur()"/>
        <i class="fas form__icon login__eye" [ngClass]="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}" (click)="togglePassword()"></i>
      </div>
      <div class="form__errors" *ngIf="showError && f.password.errors && f.password.errors.required">Password required</div>
      <div class="form__errors" *ngIf="showError && f.password.errors && f.password.errors.minLength">Password must be at least 6 characters</div>
      <div class="form__errors" *ngIf="showError && f.password.errors && f.password.errors.maxLength">Too many characters</div>
      <div class="form__errors" *ngIf="showError && f.password.errors && f.password.errors.pattern">Password must contain lowercase, uppercase and numbers</div>
    </div>

    <button type="submit" [disabled]="isLoading" class="button button--large button--center">
      <i *ngIf="isLoading; else passwordVerificationText" class="spinner spinner--light spinner--small"></i>
      <ng-template #passwordVerificationText>Verify</ng-template>
    </button>
  </form>
</div>
