<app-header>
  <ng-container left>
    <a class="fas fa-times" (click)="goBack()"></a>
  </ng-container>
  <ng-container middle>
    Announcement
  </ng-container>
</app-header>
<div class="announcement">
  <div class="announcement__container" *ngIf="!loading">
    <div class="announcement__img" *ngIf="annoucement.ImageUrl">
      <img [src]="annoucement.ImageUrl" alt="Announcement">
    </div>
      <h2 class="announcement__title">{{ annoucement.Name }}</h2>
      <div [innerHTML]="annoucement.Description" class="announcement__text"></div>
  </div>
</div>
