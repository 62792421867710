export enum CashoutErrorCode {
  GenError = -2,
  SbsCashoutTemporaryNotPossible = -180002,
  BsCashOutNotPossibleForBonusTicket = 10617,
  SbsCashoutNotPossible = -180001,
  InvalidTicketPinWithUpdateIdException = -1,
  BsCashOutBetTicketNotOpen = 10619,
  EmsErrorClosed = 101013,
  // Partial
  BsCashOutAmountOutOfRange = 10616,
  EmsErrorOddsChangeDown = 101001,
  EmsErrorOddsChangeUp = 101002
}
