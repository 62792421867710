<app-header>
  <ng-container middle>
    <img src="assets/images/vectors/logo-small.svg" alt="Brand" width="38">
  </ng-container>
  <ng-container right>
    <a class="icon icon--add-bet" [routerLink]="['/add-ticket']"></a>
  </ng-container>
</app-header>

<div class="home">
    <div class="home__container home__container--loggedout home__container--padding" *ngIf="showLoginNotification()">
      <a [routerLink]="['/login']">
        <h4 class="home__title">You're logged out</h4>
        <p class="home__para">Log in to save your bets and learn more about our retail games and rewards.</p>
        <span class="home__link">Log in <i class="fas fa-long-arrow-alt-right"></i></span>
      </a>
    </div>
    <div class="home__container home__container--addTicket home__container--padding">
      <a [routerLink]="['/add-ticket']">
        <h4 class="home__title">Add ticket</h4>
        <p class="home__para">Your bet number is located at the top of the bet ticket.</p>
        <span class="home__link">Enter ticket ID <i class="fas fa-long-arrow-alt-right"></i></span>
      </a>
    </div>

    <ng-container *ngFor="let annoucement of annoucements" [ngSwitch]="annoucement.LinkType">
      <div class="home__container home__container--announcement home__container--padding">
        <a *ngSwitchCase="1" [routerLink]="['/announcement', annoucement.Id]">
          <h4 class="home__title">{{ annoucement.Name }}</h4>
          <p class="home__para">
            {{ annoucement.IntroText }}
            <a class="home__link" [routerLink]="['/announcement', annoucement.Id]">read more</a>
          </p>
        </a>
        <a *ngSwitchCase="2" href="{{ annoucement.LinkUrl }}" target="_blank">
          <h4 class="home__title">{{ annoucement.Name }}</h4>
          <p class="home__para">
            {{ annoucement.IntroText }}
            <a class="home__link" href="{{ annoucement.LinkUrl }}" target="_blank">read more</a>
          </p>
        </a>
      </div>
    </ng-container>

    <ng-container *ngFor="let promo of promos">
      <div class="home__container home__container--promo">
        <ng-container *ngIf="promo.LinkType === 1">
            <a class="home__link" [routerLink]="['/promo',promo.Id]" >
              <img [src]="promo.ImageUrl" alt="" />
            </a>
        </ng-container>

        <ng-container *ngIf="promo.LinkType === 2">
            <a class="home__link" href="{{ promo.LinkUrl }}" target="_blank">
              <img [src]="promo.ImageUrl" alt="" />
            </a>
        </ng-container>
      </div>
    </ng-container>

    <div class="home__container home__container--download" *ngIf="loaded">
      <img src="assets/images/vectors/home/applogo.svg" alt="App Logo" width="63" height="63"/>
      <p class="home__para"><strong>Download the app now</strong> The most convenient way to track your bets </p>
      <div class="home__btn-group">
        <a href="https://play.google.com/store/apps/details?id=com.paddypower.retailapp&hl=en&gl=IE" target="_blank">
            <img src="assets/images/vectors/home/google-play.svg" alt="Download from play store" width="135" height="40"/>
        </a>
        <a href="https://apps.apple.com/gb/app/paddy-power-onside-shop-app/id1115230050" target="_blank">
            <img src="assets/images/vectors/home/appstore.svg" alt="Download from apple store" width="135" height="40"/>
        </a>
      </div>
    </div>

    <div class="home__terms" *ngIf="loaded">
      <p>
        Use of Paddy Power Onside is subject to these
        <span class="nowrap">
          <a [routerLink]="['/terms-and-conditions']">Terms &amp; conditions</a> and
          <a href="https://support.paddypower.com/app/answers/detail/a_id/9/p/6" target="_system">Privacy &amp; Cookie Policy</a>
          <a href="https://support.paddypower.com/app/answers/detail/a_id/70/" target="_system">
              <img class="home__terms--18" src="assets/images/vectors/home/18plus.svg" alt="18+">
          </a>
        </span>
      </p>
      <p>
        <a (click)="manageCookie()">Manage Cookie Preferences</a>
      </p>
    </div>

</div>

<app-footer></app-footer>
