import { Injectable } from '@angular/core';
import { IMediaService } from './IMedia.service';

@Injectable({
  providedIn: 'root'
})
export class AudioService implements IMediaService {

  constructor() { }

  play(element: HTMLAudioElement) {
    element.play();
  }

  stop(element: HTMLAudioElement) {
    element.pause();
  }

  toggle(element: HTMLAudioElement) {
    if (element.paused) {
      this.play(element);
    } else {
      this.stop(element);
    }
  }

}
