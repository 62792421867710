import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccaTrackerService } from 'src/app/api/services';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/shared/user.service';
import { Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { AnalyticsManager } from '../../../shared/analytics-manager.service';
import { LoginCreateAccountResult } from 'src/app/enums/loginCreateAccountResult.enum';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  form: FormGroup;
  formValidate: FormGroup;
  formPassword: FormGroup;
  showPassword = false;

  refreshToken = null;
  showError = false;
  isLoading = false;
  errorMessage = null;
  currentTitleStep: string;

  formStep = {
    number: 1,
    validate: 2,
    password: 3
  };
  currentStep = this.formStep.number;

  number = ['', [
    Validators.required,
    Validators.pattern(/^((07[\d]{8,9}|447[\d]{8,9}|\+447[\d]{8,9})|(08[\d]{8,10}|3538[\d]{8,10}|\+3538[\d]{8,10}))$/)
  ]];

  constructor(
    private titleService: Title,
    private fb: FormBuilder,
    private accaTracker: AccaTrackerService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute, 
    private analyticsManager: AnalyticsManager
  ) {
    this.route.data
    .pipe(take(1))
    .subscribe(res => {
      this.titleService.setTitle(res.header)
      this.currentTitleStep = res.header
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      number: this.number
    });

    this.formValidate = this.fb.group({
      number: this.number,
      validationCode: ['', [
        Validators.required,
        Validators.pattern(/^[0-9]{6}$/)
      ]]
    });

    this.formPassword = this.fb.group({
      number: this.number,
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/),
        Validators.maxLength(32)
      ]]
    });
  }

  get f() {
    switch (this.currentStep) {
      case this.formStep.number:
        return this.form.controls;
      case this.formStep.password:
        return this.formPassword.controls;
      case this.formStep.validate:
        return this.formValidate.controls;
    }
  }

  sendNumber() {
    this.showError = false;

    if (this.form.valid) {
      this.isLoading = true;
      this.accaTracker.AccaTrackerSendMobileValidationTextMessagePost({
        apiVersion: 1,
        mobileNumberRequest: {
          MobileNumber: this.form.controls.number.value
        }
      })
      .pipe(take(1))
      .subscribe(res => {
        if (res.Payload.Success) {
          this.formValidate.patchValue({number: this.form.controls.number.value});
          this.currentStep = this.formStep.validate;
          this.currentTitleStep = 'Verify';
        }

        this.isLoading = false;
      });
    } else {
      this.analyticsManager.loginCreateAccount(LoginCreateAccountResult.StoppedAtEnterPhoneNumber, true);

      this.showError = true;
    }
  }

  validateNumber() {
    this.showError = false;
    this.errorMessage = null;

    if (this.formValidate.valid) {
      this.isLoading = true;
      this.accaTracker.AccaTrackerValidateMobileValidationToken2({
        apiVersion: 2,
        mobileValidationRequest:{
          MobileNumber: this.formValidate.controls.number.value,
          MobileValidationToken: this.formValidate.controls.validationCode.value
        }
      })
      .pipe(take(1))
      .subscribe(res => {
        if (res.Payload.IsValid) {
          this.userService.storeUser(res.Payload.MobileNumberCanonical)
          this.refreshToken = res.Payload.RefreshToken;
          this.formPassword.patchValue({number: this.formValidate.controls.number.value});
          this.currentStep = this.formStep.password;
        } else {
          this.analyticsManager.loginCreateAccount(LoginCreateAccountResult.StoppedAtVerification, true);

          this.showError = true;
          this.errorMessage = 'Validation code not valid';
        }

        this.isLoading = false;
      });
    } else {
      this.showError = true;
    }
  }

  createPassword() {
    this.showError = false;

    if (this.formPassword.valid) {
      this.isLoading = true;
      this.accaTracker.AccaTrackerSetPasswordByRefreshToken({
        apiVersion: 1,
        setPasswordRequest: {
          Password: this.formPassword.controls.password.value,
          ClientGuid: 'M0CSQJsMuz2MfeNGZ50ebzh6mrTDcW',
          ExistingRefreshToken: this.refreshToken
        }
      })
      .pipe(take(1))
      .subscribe(res => {
        if (res.Payload.Success) {
          this.router.navigate(['/track-my-bet']);
        } else {
          this.analyticsManager.loginCreateAccount(LoginCreateAccountResult.StoppedAtPasswordInput, false);
        }

        this.isLoading = false;
      });
    } else {
      this.analyticsManager.loginCreateAccount(LoginCreateAccountResult.StoppedAtPasswordInput, true);

      this.showError = true;
    }
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }
}
