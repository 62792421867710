import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {
  menuShown = false;

  constructor() { }

  showMenu() {
    this.menuShown = true;
  }

  hideMenu() {
    this.menuShown = false;
  }

  toggleMenu() {
    this.menuShown = !this.menuShown;
  }

  doShowMenu() {
    return this.menuShown;
  }
}
