import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService {
  appInsights: ApplicationInsights;

  constructor(
      private environmentService: EnvironmentService
    ) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.environmentService.getEnvironmentConfiguration().appInsights.instrumentationKey,
        enableDebug: this.environmentService.getEnvironmentConfiguration().appInsights.enableDebug,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
  
    const script = document.createElement('script');
    script.innerText = `const event = new CustomEvent('initAppInsights'); window.dispatchEvent(event);`;

    script.setAttribute('type', 'text/plain');
    script.setAttribute('async', 'true');
    script.setAttribute('class', 'optanon-category-C0002');

    document.documentElement.firstChild.appendChild(script);
    
  }

  initAppInisghts() {
    this.appInsights.config.disableTelemetry = false;
    this.appInsights.loadAppInsights();
  }

  disableAppInsights() {
    this.appInsights.config.disableTelemetry = true;
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message}, properties);
  }

}