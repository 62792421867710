<div class="onboarding">

  <div class="slider">
    <div class="slider__wrapper">
      <div class="slider__content selected">
        <img class="slider__img" src="assets/images/rasters/onboarding/slider-1.png"  alt="Track My Bet" width="375" height="545">
      </div>
      <div class="slider__content">
        <img class="slider__img" src="assets/images/rasters/onboarding/slider-2.png" alt="Cashout" width="375" height="545">
      </div>
      <div class="slider__content">
        <img class="slider__img" src="assets/images/rasters/onboarding/slider-3.png" alt="Rewards" width="375" height="545">
      </div>
    </div>

    <div class="slider__pagination"></div>    
    
    <div class="slider__wrapper">
      <div class="slider__bottom_content selected">
        <div class="slider__caption">
          <p>
            <strong>Welcome to the new and improved Paddy Power Onside app!</strong>
          </p>
        </div>
      </div>
      <div class="slider__bottom_content">
        <div class="slider__caption">
          <p>
            <strong>Track and cash out your bets easier than ever</strong>
          </p>
        </div>
      </div>
      <div class="slider__bottom_content">
        <div class="slider__caption">
          <p>
            <strong>Follow, watch and listen in-play</strong>
          </p>
        </div>
      </div>
    </div>
    <footer class="slider__footer">
      <a class="button button--muted slider__skip" (click)="finishOnboarding()">Skip</a>
      <a class="button slider__next" (click)="nextSlides()">Next</a>
      <button class="button slider__complete" title="Got It!" (click)="finishOnboarding()">Done</button>
    </footer>
  </div>

</div>
