import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartupService } from './shared/startup.service';
import { TrackMyBetModule } from './modules/track-my-bet/track-my-bet.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiModule } from './api/api.module';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { environment } from '../environments/environment';
import { ResponseInterceptor } from './interceptors/response.interceptor';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { ErrorsHandlerService } from './shared/errors-handler.service';
import { ErrorsInterceptor } from './interceptors/errors.interceptor';
import { AccountModule } from './modules/account/account.module';
import { SignalRService } from './shared/signalr.service';
import { GeoBlockingGuard } from './shared/geo-blocking-guard';
import { InitialLoginGuard } from './shared/initial-login-guard';
import { GeoBlockingModule } from './modules/geo-blocking/geo-blocking.module';
import { WildcardModule } from './wildcard/wildcard.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, CONFIG, DEBUG_MODE, COLLECTION_ENABLED } from "@angular/fire/analytics";
import { AngularFirestoreModule } from '@angular/fire/firestore';

export function startupServiceFactory(startupService: StartupService, signalrService: SignalRService): Function {
  return async () => {
    await startupService.loadSettings();
    return signalrService.init();
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TrackMyBetModule,
    AccountModule,
    GeoBlockingModule,
    WildcardModule,
    HttpClientModule,
    ApiModule.forRoot({ rootUrl: environment.baseUrl }),
    NgxSmartModalModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule
  ],
  providers: [
    { provide: DEBUG_MODE, useValue: environment.firebase.enableDebug },
    { provide: COLLECTION_ENABLED, useValue: false },
    ScreenTrackingService,
    StartupService,
    GeoBlockingGuard,
    InitialLoginGuard,
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [StartupService, SignalRService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-gb'
    },
    {
      provide: ErrorHandler,
      useClass: ErrorsHandlerService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
