/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsSettingsResponseModel } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-transfer-objects-settings-response-model';
@Injectable({
  providedIn: 'root',
})
class SettingsService extends __BaseService {
  static readonly SettingsGetSettingsPath = '/api/settings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the system URLs, endpoints and settings
   * @param api-version undefined
   * @return OK
   */
  SettingsGetSettingsResponse(apiVersion: number): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsSettingsResponseModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __headers = __headers.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsSettingsResponseModel>;
      })
    );
  }
  /**
   * Gets the system URLs, endpoints and settings
   * @param api-version undefined
   * @return OK
   */
  SettingsGetSettings(apiVersion: number): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsSettingsResponseModel> {
    return this.SettingsGetSettingsResponse(apiVersion).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreTransferObjectsSettingsResponseModel)
    );
  }
}

module SettingsService {
}

export { SettingsService }
