import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { StateHelperService } from 'src/app/shared/state-helper-service';

@Component({
  selector: 'app-geo-blocking',
  templateUrl: './geo-blocking.component.html',
  styleUrls: ['./geo-blocking.component.scss']
})
export class GeoBlockingComponent implements OnInit {

  constructor(
    private titleService: Title,
    private router: Router,
    private stateHelperService: StateHelperService
  ) {
    if (!this.stateHelperService.getValueFromState('blockChecked')) {
      this.router.navigateByUrl('track-my-bet');
    }

    this.titleService.setTitle('Geoblocking');
  }

  ngOnInit(): void {

  }

}
