import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackBetComponent } from './track-bet/track-bet.component';
import { CashOutComponent } from './cash-out/cash-out.component';
import { BetTicketComponent } from './bet-ticket/bet-ticket.component';
import { AddTicketComponent } from './add-ticket/add-ticket.component';
import { TrackMyBetRoutingModule } from './track-my-bet-routing.module';
import { BetEventComponent } from './bet-event/bet-event.component';
import { SliderDirective } from './slider/slider.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuComponent } from './menu/menu.component';
import { TrackMyBetComponent } from './track-my-bet.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { CashOutRequestComponent } from './cash-out-request/cash-out-request.component';
import { HeaderComponent } from './header/header.component';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { MediaComponent } from './media/media.component';
import { MediaControlDirective } from './media/media-control.directive';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FooterComponent } from './footer/footer.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { FaqsComponent } from './faqs/faqs.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { BarcodeScannerLivestreamModule } from 'ngx-barcode-scanner';
import { RewardsComponent } from './rewards/rewards.component';
import { GamesComponent } from './games/games.component';
import { HomeComponent } from './home/home.component';
import { PromoComponent } from './promo/promo.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { NewRewardsComponent } from './rewards/new-rewards/new-rewards.component';
import { CurrentRewardsComponent } from './rewards/current-rewards/current-rewards.component';
@NgModule({
  declarations: [
    TrackBetComponent,
    CashOutComponent,
    BetTicketComponent,
    AddTicketComponent,
    BetEventComponent,
    SliderDirective,
    MenuComponent,
    TrackMyBetComponent,
    CashOutRequestComponent,
    HeaderComponent,
    SafePipe,
    MediaComponent,
    MediaControlDirective,
    FooterComponent,
    TermsConditionComponent,
    FaqsComponent,
    AnnouncementComponent,
    RewardsComponent,
    GamesComponent,
    HomeComponent,
    PromoComponent,
    OnboardingComponent,
    NewRewardsComponent,
    CurrentRewardsComponent
  ],
  imports: [
    CommonModule,
    TrackMyBetRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSmartModalModule.forRoot(),
    AppRoutingModule,
    BarcodeScannerLivestreamModule
  ],
  exports: [TrackBetComponent]
})
export class TrackMyBetModule { }
