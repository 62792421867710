<app-header>
  <ng-container left>
    <a class="fas fa-times" [routerLink]="['/track-my-bet']"></a>
  </ng-container>
  <ng-container middle>
    Cashout
  </ng-container>
</app-header>

<div class="cash-out-request">
  <div class="cash-out-request__requesting" *ngIf="requesting">
    <div class="loader mw">
      <div class="spinner spinner--center"></div>
      <p class="cash-out-request__loader-text">requesting cashout ...</p>
    </div>
  </div>

  <div class="cash-out-request__success" *ngIf="!requesting && cashoutStatus === CashoutStatusEnum.Success">
    <div class="cash-out-request__inner mw">
      <img src="/assets/images/icons/cashout-successful.svg" alt="" width="320" height="280" />

      <p class="cash-out-request__para">You cashed out</p>

      <p class="cash-out-request__currency">
        <span>{{this.cashoutParams.CurrencySymbol}}</span>{{amount | number : '1.2-2' }}
      </p>

      <button class="button button--large" [routerLink]="['/track-my-bet']" [state]="{number: this.cashoutParams.BetTicketNumber, activeTab: 'Settled'}">See my winning bets</button>
    </div>
  </div>

  <div class="cash-out-request__partial" *ngIf="!requesting && cashoutStatus === CashoutStatusEnum.Partial">
    <div class="cash-out-request__inner mw">
      <img src="/assets/images/icons/cashout-value-changed.svg" alt="" width="320" height="280" />

      <p class="cash-out-request__para">
        Cashout value changed
      </p>

      <p class="cash-out-request__currency">
        <span>{{this.cashoutParams.CurrencySymbol}}</span>{{amount | number : '1.2-2' }}
      </p>

      <p class="cash-out-request__para cash-out-request__para--small">Do you want to cash out with the new amount?</p>

      <div class="button-group">
        <a class="button button--large button--alpha" [routerLink]="['/track-my-bet']">No, thanks</a>
        <button class="button button--large" (click)="tryAgain()">Confirm</button>
      </div>
    </div>
  </div>

  <div class="cash-out-request__fail" *ngIf="!requesting && cashoutStatus === CashoutStatusEnum.Fail">
    <div class="cash-out-request__inner mw">
      <img src="/assets/images/icons/cashout-unsuccesful.svg" alt="" width="320" height="280" />

      <p class="cash-out-request__para">
        Cashout request<br/>
        unsuccessful
      </p>

      <p class="cash-out-request__para">{{errorMessage}}</p>

      <button class="button button--large" [routerLink]="['/track-my-bet']">Back to bets</button>
    </div>
  </div>
</div>
<app-footer></app-footer>
