import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../shared/user.service';
import { AuthService } from '../shared/auth.service';
import { map } from 'rxjs/operators';
import { SignalRService } from '../shared/signalr.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(
    public userService: UserService,
    private authService: AuthService,
    private signalRService: SignalRService
  ) {  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body) {
            if (event.body.Payload && event.body.Payload.RefreshToken) {
              this.userService.setToken(event.body.Payload.RefreshToken);
            }

            if (event.body.Payload && event.body.Payload.AnonUserId && !this.userService.anonymousUserId) {
              this.userService.anonymousUserId = event.body.Payload.AnonUserId;
              this.signalRService.init();
            }

            if (event.body.Payload && event.body.Payload.TokenExpired) {
              this.authService.logout();
            }
          }
        }

        return event;
      })
    );
  }
}
