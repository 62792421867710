import { Directive, ElementRef } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Directive({
  selector: '[appMediaControl]'
})
export class MediaControlDirective {

  constructor(
    private el: ElementRef,
    private ngxSmartModalService: NgxSmartModalService
  ) {
    this.el.nativeElement.onerror = this.handleError.bind(this);
  }

  handleError() {
    let message: string;

    switch (this.el.nativeElement.error.code) {
      case MediaError.MEDIA_ERR_DECODE:
          message = 'Unfortunately your browser could not play this media resource.\nHave you tried the PP Onside mobile app?';
          break;
      case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
          message = 'Unfortunately your browser does not support this kind of media.\nHave you tried the PP Onside mobile app?';
          break;
      case MediaError.MEDIA_ERR_NETWORK:
          message = 'Please check your network connection and try again';
          break;
      default:
          break;
    }

    this.ngxSmartModalService.create('Error', message).open();
  }
}
