<footer class="footer">
  <nav class="footer__nav mw">
    <ul class="footer__menu">
      <li class="footer__item" *ngIf="!(currentUser | async)">
        <a [routerLink]="['/home']" routerLinkActive="is-active">
          <svg class="footer__icon">
            <use xlink:href="assets/images/vectors/svgsprites.svg#ic-home"></use>
          </svg>
          Home
        </a>
      </li>
      <li class="footer__item">
        <a [routerLink]="['/track-my-bet']" routerLinkActive="is-active">
          <svg class="footer__icon">
            <use xlink:href="assets/images/vectors/svgsprites.svg#ic-bets"></use>
          </svg>
          Bets
        </a>
      </li>
      <li class="footer__item">
        <a [routerLink]="['/rewards']" routerLinkActive="is-active">
          <svg class="footer__icon">
            <use xlink:href="assets/images/vectors/svgsprites.svg#ic-rewards"></use>
          </svg>
          Rewards
        </a>
      </li>
      <li class="footer__item">
        <a [routerLink]="['/games']" routerLinkActive="is-active">
          <svg class="footer__icon">
            <use xlink:href="assets/images/vectors/svgsprites.svg#ic-games"></use>
          </svg>
          Games
        </a>
      </li>
      <li class="footer__item">
        <a (click)="showMenu()">
          <svg class="footer__icon">
            <use xlink:href="assets/images/vectors/svgsprites.svg#ic-more"></use>
          </svg>
          More
        </a>
      </li>
    </ul>
  </nav>
</footer>
