import { Component, HostListener, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppSettingsService } from 'src/app/shared/app-settings.service';
import { TicketService } from 'src/app/shared/ticket.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Steer73PaddypowerCoreServicesBetTicketManagerBetInfoModel } from 'src/app/api/models';
import { Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { AddTicketResult } from 'src/app/enums/addTicketResult.enum';
import { AddTicketSuccessResult } from 'src/app/enums/addTicketSuccessResult.enum';
import { AnalyticsManager } from '../../../shared/analytics-manager.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-ticket',
  templateUrl: './add-ticket.component.html',
  styleUrls: ['./add-ticket.component.scss']
})
export class AddTicketComponent implements OnInit, OnDestroy {
  @ViewChild(BarcodeScannerLivestreamComponent)
  barcodeScanner: BarcodeScannerLivestreamComponent;

  form: FormGroup;
  submitted = false;
  success = false;
  showError = false;
  scanInProgress = false;
  errorMessage: string;
  maxTicketLength: number;
  longTicketLength: number;
  longBarCodeLength: number;
  addTicketModal = 'addedTicket';
  addedTicket: Steer73PaddypowerCoreServicesBetTicketManagerBetInfoModel;

  isManualSelection = true;
  resumeCamera = false;

  defaultError = 'We can\'t track this bet right now\nPlease try again shortly';

  constructor(
    private titleService: Title,
    private fb: FormBuilder,
    private settings: AppSettingsService,
    private ticketService: TicketService,
    public ngxSmartModalService: NgxSmartModalService,
    public location: Location, 
    private analyticsManager: AnalyticsManager,
    private router: Router
    ) {
      this.titleService.setTitle('Add ticket');
    }

  @HostListener('document:visibilitychange', [ '$event' ])
  visibilitychange() {
    this.documentVisibilityChanged(document.hidden);
  }

  ngOnInit() {
    let settings = this.settings.fetch();
    this.maxTicketLength = settings.BetTicketLength;
    this.longTicketLength = settings.LongTicketLength;
    this.longBarCodeLength = settings.LongBarCodeLength;


    this.form = this.fb.group({
      betticketnumber: ['', [
        Validators.required,
        Validators.pattern('[0-9]+'),
        Validators.minLength(this.maxTicketLength),
        Validators.maxLength(this.longBarCodeLength)
      ]]
    });
    
  }

  ngOnDestroy() {
  }

  addTicket() {
    this.showError = false;

    if (this.form.valid) {
      this.submitted = true;
      this.ticketService.addTicket(this.form.controls.betticketnumber.value, this.scanInProgress)
      .pipe(take(1))
      .subscribe((res) => {

        this.analyticsManager.addTicket(AddTicketResult.TicketTrackedSuccessfully, !this.isManualSelection);
        this.addedTicket = res.Tickets[0];

        if(this.form.controls.betticketnumber.value.length === this.longBarCodeLength) {
          let betTicketNumber = `${this.form.controls.betticketnumber.value.substring(8,12)}0${this.form.controls.betticketnumber.value.substring(15)}`;
          this.form.controls.betticketnumber.setValue(betTicketNumber);
        }

        this.success = true;
      },
      err => {
        
        this.analyticsManager.addTicketError(err.message);

        this.setError(err.message);
        this.showError = true;
        this.submitted = false;
      });
    }
  }

  setError(errorMessage) {
    this.errorMessage = errorMessage ?? this.defaultError;
  }

  reset() {
    
    this.analyticsManager.addTicketSuccess(AddTicketSuccessResult.AddAnother);    

    this.form.reset();
    this.submitted = false;
    this.success = false;
    this.showError = false;
    this.scanInProgress = false;
  }
  
  viewBet() {  
    this.analyticsManager.addTicketSuccess(AddTicketSuccessResult.ViewBet);     

    this.router.navigate(['/track-my-bet'], { state: { ticket: this.addedTicket, activeTab: (this.ticketService.isTicketOpen(this.addedTicket) ? 'Open' : 'Settled') } });  
  }

  changeInputType(isManual) {
    this.reset();
    this.isManualSelection = isManual;

    if (!isManual && this.supportsCamera()) {
      this.barcodeScanner.start();
    } else if (isManual && this.barcodeScanner && this.barcodeScanner.started) {
      this.barcodeScanner.stop();
    }
  }

  onValueChanges(result) {
    if (!this.scanInProgress) {
      this.scanInProgress = true;

      let code:string = result.codeResult.code;
      if (code) {
        code = code.toLowerCase().startsWith('b') ? code.substring(1) : code;

        if (code.length === this.maxTicketLength || code.length === this.longBarCodeLength) {
          this.form.controls.betticketnumber.setValue(code);
          this.addTicket();
        } else {

          this.analyticsManager.addTicket(AddTicketResult.ScanFailed, true);
          this.setError('The barcode you scanned is invalid');
          this.showError = true;
        }
      } else {
        this.scanInProgress = false;
      }
    }
  }

  supportsCamera() {
    return navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function';
  }

  documentVisibilityChanged(documentHidden) {
    if (this.isManualSelection)
      return;

    if (documentHidden) {
      if (this.barcodeScanner && this.barcodeScanner.isStarted) {
        this.resumeCamera = true;
        this.barcodeScanner.stop();
      }
    } else {
      if (this.resumeCamera) {
        this.barcodeScanner.start();
      }

      this.resumeCamera = false;
    }
  }

  goBack() {
    this.location.back();
  }
}
