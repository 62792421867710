<app-header>
    <ng-container left>
      <a class="fas fa-times" (click)="goBack()"></a>
    </ng-container>
    <ng-container middle>
      Promo
    </ng-container>
  </app-header>
<div class="promo">
    <div class="promo__container" *ngIf="!loading">
      <div class="promo__img" *ngIf="promo.InternalImageUrl">
        <img [src]="promo.InternalImageUrl" alt="Promo">
      </div>
        <h2 class="promo__title">{{ promo.Name }}</h2>
        <div [innerHTML]="promo.Description" class="promo__text"></div>
    </div>
</div>
  