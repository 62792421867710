/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel } from '../models/steer-73paddypower-core-dto-base-response-steer-73paddypower-core-services-bet-ticket-manager-ticket-response-model';
import { Steer73PaddypowerCoreDtoAddTicketRequestV2 } from '../models/steer-73paddypower-core-dto-add-ticket-request-v2';
import { Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreServicesBetTicketManagerRetaCashOutModel } from '../models/steer-73paddypower-core-dto-base-response-system-collections-generic-list-steer-73paddypower-core-services-bet-ticket-manager-reta-cash-out-model';
@Injectable({
  providedIn: 'root',
})
class BetTicketsService extends __BaseService {
  static readonly BetTicketsGetOpenBetsFromTokenPath = '/api/betTickets/open';
  static readonly BetTicketsGetSettledBetsFromTokenPath = '/api/betTickets/settled';
  static readonly BetTicketsAddTicketPath = '/api/betTickets/add';
  static readonly BetTicketsRefreshTicketsPath = '/api/betTickets/refresh';
  static readonly BetTicketsCashoutInfoPath = '/api/betTickets/cashoutinfo';
  static readonly BetTicketsGetBetTicketPath = '/api/betTickets/GetBetTicket/{betTicketId}';
  static readonly BetTicketsGetBetRadarPath = '/api/betTickets/GetBetRadar/{statisticId}';
  static readonly BetTicketsGetRetaPath = '/api/betTickets/GetReta/{betTicketId}';
  static readonly BetTicketsStartingTrackingRetaTicketPath = '/api/betTickets/StartingTrackingRetaTicket/{betTicketId}';
  static readonly BetTicketsCheckCashoutPricePath = '/api/betTickets/CheckCashoutPrice/{betTicketId}';
  static readonly BetTicketsGetRetaConvertedPath = '/api/betTickets/GetRetaConverted/{betTicketId}';
  static readonly BetTicketsGetRetaV2Path = '/api/betTickets/GetRetaV2/{betTicketId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param api-version undefined
   * @return OK
   */
  BetTicketsGetOpenBetsFromTokenResponse(apiVersion: number): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __headers = __headers.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/betTickets/open`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel>;
      })
    );
  }
  /**
   * @param api-version undefined
   * @return OK
   */
  BetTicketsGetOpenBetsFromToken(apiVersion: number): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel> {
    return this.BetTicketsGetOpenBetsFromTokenResponse(apiVersion).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel)
    );
  }

  /**
   * @param api-version undefined
   * @return OK
   */
  BetTicketsGetSettledBetsFromTokenResponse(apiVersion: number): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __headers = __headers.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/betTickets/settled`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel>;
      })
    );
  }
  /**
   * @param api-version undefined
   * @return OK
   */
  BetTicketsGetSettledBetsFromToken(apiVersion: number): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel> {
    return this.BetTicketsGetSettledBetsFromTokenResponse(apiVersion).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel)
    );
  }

  /**
   * @param params The `BetTicketsService.BetTicketsAddTicketParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BetTicketsAddTicketResponse(params: BetTicketsService.BetTicketsAddTicketParams): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/betTickets/add`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel>;
      })
    );
  }
  /**
   * @param params The `BetTicketsService.BetTicketsAddTicketParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BetTicketsAddTicket(params: BetTicketsService.BetTicketsAddTicketParams): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel> {
    return this.BetTicketsAddTicketResponse(params).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel)
    );
  }

  /**
   * Refreshes the bet tickets contained within the refresh token
   * @param api-version undefined
   * @return OK
   */
  BetTicketsRefreshTicketsResponse(apiVersion: number): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __headers = __headers.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/betTickets/refresh`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel>;
      })
    );
  }
  /**
   * Refreshes the bet tickets contained within the refresh token
   * @param api-version undefined
   * @return OK
   */
  BetTicketsRefreshTickets(apiVersion: number): __Observable<Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel> {
    return this.BetTicketsRefreshTicketsResponse(apiVersion).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSteer73PaddypowerCoreServicesBetTicketManagerTicketResponseModel)
    );
  }

  /**
   * Gets cashout info for Reta tickets
   * @param api-version undefined
   * @return OK
   */
  BetTicketsCashoutInfoResponse(apiVersion: number): __Observable<__StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreServicesBetTicketManagerRetaCashOutModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __headers = __headers.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/betTickets/cashoutinfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreServicesBetTicketManagerRetaCashOutModel>;
      })
    );
  }
  /**
   * Gets cashout info for Reta tickets
   * @param api-version undefined
   * @return OK
   */
  BetTicketsCashoutInfo(apiVersion: number): __Observable<Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreServicesBetTicketManagerRetaCashOutModel> {
    return this.BetTicketsCashoutInfoResponse(apiVersion).pipe(
      __map(_r => _r.body as Steer73PaddypowerCoreDtoBaseResponseSystemCollectionsGenericListSteer73PaddypowerCoreServicesBetTicketManagerRetaCashOutModel)
    );
  }

  /**
   * @param params The `BetTicketsService.BetTicketsGetBetTicketParams` containing the following parameters:
   *
   * - `betTicketId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BetTicketsGetBetTicketResponse(params: BetTicketsService.BetTicketsGetBetTicketParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/betTickets/GetBetTicket/${encodeURIComponent(String(params.betTicketId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `BetTicketsService.BetTicketsGetBetTicketParams` containing the following parameters:
   *
   * - `betTicketId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BetTicketsGetBetTicket(params: BetTicketsService.BetTicketsGetBetTicketParams): __Observable<{}> {
    return this.BetTicketsGetBetTicketResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param params The `BetTicketsService.BetTicketsGetBetRadarParams` containing the following parameters:
   *
   * - `statisticId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BetTicketsGetBetRadarResponse(params: BetTicketsService.BetTicketsGetBetRadarParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/betTickets/GetBetRadar/${encodeURIComponent(String(params.statisticId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `BetTicketsService.BetTicketsGetBetRadarParams` containing the following parameters:
   *
   * - `statisticId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BetTicketsGetBetRadar(params: BetTicketsService.BetTicketsGetBetRadarParams): __Observable<{}> {
    return this.BetTicketsGetBetRadarResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param params The `BetTicketsService.BetTicketsGetRetaParams` containing the following parameters:
   *
   * - `betTicketId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BetTicketsGetRetaResponse(params: BetTicketsService.BetTicketsGetRetaParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/betTickets/GetReta/${encodeURIComponent(String(params.betTicketId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `BetTicketsService.BetTicketsGetRetaParams` containing the following parameters:
   *
   * - `betTicketId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BetTicketsGetReta(params: BetTicketsService.BetTicketsGetRetaParams): __Observable<{}> {
    return this.BetTicketsGetRetaResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param params The `BetTicketsService.BetTicketsStartingTrackingRetaTicketParams` containing the following parameters:
   *
   * - `betTicketId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BetTicketsStartingTrackingRetaTicketResponse(params: BetTicketsService.BetTicketsStartingTrackingRetaTicketParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/betTickets/StartingTrackingRetaTicket/${encodeURIComponent(String(params.betTicketId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `BetTicketsService.BetTicketsStartingTrackingRetaTicketParams` containing the following parameters:
   *
   * - `betTicketId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BetTicketsStartingTrackingRetaTicket(params: BetTicketsService.BetTicketsStartingTrackingRetaTicketParams): __Observable<{}> {
    return this.BetTicketsStartingTrackingRetaTicketResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param params The `BetTicketsService.BetTicketsCheckCashoutPriceParams` containing the following parameters:
   *
   * - `betTicketId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BetTicketsCheckCashoutPriceResponse(params: BetTicketsService.BetTicketsCheckCashoutPriceParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/betTickets/CheckCashoutPrice/${encodeURIComponent(String(params.betTicketId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `BetTicketsService.BetTicketsCheckCashoutPriceParams` containing the following parameters:
   *
   * - `betTicketId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BetTicketsCheckCashoutPrice(params: BetTicketsService.BetTicketsCheckCashoutPriceParams): __Observable<{}> {
    return this.BetTicketsCheckCashoutPriceResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param params The `BetTicketsService.BetTicketsGetRetaConvertedParams` containing the following parameters:
   *
   * - `betTicketId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BetTicketsGetRetaConvertedResponse(params: BetTicketsService.BetTicketsGetRetaConvertedParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/betTickets/GetRetaConverted/${encodeURIComponent(String(params.betTicketId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `BetTicketsService.BetTicketsGetRetaConvertedParams` containing the following parameters:
   *
   * - `betTicketId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BetTicketsGetRetaConverted(params: BetTicketsService.BetTicketsGetRetaConvertedParams): __Observable<{}> {
    return this.BetTicketsGetRetaConvertedResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param params The `BetTicketsService.BetTicketsGetRetaV2Params` containing the following parameters:
   *
   * - `betTicketId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BetTicketsGetRetaV2Response(params: BetTicketsService.BetTicketsGetRetaV2Params): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __headers = __headers.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/betTickets/GetRetaV2/${encodeURIComponent(String(params.betTicketId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `BetTicketsService.BetTicketsGetRetaV2Params` containing the following parameters:
   *
   * - `betTicketId`:
   *
   * - `api-version`:
   *
   * @return OK
   */
  BetTicketsGetRetaV2(params: BetTicketsService.BetTicketsGetRetaV2Params): __Observable<{}> {
    return this.BetTicketsGetRetaV2Response(params).pipe(
      __map(_r => _r.body as {})
    );
  }
}

module BetTicketsService {

  /**
   * Parameters for BetTicketsAddTicket
   */
  export interface BetTicketsAddTicketParams {
    request: Steer73PaddypowerCoreDtoAddTicketRequestV2;
    apiVersion: number;
  }

  /**
   * Parameters for BetTicketsGetBetTicket
   */
  export interface BetTicketsGetBetTicketParams {
    betTicketId: string;
    apiVersion: number;
  }

  /**
   * Parameters for BetTicketsGetBetRadar
   */
  export interface BetTicketsGetBetRadarParams {
    statisticId: string;
    apiVersion: number;
  }

  /**
   * Parameters for BetTicketsGetReta
   */
  export interface BetTicketsGetRetaParams {
    betTicketId: string;
    apiVersion: number;
  }

  /**
   * Parameters for BetTicketsStartingTrackingRetaTicket
   */
  export interface BetTicketsStartingTrackingRetaTicketParams {
    betTicketId: string;
    apiVersion: number;
  }

  /**
   * Parameters for BetTicketsCheckCashoutPrice
   */
  export interface BetTicketsCheckCashoutPriceParams {
    betTicketId: string;
    apiVersion: number;
  }

  /**
   * Parameters for BetTicketsGetRetaConverted
   */
  export interface BetTicketsGetRetaConvertedParams {
    betTicketId: string;
    apiVersion: number;
  }

  /**
   * Parameters for BetTicketsGetRetaV2
   */
  export interface BetTicketsGetRetaV2Params {
    betTicketId: string;
    apiVersion: number;
  }
}

export { BetTicketsService }
