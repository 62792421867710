import { Component, OnInit } from '@angular/core';
import { SideMenuService } from 'src/app/shared/side-menu.service';
import { UserService } from 'src/app/shared/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private sideMenuService: SideMenuService,
              private userService: UserService,
              ) { }

  ngOnInit() {
  }

  showMenu() {
    this.sideMenuService.showMenu();
  }

  get currentUser() {
    return this.userService.userData$;
  }
}
