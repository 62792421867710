import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../shared/user.service';
import { EnvironmentService } from '../shared/environment.service';
import { AppSettingsService } from '../shared/app-settings.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(public userService: UserService,
              public environmentService: EnvironmentService,
              public appSettings: AppSettingsService) {  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf(this.environmentService.getEnvironmentConfiguration().baseUrl) > -1) {
      request = request.clone({
        headers: request.headers
        .set('X-Refresh-Token', `${this.userService.getToken()}`)        
      });
    } else if (this.appSettings.fetch()) {
      for (let endpoint in this.appSettings.fetch().Endpoints)
      {
        if (request.url.indexOf(this.appSettings.fetch().Endpoints[endpoint]) > -1) {
          request = request.clone({
            headers: request.headers
            .set('X-Refresh-Token', `${this.userService.getToken()}`)
          });
          break;
        }
      }
    }

    if (request.url.indexOf(this.environmentService.getEnvironmentConfiguration().baseUrl) > -1) {
      request = request.clone({
        headers: request.headers
        .set('X-Client-Guid', 'M0CSQJsMuz2MfeNGZ50ebzh6mrTDcW')
      });
    }

    return next.handle(request);
  }
}
