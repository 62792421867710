import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './account.component';
import { LoginComponent } from './login/login.component';
import { CreateComponent } from './create/create.component';
import { GeoBlockingGuard } from '../../shared/geo-blocking-guard';

export const routes: Routes = [{
    path: '',
    component: AccountComponent,
    canActivate: [GeoBlockingGuard],
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'create-account', component: CreateComponent, data: {header: 'Create account'} },
      { path: 'reset-password', component: CreateComponent, data: {header: 'Reset password'} }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AccountRoutingModule { }
